import styled from "styled-components";

export const CreateOrderStyled = styled.div`
  .left-side-content-sec .col-sm-6 {
    margin-top: 0;
  }
  .left-side-content-sec .product-name-drop-down {
    // margin-top: -20px;
    select {
      height: 40px;
      border-radius: 4px;
    }
  }
  .create-job-action1 {
    text-align: right;
  }
  .user-email-sec {
    text-transform: lowercase;
  }
  .image-link-sec-item {
    padding: 10px;
    border: 1px solid #e2e2e2;
    margin: 10px;
  }
  .list-images-sec-con {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .image-link-sec-item-new {
    cursor: pointer;
  }
  .upload-images-sec {
    margin-bottom: 20px;
  }
  .image-link-sec-item {
    text-align: center;
    img {
      cursor: pointer;
      width: 150px;
      height: 100px;
      object-fit: cover;
      max-width: 150px;
      max-height: 100px;
      object-fit: contain;
    }
    svg {
      position: absolute;
      margin-left: 10px;
      cursor: pointer;
    }
    div {
      margin-top: 10px;
      text-align: center;
    }
  }
  .add_edit_icons_sec {
    img {
      width: 25px;
      cursor: pointer;
      margin-left: 5px;
      position: relative;
      // top: -6px;
    }
  }
  .extra-fields-content {
    display: flex;
  }
  .radio-button-values-incoming1 {
    display: grid;

    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-left: 25px;
    //   display: flex;
    //   .row1 {
    //     display: flex;
    //     justify-content: space-around;
    //     input {
    //     }
    //   }
    // .form-check-input,
    // .form-check-label {
    //   margin: 0;
    // }
  }
  .warrenty-type-section1 {
    margin-bottom: 20px;
  }
  .pattern-lock-img img {
    height: 20px;
    display: inline-block;
    cursor: pointer;
  }
  .extra-items-row-con {
    margin-top: 20px;
  }
  .radio-buttons-section-tems-sec1 {
    display: flex;
    gap: 50px;
  }
  .arrow-text-sec {
    cursor: pointer;
    // margin-left: 20px;
    padding-right: 40px;
    display: inline-block;
    img {
      height: 30px;
    }
  }
  .section-container-header1 {
    justify-content: space-between;
    h3 {
      // width: 33%;
    }
  }
  .section-container-header1-left {
    display: flex;
  }
  .menu-items-list {
    padding: 0;
    margin-top: -1rem;
    position: absolute;
    z-index: 5346565465;
    width: 89%;
    ul {
      max-height: 250px;
      overflow: scroll;
    }
    li {
      cursor: pointer;
      background: #4682b4;
      color: #fff;
      border-color: #fff;
    }
  }
  .focusnext-boby-content .form-control:focus {
    box-shadow: none;
  }
  .error-fields-sec .basic-single .select__control,
  .error-fields-sec select {
    border: 1px solid #dc3545;
  }
  .error-fields-sec-radio > label {
    border: 1px solid #dc3545;
  }
  .enginner-prev-list-all {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  .job-card-submit-btn,
  .job-card-submit-btn:focus {
    background: #000 !important;
    border: none !important;
    height: 42px !important;
    box-shadow: none !important;
  }
  .enginner-prev-list,
  .enginner-prev-list-header {
    display: flex;

    gap: 15px;
  }
  .add-new-user-sec {
    margin-left: 20px;
    display: block;
    margin-top: 2px;
    // text-decoration: underline;
    cursor: pointer;
    color: #007bff;
  }
  .enginner-prev-list-header {
    border-bottom: 1px solid #e2e2e2;
    padding: 6px;
    font-weight: 600;
  }
  .enginner-prev-list {
    border-bottom: 1px solid #e2e2e2;
    padding: 6px;
  }
  .enginner-prev-createdby {
    width: 20%;
  }
  .enginner-prev-date {
    width: 20%;
  }
  .enginner-prev-desc {
    width: 60%;
  }
  .part-enginner-prev-part {
    width: 40%;
  }
  .part-enginner-prev-serial {
    width: 60%;
  }
  .enginner-prev-list-allactionTaken .enginner-prev-date {
    width: 40%;
  }
  .enginner-prev-list-allactionTaken > div,
  .enginner-prev-list-allpartReplaced > div {
    text-align: center;
  }
  .edit-action-content {
    // margin: 30px 0px;
  }
  .manditary {
    color: red;
  }
  .action-edit-observations-sec select {
    height: 50px;
  }
  .action-edit-add-icons select {
    height: 50px;
  }
  .action-edit-observations-sec textarea {
    margin-top: 10px;
    width: 100%;
    padding: 5px;
    height: 70px;
    border: 1px solid #ced4da;
  }
  // .edit-action-all-items-left {
  //   width: 48%;
  //   float: left;
  //   margin-right: 2%;
  // }
  // .edit-action-all-items-left {
  //   width: 50%;
  //   float: left;
  // }
  // .edit-action-all-items-right {
  //   width: 47%;
  //   float: left;
  // }
  .action-taken-items-parts-replaced,
  .action-taken-items-notes {
    margin-top: 10px;
    // border-top: 1px solid #006261;
  }
  .add-others-remark-sec {
    padding: 0;
    border: 0;

    button {
      border: 1px solid #ced4da;
      border-radius: 4px;
      padding: 10px 20px;
      background: #f1eaea;
      color: #000;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .action-taken-all-items-sec {
    // border-top: 1px solid #006261;
    margin-top: 20px;
  }
  .action-taken-items {
    width: 100%;
    margin-top: 10px;
    padding: 15px 20px;
    border: 1px solid #006261;
    border-radius: 4px;
    margin-top: 10px;
    .col-form-label {
      font-weight: bold;
      font-size: 22px;
      color: #0b66b2;
      background: #d8e6f3;
      padding-left: 20px;
      width: 100%;
    }
  }
  .radio-buttons-section-tems-sec {
    display: inline-flex;
    margin: auto;
    padding-top: 15px;
    margin-top: 15px;

    padding-bottom: 15px;
    margin-bottom: 15px;
    width: 100%;
  }
  .items-recieved-body-content {
    display: inline-block;
    margin-top: 20px;
  }
  .items-recieved-body-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .items-recieved-section-error .items-recieved-body-content {
    border: 1px solid red;
  }
  .add-back-arrow {
    margin-bottom: 10px;
    span {
      cursor: pointer;
      background: #000;
      color: #fff;
      padding: 5px;
      border-radius: 4px;
    }
  }
  .radio-buttons-section-tems {
  }
  .radio-buttons-section-tems-left {
    width: 50%;
  }
  .radio-button-values input,
  .radio-button-values label {
    cursor: pointer;
  }
  .action-edit-add-icons-name {
    .action-usernames {
      display: flex;
      gap: 20px;
      > div,
      > input {
        width: 50%;
        height: 40px;
        // margin-right: 3%;
      }
    }
  }
  .action-edit-add-icons-name-left {
    width: 90%;
  }
  .action-edit-add-icons,
  .action-edit-add-icons-name {
    display: flex;
    select {
      // width: 80%;
    }
    .action-click-item {
      width: 10%;
      width: 60px;
    }
    svg {
      width: 30px;
      height: 27px;
      margin: auto;
      margin-left: 15px;
      cursor: pointer;
    }
  }
  .action-edit-add-icons-name1 .action-click-item {
    padding-top: 23px;
  }
  .action-edit-add-icons-name,
  .radio-buttons-section-tems-sec {
    // border-top: 1px solid #e2e2e2;
  }
  .radio-buttons-section-tems-sec {
    border-bottom: 1px solid #e2e2e2;
  }
  .assigned-to-sec {
    width: 250px;
    margin-right: 50px;
  }
  // .assigned-to-sec-priority {
  //   width: 250px;
  //   margin-right: 50px;
  // }
  // .assigned-to-sec {
  //   width: 250px;
  //   margin-right: 50px;
  // }
  .action-taken-all-items-sec,
  .action-taken-items-parts-replaced {
    min-height: 154px;
  }
  .courier-view .form-group label,
  .create-job-action label,
  .radio-buttons-section-tems-sec label.col-form-label {
    font-weight: bold;
    font-size: 20px;
    color: #0b66b2;
  }
  .datatable1 .ProductDetails1 .form-group label,
  .datatable1 .ProductDetails1 label.col-form-label {
    font-weight: 400;
    font-size: 18px;
    color: #000;
  }
  .create-job-action {
    margin: auto;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    // padding: 0 5%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
  }
  .create-job-action99 {
    text-align: right;
  }
  .add-border-top {
    border-top: 1px solid #e2e2e2;
    padding-top: 20px;
  }
  .submit-btn-sec-top {
    width: 100%;
    // text-align: right;
    margin-top: 35px !important;
    button {
      margin-right: 20px;
      width: 200px;
      height: 50px !important;
      font-size: 20px;
    }
  }
  .add-border-top {
  }
  .printPage-Count-sec {
    font-size: 15px !important;
  }
  .assigned-to-sec-amc {
    margin-top: 20px;
    margin-left: 15px;
    display: flex;
  }
  .assigned-to-sec-amc-left-con {
    display: flex;
    > div {
      min-width: 100px;
      margin-left: 10px;
      padding-top: 4px;
    }
  }
  .courier-sec-content-sec {
    margin-left: 15px;
    width: 95%;
  }
  .submit-btn-sec-top {
    // text-align: right;
  }
  .action-edit-add-icons .action-click-item {
    svg {
      position: relative;
      top: 10px;
    }
  }
  @media (min-width: 700px) {
    .edit-action-all-items-left,
    .action-taken-all-items-sec-d {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 0px;
      column-gap: 20px;
    }
    .action-taken-all-items-sec-d {
      border: 1px solid rgb(0, 98, 97);
      .action-taken-items {
        border: none;
      }
    }
  }
  @media (max-width: 700px) {
    .items-recieved-body-content {
      grid-template-columns: 1fr 1fr;
      margin-left: 0px;
    }
    .section-container-header1 {
      display: block;
      text-align: center;
      h3 {
        margin-bottom: 20px;
      }
      .arrow-text-sec {
        padding-right: 0px;
      }
      .search-icon-sec input {
        margin-right: 20px;
        width: 70%;
      }
    }
    .left-side-content-sec {
      > div {
        gap: 4%;
        > div {
          width: 48%;
          float: left;
        }
      }
    }
    .left-side-content-sec .payment-time-sec-mo {
      display: block;
      > div {
        width: 100%;
        float: left;
      }
    }
    .create-job-action1 {
      display: block;
      width: 80%;
      margin-bottom: 100px !important;
      padding-bottom: 80px;
    }
  }

  // adding new styles
  .section-container-header2 {
    display: flex;
    justify-content: flex-start;
    height: 42px;
    background: #e2e2e2;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
  }
  .items-section-row {
    margin: 10px 0px;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    > div {
      margin-top: 16px;
    }
  }
`;
