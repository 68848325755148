import styled from "styled-components";

export const ReportsStyled = styled.div`
  .student-fields-sec-content-all {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .submit-filter-btn-sec {
    margin-top: 25px;
  }
  .no-reports-count {
    border-top: 1px solid #e2e2e2;
    padding-top: 20px;
    text-align: center;
  }
`;
