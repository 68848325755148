import styled from "styled-components";

export const TransactionsStyled = styled.div`
  .no-results {
    color: rgb(89, 85, 85);
    margin-top: 100px;
    text-align: center;
  }
  .student-fields-sec-content-all {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    // margin-top: 30px;
    margin-bottom: 10px;
    .ant-picker-range {
      height: 46px;
      border-radius: 8px;
    }
  }
  .submit-filter-btn-sec {
    margin-top: 25px;
  }
`;
