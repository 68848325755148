import { PaymentModelsStyled } from "./PaymentModels.styled";
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import {
  addDirectPaymentAPI,
  getAllDirectPaymentsAPI,
  // getAllDirectPaymentsAPI,
} from "./../../../redux/slices/StudentManagement/StudentManagementService";
import Loader from "./../../../components/Common/Loader";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import clsx from "clsx";
import CloseButton from "react-bootstrap/CloseButton";
const initValStore = {
  transactionDate: undefined,
  transactionMode: undefined,
  transactionId: undefined,
};
const initValError = {
  transactionDate: false,
  transactionMode: false,
  transactionId: false,
};
const errorDataValue = {
  transactionDate: "Please Enter Value",
  transactionMode: "Please Enter Value",
  transactionId: "Please Enter Value",
};

const DirectPaymentModel = (props: any) => {
  const history = useHistory();
  const [passError, setPassError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [feeComponent, setFeeComponent] = useState([] as any);
  const [transactionMode, setTransactionMode] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [errorData, setErrorData] = useState(initValError as any);
  const { error, loading, user } = useSelector((state: any) => state?.auth);

  useEffect(() => {}, [props?.show]);
  const handleSubmitFn = async () => {
    console.log("feeComponent", feeComponent);
    console.log("sing data", signUpData);
    var isValid = true;
    var errornew = { ...initValError } as any;
    for (var key in errornew) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    feeComponent.map((iit: any) => {
      const keyvv = iit?.value?.split("_")[0];
      console.log("keyvv", keyvv);
      console.log("signUpData[keyvv]", signUpData[keyvv]);
      if (signUpData[keyvv] === "" || !signUpData[keyvv]) {
        errornew[keyvv] = true;
        isValid = false;
      }
    });
    setErrorData({ ...errornew });

    if (isValid) {
      setIsLoading(true);
      const body = {
        ...signUpData,
        studentId: props?.studentId,
        instituteId: user?._id,
      };
      await dispatch(addDirectPaymentAPI(body));
      await dispatch(getAllDirectPaymentsAPI({ studentId: props?.studentId }));

      setIsLoading(false);
      props?.handleClose();
    }
  };
  const handleChangeValue = (e: any) => {
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    setSignUpData({
      ...addr,
      [name]: value1,
    });
    setErrorData({
      ...addr_error,
      [name]: false,
    });
  };

  const admissionList = [
    {
      label: "Addmission Fees(10000)",
      value: "addmissionFees_10000",
    },
    {
      label: "Tuition Fees(70000)",
      value: "tuitionFees_70000",
    },
    {
      label: "Transpotaion Fees(30000)",
      value: "transpotaionFees_30000",
    },
  ] as any;
  const transactionModesList = [
    {
      label: "Cash",
      value: "Cash",
    },
    {
      label: "Net Banking",
      value: "Net Banking",
    },
    {
      label: "Cheque",
      value: "Cheque",
    },
    {
      label: "Demand Draft",
      value: "Demand Draft",
    },
    {
      label: "Others",
      value: "Others",
    },
  ] as any;

  const handleChangeFeeComponent = (selectedOption: any) => {
    setFeeComponent(selectedOption);
  };
  const handleChangeTransactionMode = (selectedOption: any) => {
    setTransactionMode(selectedOption);
    const addr = { ...signUpData };
    var errornew = { ...initValError } as any;
    errornew["transactionMode"] = false;

    setSignUpData({
      ...addr,
      ["transactionMode"]: selectedOption?.value,
    });
    setErrorData({ ...errornew });
  };

  console.log("feeComponent", feeComponent);
  console.log("signupData", signUpData);
  console.log("errorData", errorData);
  return (
    <>
      <Modal {...props} size="sm" className="payment-mode-model-box" centered>
        <div className="conformation-model">
          <PaymentModelsStyled>
            <Modal.Body>
              <span
                className="color-picker-close-icon"
                onClick={() => props.handleClose(false)}
              >
                <CloseButton />
              </span>
              {isLoading && <Loader />}
              <div className="content-sec-color-picker">
                <h3>Direct Payment Model</h3>
              </div>
              <div>
                <div>
                  <div className="deltape-form free-payment-models-component">
                    <div className="delta-select-column">
                      <label>Select Fee Components </label>
                      <Select
                        value={feeComponent}
                        placeholder="Select Class"
                        onChange={handleChangeFeeComponent}
                        options={admissionList}
                        className="delta-select"
                        classNamePrefix="delta-select"
                        isMulti
                      />
                    </div>
                    {feeComponent?.map((item: any) => {
                      const name = item?.value?.split("_")[0];
                      const amountV = item?.value?.split("_")[1];
                      return (
                        <>
                          <div className="student-info-row">
                            <Form.Group
                              className="delta-signup-md"
                              controlId="email"
                            >
                              <Form.Label>
                                {item?.label}
                                <span className="mand-sign-field">*</span>
                              </Form.Label>
                              <Form.Control
                                // autoFocus
                                isInvalid={errorData[name]}
                                name={name}
                                type="number"
                                max={parseInt(amountV)}
                                value={signUpData[name]}
                                onChange={(e) => handleChangeValue(e)}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errorData[name] ? (
                                  <>Please Enter Amount {item?.label}</>
                                ) : null}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </>
                      );
                    })}

                    {/* <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          {" "}
                          Tuition Fee Amount{" "}
                          <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          // autoFocus
                          isInvalid={errorData?.tuitionFeeAmount}
                          name="tuitionFeeAmount"
                          type="text"
                          value={signUpData?.tuitionFeeAmount}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.tuitionFeeAmount ? (
                            <>{errorDataValue?.tuitionFeeAmount}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div> */}
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          {" "}
                          Transaction Date{" "}
                          <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          // autoFocus
                          isInvalid={errorData?.transactionDate}
                          name="transactionDate"
                          type="date"
                          value={signUpData?.transactionDate}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.transactionDate ? (
                            <>{errorDataValue?.transactionDate}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="delta-select-column">
                      <label>Transaction Mode </label>
                      <Select
                        value={transactionMode}
                        placeholder="Select Class"
                        onChange={handleChangeTransactionMode}
                        options={transactionModesList}
                        className="delta-select"
                        classNamePrefix="delta-select"
                      />
                    </div>
                    {errorData?.transactionMode ? (
                      <>
                        <p className="error_message">
                          {errorDataValue?.transactionMode}
                        </p>
                      </>
                    ) : null}
                    {/* <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          {" "}
                          Transaction Mode{" "}
                          <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          // autoFocus
                          isInvalid={errorData?.transactionMode}
                          name="transactionMode"
                          type="text"
                          value={signUpData?.transactionMode}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.transactionMode ? (
                            <>{errorDataValue?.transactionMode}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div> */}
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          {" "}
                          Transaction Id{" "}
                          <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          // autoFocus
                          isInvalid={errorData?.transactionId}
                          name="transactionId"
                          type="text"
                          value={signUpData?.transactionId}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.transactionId ? (
                            <>{errorDataValue?.transactionId}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label> Notes</Form.Label>
                        <Form.Control
                          // autoFocus
                          name="notes"
                          type="text"
                          value={signUpData?.notes}
                          onChange={(e) => handleChangeValue(e)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="new-option-body-actions">
                <Button className="deltape-button" onClick={handleSubmitFn}>
                  Add Payment
                </Button>
              </div>
            </Modal.Footer>
          </PaymentModelsStyled>
        </div>
      </Modal>
    </>
  );
};
export default DirectPaymentModel;
