import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useLocation } from "react-router-dom";
import * as ReactPopOver from "react-popover";
import logo1 from "../../img/logo/logonew5.png";
import logo2 from "../../img/logo/logonew6.png";
import logo3 from "../../assets/Log-out.png";
import logo4 from "../../img/delta/Logo.png";
import { Offcanvas, Navbar, Container } from "react-bootstrap";

import moment from "moment";
import {
  userMenuLinks,
  adminMenuLinks,
  instituteMenuLinks,
} from "./../SideMenu/constants";

import { Row, Col, Button } from "react-bootstrap";
import "./styles.css";
import "./mobile.css";
import Marquee from "react-fast-marquee";
import { checkIsMobile } from "./../../Scenes/common";
// import 'bootstrap/dist/css/bootstrap.min.css';
import DropdownList from "./DropdownList";
import {
  updateUserDetails,
  updateAlertModelBox,
} from "../../redux/slices/auth/authSlice";
import { triggerUserFprofileInMixpanel } from "./../../Scenes/common";
import AlertModel from "../../components/AlertModel/AlertModel";
const Header = (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loginStoreDetails, user, isParentSite, isAdminSite } =
    useSelector(({ auth }) => auth);
  // const gmtDateTime = moment.utc(
  //   collectionData?.biddingStartDateOnUTC,
  //   "YYYY-MM-DD h:mm:ss a"
  // );
  const [shows, setShows] = useState(false);

  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);

  const [isOpen, setIsOpen] = useState(false);
  const [showIslogoutModel, setShowIslogoutModel] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const [updateData, setUpdateData] = useState(false);
  const cUrl = window.location.pathname?.toLowerCase();
  const selectedUrl = window.location.pathname;

  const menuLinks = isParentSite
    ? userMenuLinks
    : isAdminSite
    ? adminMenuLinks
    : instituteMenuLinks;
  // console.log("cUrl", cUrl);
  const isHomepAge =
    cUrl === "/" ||
    cUrl === "/aboutus" ||
    cUrl === "/contactus" ||
    cUrl === "/home" ||
    cUrl === "/services"
      ? true
      : false;

  useEffect(() => {
    getCurrentTime();
    triggerUserFprofileInMixpanel(user);
  }, []);

  const getCurrentTime = () => {
    setUpdateData(!updateData);
    setCurrentTime(moment().format("MMMM DD, YYYY | h:mm:ss A"));
    setTimeout(() => {
      getCurrentTime();
    }, 1000);
  };
  const gotoCartPage = (url) => {
    setIsOpen(false);
    if (url === "/jobcardsummary") {
      window.location.href = url;
    } else if (url) {
      history.push(url);
    }
  };
  const navigateToHome = (url) => {
    history.push("/");
  };
  const gotoNewPage = (rlvv) => {
    history.push(rlvv);
    handleCloses();
  };
  const handleLogoutSec = () => {
    // setShowIslogoutModel(true);
    handleCloses();
    dispatch(
      updateAlertModelBox({
        title: "Are you sure!",
        subTitle: "Do you want to Logout?",
        icon: "warning",
        confirmBT: "Yes",
        cancelBT: "Cancel",
        confirmBTCall: () => {
          handleLogout();
        },
      })
    );
  };
  const getFullName = () => {
    var uName = "Full Name";
    if (loginStoreDetails?.firstName) {
      uName = `${user?.firstName?.split(" ")[0]}`;
    } else if (user?.firstName) {
      uName = `${user?.firstName?.split(" ")[0]}`;
    }
    return uName;
  };
  const getShortName = () => {
    var shortname = "";
    try {
      var firstName = user?.data?.firstName
        ? user?.data?.firstName.charAt(0).toUpperCase()
        : "";
      var lastName = user?.data?.lastName
        ? user?.data?.lastName.charAt(0).toUpperCase()
        : "";
      shortname = firstName + lastName;
    } catch (error) {}
    return shortname;
  };
  const togglePopover = () => {
    setIsOpen(!isOpen);
  };
  const handleLogout = () => {
    console.log("1111");
    localStorage.clear();
    // setIsOpen(!isOpen);
    dispatch(updateUserDetails(null));
    history.push("/");
  };
  // const logoutGotoNewPage = () => {
  //   localStorage.clear();
  //   dispatch(updateUserDetails(null));
  //   history.push("/signin");
  // };
  // console.log("user", user);
  // console.log("currentTime", currentTime);
  return (
    <>
      <div className="header-wrapper" id="headerWrapper">
        <div className="headerSection">
          <AlertModel />
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="allItemsSection">
              <div className="leftsideItem">
                <img
                  className="mainLogoImage"
                  onClick={() => {
                    gotoCartPage("/");
                  }}
                  src={logo4}
                ></img>

                {/* <span
                  className="logo-text-main"
                  onClick={() => {
                    gotoCartPage(user?.id ? "/" : "/");
                  }}
                >
                  DELTAPE
                </span> */}
              </div>
              <div className="centersideItem"></div>
              <div className="rightsideItem">
                {/* <span
                  className="cartIconPage"
                  onClick={() => {
                    gotoCartPage("/cart");
                  }}
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 16 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="heart-icon"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.69231 4.92308H1.84615C1.35631 4.92308 0.886769 5.11754 0.540923 5.464C0.194462 5.80984 0 6.27938 0 6.76923C0 8.47815 0 11.6431 0 14.1538C0 15.2966 0.453538 16.392 1.26154 17.2C2.06954 18.008 3.16492 18.4615 4.30769 18.4615C6.56308 18.4615 9.43692 18.4615 11.6923 18.4615C12.8351 18.4615 13.9305 18.008 14.7385 17.2C15.5465 16.392 16 15.2966 16 14.1538V6.76923C16 6.27938 15.8055 5.80984 15.4591 5.464C15.1132 5.11754 14.6437 4.92308 14.1538 4.92308H12.3077V4.30769C12.3077 1.92861 10.3791 0 8 0C5.62092 0 3.69231 1.92861 3.69231 4.30769V4.92308ZM14.7692 6.76923V14.1538C14.7692 14.9698 14.4449 15.7526 13.8683 16.3298C13.2911 16.9065 12.5083 17.2308 11.6923 17.2308C9.43692 17.2308 6.56308 17.2308 4.30769 17.2308C3.49169 17.2308 2.70892 16.9065 2.13169 16.3298C1.55508 15.7526 1.23077 14.9698 1.23077 14.1538V6.76923C1.23077 6.60615 1.29538 6.44923 1.41108 6.33415C1.52615 6.21846 1.68308 6.15384 1.84615 6.15384H14.1538C14.3169 6.15384 14.4738 6.21846 14.5889 6.33415C14.7046 6.44923 14.7692 6.60615 14.7692 6.76923ZM11.0769 4.92308V4.30769C11.0769 2.60861 9.69908 1.23077 8 1.23077C6.30092 1.23077 4.92308 2.60861 4.92308 4.30769V4.92308H11.0769Z"
                      fill="#1db954"
                    ></path>
                  </svg>
                </span> */}
                <span className="signInButtonArea">
                  {user?._id ? (
                    <>
                      {checkIsMobile() ? (
                        <>
                          {" "}
                          <Navbar
                            expand={"deltape"}
                            className="bg-body-tertiary mb-0 deltape-header-sec-nav-bar"
                          >
                            <Container fluid>
                              <Navbar.Toggle
                                aria-controls={`offcanvasNavbar-expand-deltape`}
                                onClick={handleShows}
                              />
                              <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-deltape`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-deltape`}
                                show={shows}
                                onHide={handleCloses}
                                className="nav-bar-sec-mobile-sec"
                                // placement="end"
                              >
                                <Offcanvas.Header closeButton>
                                  <Offcanvas.Title
                                    id={`offcanvasNavbarLabel-expand-deltape`}
                                  >
                                    <a
                                      className="navbar-brand left-image-sec-logo"
                                      href="#"
                                    >
                                      <img
                                        onClick={navigateToHome}
                                        src={logo4}
                                        className="brandImg"
                                        alt="Deltape"
                                        width="100"
                                        height="30"
                                      />
                                    </a>
                                  </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                  <>
                                    <div className="header-left-menu-container">
                                      <div className="left-menu-container1">
                                        <div className="left-header-sec-cont-name">
                                          <div>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="#fff"
                                              className="bi bi-person-circle"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                              <path
                                                fill-rule="evenodd"
                                                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                                              />
                                            </svg>
                                          </div>
                                          <div className="left-menu-sec-name">
                                            <span>{`${user?.firstName} ${user?.lastName}`}</span>
                                            {!isParentSite && <></>}
                                          </div>
                                        </div>

                                        <div className="mobile-links-menu-sec-all">
                                          {menuLinks.map((item, index) => {
                                            return (
                                              <div
                                                onClick={() => {
                                                  gotoNewPage(item?.link);
                                                }}
                                                className={
                                                  selectedUrl === item?.link
                                                    ? "selectedItem"
                                                    : ""
                                                }
                                              >
                                                {item?.title}
                                              </div>
                                            );
                                          })}
                                          <div>
                                            <span
                                              onClick={handleLogoutSec}
                                              className="logout-text-sec"
                                            >
                                              <img src={logo3} />{" "}
                                              <span>Logout</span>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                </Offcanvas.Body>
                              </Navbar.Offcanvas>
                            </Container>
                          </Navbar>
                        </>
                      ) : (
                        <>
                          <span
                            onClick={handleLogoutSec}
                            className="logout-text-sec"
                          >
                            <img src={logo3} /> <span>Logout</span>
                          </span>
                        </>
                      )}
                      {/* <span className="signed-user-name">{getFullName()}</span> */}
                      {/* <ReactPopOver
                        isOpen={isOpen}
                        body={
                          <DropdownList
                            handleLogout={handleLogout}
                            gotoNextPage={gotoCartPage}
                          />
                        }
                        className="header-popover-box"
                        place="below"
                        onOuterAction={togglePopover}
                      >
                        <span
                          className="btn-user-profile"
                          onClick={togglePopover}
                        >
                          <div className="profileIconStyles">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#17a2b8"
                              class="bi bi-person-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                              <path
                                fill-rule="evenodd"
                                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                              />
                            </svg>
                          </div>
                        </span>
                      </ReactPopOver> */}
                    </>
                  ) : (
                    <>
                      <div className="header-login-btns">
                        {" "}
                        <button
                          onClick={() => {
                            gotoCartPage("/signin");
                          }}
                          className="signInButton"
                        >
                          Sign In
                        </button>
                        <button
                          onClick={() => {
                            gotoCartPage("/signup");
                          }}
                          className="signInButton sign-up"
                        >
                          Start free Trial
                        </button>
                      </div>
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
