import React, { useEffect, useState } from "react";
import { ApplicationsStyled } from "./PlansandPayments.styled";
import { Row, Col, Button, Form, Dropdown } from "react-bootstrap";
import {
  getAllApplications,
  getAllApplicationsbyuser,
} from "./../../redux/slices/PlansandPayments/PlansandPaymentsService";
import { updateApplicationDetails } from "./../../redux/slices/PlansandPayments/PlansandPaymentsSlice";
import add_icon from "../../img/delta/add.png";
import BreadCrumb from "./../../components/BreadCrumb/BreadCrumb";
import NewChildComponent from "./../CustomerDashboard/NewChildComponent";
import { Tabs } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "./../../Scenes/common";
import CreateApplication from "./../../components/CreateApplication/CreateApplication";
const Applications = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [showCreateAppli, setShowCreateAppli] = useState(true); //false
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState("3");
  const [showAddNewChild, setShowAddNewChild] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [applicaId, setApplicaId] = useState("");
  const history = useHistory();

  const dispatch = useDispatch();
  const { user } = useSelector((authR) => authR?.auth);
  const { allInstitutes, instituteDetails } = useSelector(
    (authR) => authR?.instituteManagement
  );
  const { applicationDetails, userAllApplications, allApplications } =
    useSelector((authR) => authR?.plansandPayments);
  console.log("userAllApplications", userAllApplications);
  console.log("allApplications", allApplications);
  console.log("applicationDetails", applicationDetails);

  console.log("userAllApplications", userAllApplications);
  useEffect(() => {
    triggerMixpanelEvent("Contact_Us");
    dispatch(getAllApplicationsbyuser(user?._id));
  }, []);

  const onChangeValue = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleCreatenewApp = () => {
    setShowCreateAppli(true);
    setCurrentStep("1");
  };
  const cancelBtn = () => {
    setCurrentStep("1");
    setShowCreateAppli(false);
  };
  // const handleEdit = (itemD) => {
  //   setShowCreateAppli(true);
  //   setCurrentStep("2");
  //   dispatch(updateApplicationDetails(itemD));
  // };
  const handleAddNewChild = async () => {
    setApplicaId("");
    dispatch(updateApplicationDetails({}));
    history.push("/createApplication");
    // setShowAddNewChild(true);
  };
  const handleClose = async () => {
    setShowAddNewChild(false);
  };
  const handleEdit = (itm) => {
    setApplicaId(itm?._id);
    setShowAddNewChild(true);
  };
  const InstallementsSec = () => {
    return (
      <div className="application-tabs">
        <div className="all-emi-plans-row-all">
          {userAllApplications.map((item, index) => {
            const apppId2 = 500001 + index;
            return (
              <div
                className="all-emi-plans-row"
                onClick={() => {
                  handleEdit(item);
                }}
              >
                <div className="all-emi-plans-top-sec1">
                  <div className="d-flex">
                    <div>
                      {/* <div class="status-badge">Completed</div> */}
                      <h6>{`${item?.studentFirstName} ${item?.studentLastName}`}</h6>
                      <p>{item?.instituteName}</p>
                      <p>{item?.academicYear}</p>
                    </div>
                  </div>
                  <div>{`>`}</div>
                </div>
                <div className="all-emi-plans-top-sec2">
                  <div>
                    <div className="emi-plans-top-sec-sub1">
                      <h5>Application ID</h5>
                      <h6>{apppId2}</h6>
                    </div>
                    <div>
                      <h5>Fees</h5>
                      <h6>
                        ₹{parseInt(item?.feeAmount || 0)?.toLocaleString()}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="all-emi-plans-top-sec3">
                  <p>
                    Last updated on
                    <span>14/05/2024 </span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <>
      <ApplicationsStyled>
        <div className="freshbag-wrapper">
          <NewChildComponent
            applicationId={applicaId}
            show={showAddNewChild}
            handleClose={handleClose}
          />
          <div className="content getinTouchPage">
            <div className="contact">
              <div className="row">
                <div>
                  <div className="row">
                    <BreadCrumb list={[{ title: "Applications" }]} />
                    <div className="profileinfoHeader">
                      <div>
                        <span className="edit-p-text">Applications</span>
                        <div>
                          <button
                            className="deltape-button"
                            onClick={handleAddNewChild}
                          >
                            Add New Application &nbsp; <img src={add_icon} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p>
                        Find all your EMI applications, Autopay subscriptions
                        and transactions history here.
                      </p>
                    </div>
                    <div className="delata-tabs-sec">
                      <Tabs
                        defaultActiveKey="1"
                        items={[
                          {
                            label: "Easy Monthly Installements",
                            key: "1",
                            children: <InstallementsSec />,
                          },
                          {
                            label: "Auto Payments",
                            key: "2",
                            children: `Auto Payments`,
                          },
                          {
                            label: "Transaction History",
                            key: "3",
                            children: `Transaction History`,
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!showCreateAppli && (
            <div className="no-results">
              <p>No Transactions available</p>
            </div>
          )}
        </div>
      </ApplicationsStyled>
    </>
  );
};

export default Applications;
