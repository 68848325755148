import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createUserAPI,
  getAllUsers,
  getUserDetailsAPI,
} from "./UserManagementService";

export interface leadManagementState {
  loading: boolean;
  error: any;
  manageUserDetails: any;
  allUsers: any;
}

const initialState: leadManagementState = {
  loading: false,
  error: null,
  manageUserDetails: [],
  allUsers: [],
};

export const UserManagement = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    // updateUserDetails: (
    //   state: leadManagementState,
    //   action: PayloadAction<boolean>
    // ) => {
    //   state.user = action.payload;
    // },
    updateErrorMessage: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(createUserAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      createUserAPI.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.manageUserDetails = action.payload?.data;
      }
    );
    builder.addCase(
      createUserAPI.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.manageUserDetails = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    builder.addCase(getUserDetailsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getUserDetailsAPI.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.manageUserDetails = action.payload?.data;
      }
    );
    builder.addCase(
      getUserDetailsAPI.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.manageUserDetails = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );

    //getAllUsers
    builder.addCase(getAllUsers.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllUsers.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allUsers = action.payload;
      }
    );
    builder.addCase(
      getAllUsers.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.allUsers = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
  },
});

export const { setLoading, updateErrorMessage } = UserManagement.actions;

export default UserManagement.reducer;
