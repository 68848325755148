import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  signUpUser,
  addNewUserData,
  updateUserPasswordApi,
} from "../../redux/slices/auth/authService";
import { useHistory, useLocation } from "react-router-dom";
import { GOOGLE_MAP_URL } from "../../config";
import CustomModal from "../../components/CustomModel";
import { triggerMixpanelEvent } from "../../Scenes/common";
import { UsersListStyled } from "./UsersList.styled";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import clsx from "clsx";
import CloseButton from "react-bootstrap/CloseButton";

const ChangePassword = (props: any) => {
  const { selectedData, addUserSuccess, onHide, addPasswordSuccess } = props;
  const { error, loading, user } = useSelector((stateD: any) => stateD?.auth);
  const history = useHistory();
  const [passError, setPassError] = useState(false);
  const [passCError, setCPassError] = useState(false);
  const [showSuccessUserPopup, setShowSuccessUserPopup] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, errorErrorMessage] = useState("");

  const dispatch = useDispatch();

  const handleChangeValue = (e: any) => {
    let { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    } else {
      setConfirmPassword(value);
    }
  };

  const createAccountData = async () => {
    if (!password && !confirmPassword) {
      errorErrorMessage("Please enter Password and Confirm Password");
    } else if (!confirmPassword) {
      errorErrorMessage("Please enter Confirm Password");
    } else if (!password) {
      errorErrorMessage("Please enter Password");
    } else if (password && confirmPassword && password !== confirmPassword) {
      errorErrorMessage(
        "Password and Confirm Password fields value must be matched."
      );
    } else if (password && password?.length < 6) {
      errorErrorMessage("Password requires 6 characters minimum");
    } else if (password && confirmPassword) {
      const jsonB = {
        userId: selectedData?.id,
        changePassword: password,
      };
      const resp = (await dispatch(updateUserPasswordApi(jsonB))) as any;
      console.log("resp", resp);
      addPasswordSuccess();
    }
  };
  //     setPassError(null);
  //     var isValid = true;
  //     var errornew = { ...errorData };
  //     for (var key in initValError1) {
  //       const pValue = signUpData[key];
  //       if (
  //         signUpData[key] === "Select" ||
  //         signUpData[key] === "" ||
  //         !signUpData[key]
  //       ) {
  //         errornew[key] = true;
  //         isValid = false;
  //       }
  //       if (key === "contactNumber" && pValue?.length < 10) {
  //         errornew[key] = true;
  //         isValid = false;
  //       }
  //     }
  //     console.log("errornew", errornew);
  //     setErrorData({ ...errornew });
  //     if (!selectedData?.id) {
  //       if (
  //         signUpData?.password &&
  //         signUpData?.conformPassword &&
  //         signUpData?.password !== signUpData?.conformPassword
  //       ) {
  //         isValid = false;
  //         setPassError(
  //           "Password and Confirm Password fields value must be matched."
  //         );
  //       } else if (signUpData?.password && signUpData?.password?.length < 6) {
  //         isValid = false;
  //         setPassError("Password requires 6 characters minimum.");
  //       }
  //     }
  //     if (isValid) {
  //       makeSignUpCall(signUpData);
  //       console.log("signUpData 444", signUpData);
  //     }
  //   };
  //   const makeSignUpCall = async (data) => {
  //     const resp = await dispatch(addNewUserData(data));
  //     console.log("vinodddd", resp);
  //     if (resp?.payload?.id) {
  //       onHide();
  //       triggerMixpanelEvent("Add_user_success");
  //       addUserSuccess();
  //     } else if (resp?.error?.message) {
  //       errorErrorMessage(resp?.error?.message);
  //     }
  //   };
  const hideModel = () => {
    addUserSuccess(selectedData?.id);
    setShowSuccessUserPopup(false);
  };
  // const validationCheck = () => {
  //   var formValid = false;
  //   console.log("signUpData", signUpData);
  //   console.log("errorData", errorData);
  //   for (var key in errorData) {
  //     if (signUpData[key] === "" || !signUpData[key]) {
  //       formValid = true;
  //     }
  //   }
  //   return formValid;
  // };

  //   console.log("signUpData final", JSON.stringify(signUpData));
  //   console.log("errorData?.email", errorData);
  return (
    <Modal
      {...props}
      className="conformation-change-password-box1"
      size="lg"
      centered
    >
      <div className="conformation-model">
        <UsersListStyled>
          <Modal.Body>
            <div className="add-user-sec-box">
              <div className="new-option-header">
                <h4>{`Update Password`}</h4>
                <CloseButton onClick={props?.onHide} />
              </div>
              <div className="new-option-body">
                <div className="SignupContainer-signup">
                  <div>
                    <div>
                      <Form.Group className="signup-md22" controlId="email">
                        <Form.Label>
                          Password <span className="mand-sign-field">*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          autoFocus
                          name="password"
                          type="password"
                          value={password}
                          onChange={(e: any) => handleChangeValue(e)}
                          isInvalid={passError}
                        />
                      </Form.Group>
                    </div>
                    <Form.Group className="signup-md22" controlId="email">
                      <Form.Label>
                        Confirm Password{" "}
                        <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="lastName"
                        value={confirmPassword}
                        onChange={(e: any) => handleChangeValue(e)}
                        isInvalid={passCError}
                      />
                    </Form.Group>
                  </div>
                  {errorMessage && (
                    <p className="errorMessage">{errorMessage}</p>
                  )}
                  {/* {passError && <p className="errorMessage">{passError}</p>}
    //   {errorMessage && <p className="errorMessage">{errorMessage}</p>}
      {error && !passError && <p className="errorMessage">{error}</p>} */}
                  <div className="create-btn-full-width">
                    <div className="create-account-action-con">
                      <button
                        // disabled={validationCheck()}
                        onClick={() => {
                          createAccountData();
                        }}
                        type="submit"
                        className="create-account-btn-sec"
                      >
                        {loading ? "Loading" : "Update Password"}
                      </button>
                    </div>
                  </div>
                  <CustomModal
                    show={showSuccessUserPopup}
                    titleText="You have Successfully Created User."
                    deleteText="ok"
                    cancelText=""
                    onCancel={() => hideModel()}
                    onDelete={() => hideModel()}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
          <div className="new-option-body-actions">
            <Button onClick={handleSubmitFn}>Add New User</Button>
          </div>
        </Modal.Footer> */}
        </UsersListStyled>
      </div>
    </Modal>
  );
};

export default ChangePassword;
