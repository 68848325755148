import { IndexStyled } from "./index.styled";
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import clsx from "clsx";
import CloseButton from "react-bootstrap/CloseButton";
import CreateClient from "../CreateClient";
const CreateStudentModel = (props: any) => {
  const history = useHistory();
  const [passError, setPassError] = useState("");
  const dispatch = useDispatch();
  const [color, setColor] = useState("rgb(255, 255, 255)");
  const [feeComponent, setFeeComponent] = useState({} as any);

  useEffect(() => {}, [props?.show]);
  const handleSubmitFn = () => {
    const new344 = color.replace("rgb(", "").replace(")", "");
  };
  const handleChangeFeeComponent = (selectedOption: any) => {
    setFeeComponent(selectedOption);
  };
  return (
    <>
      <Modal {...props} size="xl" className="create-student-model-box" centered>
        <div className="conformation-model1">
          {/* <IndexStyled> */}
          <Modal.Body>
            <span
              className="color-picker-close-icon"
              onClick={() => props.handleClose(false)}
            >
              <CloseButton />
            </span>
            <div className="content-create-student">
              <CreateClient
                selectedStudentId={props?.selectedStudentId}
                handleClose={props?.handleClose}
                handleCloseSuccess={props.handleCloseSuccess}
                fromModel={true}
              />
            </div>
          </Modal.Body>
          {/* </IndexStyled> */}
        </div>
      </Modal>
    </>
  );
};
export default CreateStudentModel;
