import { StoresListStyled } from "./StoresList.styled";
import React, { useState, useEffect } from "react";
// import { getApprovedUsersCall } from "./../../redux/slices/serviceManagement/serviceManagementService";
// import { getApprovedUsersCall } from "./../../redux/slices/storeManagement/serviceManagementService";
// import { LOGINID } from "./../../Scenes/common";
import { useDispatch, useSelector } from "react-redux";

import {
  Row,
  Col,
  Button,
  Form,
  Tab,
  Nav,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { ReactComponent as BlockIcon } from "./../../assets/blockIcon.svg";
import { ReactComponent as RedBlockIcon } from "./../../assets/redBlockIcon.svg";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import { ReactComponent as HorizontalDots } from "./../../assets/HorizontalDots2.svg";
import "./styles.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import CustomModal from "./../../components/CustomModel";
import {
  getbusinessuserbyidCall,
  blockCustomer,
  getcustomeraddressCall,
  getPendingUsersCall,
  getRejectedUsersCall,
  getApprovedUsersCall,
  getPaymentDetailsCall,
  customerApprovelCall,
  customerRejectCall,
} from "../../redux/slices/storeManagement/storeManagementService";
// import { getApprovedUsersCall } from "./../../redux/slices/serviceManagement/serviceManagementService";
import SearchComponent from "./../../components/SearchComponent";
// import ImagePopup from "./../../components/ImagePopup";
import UserRejectPopup from "./UserRejectPopup";
import moment from "moment";
import { LOGINID } from "./../../Scenes/common";

const StoresList = () => {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
  ]);
  const [showAllUsers, setShowAllUsers] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [rejectedUsers, setRejectedUsers] = useState([]);
  const [showApprovedUsers, setShowApprovedUsers] = useState([]);
  const [showBlockUserPopup, setShowBlockUserPopup] = useState(false);
  const [showBlockSuccessUserPopup, setShowBlockSuccessUserPopup] =
    useState(false);
  const [showUnBlockUserPopup, setShowUnBlockUserPopup] = useState(false);
  const [showUnBlockSuccessUserPopup, setShowUnBlockSuccessUserPopup] =
    useState(false);
  const [selectedData, setSelectedData] = useState({} as any);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [userId, setUserId] = useState("");
  const [showBlockedUsers, setShowBlockedUsers] = useState(false);
  const [show, setShow] = useState(false);
  const [imageUrlForPopup, setImageUrlForPopup] = useState("");
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [showPendingUsersPopup, setShowPendingUsersPopup] = useState(false);
  const [showPendingUsersText, setShowPendingUsersText] = useState("");
  const [selctedType, setSelctedType] = useState("");
  const [selectedPendingUser, setSelectedPendingUser] = useState({} as any);
  const [showRejectUserUnputPopup, setShowRejectUserUnputPopup] =
    useState(false);
  const [purchaseHistory, setPurchaseHistory] = useState([]);

  const [approvedcurrentPage, setApprovedcurrentPage] = useState(1);
  const [pendingcurrentPage, setPendingcurrentPage] = useState(1);
  const [rejectedcurrentPage, setRejectedcurrentPage] = useState(1);

  const [usersPerPage, setUsersPerPage] = useState(15);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);

  const [approvedmaxPageNumberLimit, setApprovedmaxPageNumberLimit] =
    useState(5);
  const [approvedminPageNumberLimit, setApprovedminPageNumberLimit] =
    useState(0);

  const [pendingmaxPageNumberLimit, setPendingmaxPageNumberLimit] = useState(5);
  const [pendingminPageNumberLimit, setPendingminPageNumberLimit] = useState(0);

  const [rejectedmaxPageNumberLimit, setRejectedmaxPageNumberLimit] =
    useState(5);
  const [rejectedminPageNumberLimit, setRejectedminPageNumberLimit] =
    useState(0);

  const [approvedTotalCount, setApprovedTotalCount] = useState(0);
  const [pendingTotalCount, setPendingTotalCount] = useState(0);
  const [rejectedTotalCount, setRejectedTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [eventKeyTypes, setEventKeyTypes] = useState("approved");

  useEffect(() => {
    console.log("vinovvvvv", LOGINID);
    getAllUsersData("onLoad");
    if (LOGINID) {
      // getuserDataid(LOGINID);
    }
  }, []);
  const getAllUsersData = async (pageType?: any) => {
    const testJsonDa = {
      email: "vonod@gmail.com",
      phoneNumber: "9164236553",
      firstName: "vinod",
      lastName: "Tana",
    };
    const testData = [testJsonDa, testJsonDa, testJsonDa, testJsonDa];

    setLoading(true);
    // const resp = await getAllUsers();
    const approved = (await dispatch(
      getApprovedUsersCall({
        approvedcurrentPage,
        usersPerPage,
      })
    )) as any;
    console.log("approved", approved);
    const Pending = (await dispatch(
      getPendingUsersCall({
        pendingcurrentPage,
        usersPerPage,
      })
    )) as any;
    const Rejected = (await dispatch(
      getRejectedUsersCall({
        rejectedcurrentPage,
        usersPerPage,
      })
    )) as any;
    setLoading(false);
    let approvedUsers = approved?.payload || testData,
      pendingUsers = Pending?.payload || testData,
      rejectedUsers = Rejected?.payload || testData;
    setShowApprovedUsers(approvedUsers);
    setApprovedUsers(approvedUsers);
    setPendingUsers(pendingUsers);
    // setRejectedUsers(rejectedUsers);

    if (pageType == "onLoad") {
      setApprovedTotalCount(approved?.totalCount);

      setPendingTotalCount(Pending?.totalCount);

      setRejectedTotalCount(Rejected?.totalCount);
    }
  };
  const hideModel = () => {
    setShowBlockUserPopup(false);
    setShowBlockSuccessUserPopup(false);
    setShowUnBlockSuccessUserPopup(false);
    setShowUnBlockUserPopup(false);
    setShowPendingUsersPopup(false);
  };
  const getuserDataid = async (id: any) => {
    if (id) {
      const resp = (await getbusinessuserbyidCall(id)) as any;
      console.log(resp);
      const respt = resp?.customer;
      selectUserInfo(respt);
    }
  };
  const selectUserInfo = async (user: any) => {
    // window.scrollTo(0, 0);
    // setShowAllUsers(false);
    // setSelectedData(user);
    // // this.setState({ showAllUsers: false, selectedData: user });
    // const getAddress = (await getcustomeraddressCall(user?.id)) as any;
    // // this.setState({ selectedAddress: getAddress?.address });
    // setSelectedAddress(getAddress?.address);
    // const History = (await getPaymentDetailsCall(user?.id)) as any;
    // setPurchaseHistory(History?.data);
    //// this.setState({ purchaseHistory: History?.data });
  };
  const selectedUsersType = (e: any) => {
    let value = e.target.value;
    if (value == "all") {
      setShowApprovedUsers(approvedUsers);
    } else if (value == "blocked") {
      let finaalAction = approvedUsers.filter(
        (item: any) => item?.isBlocked == true
      );
      setShowApprovedUsers(finaalAction);
    } else if (value == "unBlocked") {
      let finaalAction = approvedUsers.filter(
        (item: any) => item?.isBlocked == false
      );
      setShowApprovedUsers(finaalAction);
    }
  };
  const blockUser = (user: any) => {
    setShowBlockUserPopup(true);
    setUserId(user.id);
    // this.setState({ showBlockUserPopup: true, userId: user.id });
  };
  const blockUserConform = async () => {
    let id = userId;
    const updatedUsers = showApprovedUsers as any;
    const index = updatedUsers.findIndex((item: any) => item.id == id);
    if (index > 0) {
      updatedUsers[index].isBlocked = true;
      setShowApprovedUsers(updatedUsers);
    }
    const updatedUsers1 = approvedUsers as any;
    const index1 = updatedUsers1.findIndex((item: any) => item.id == id);
    if (index1 > 0) {
      updatedUsers1[index1].isBlocked = true;
      setApprovedUsers(updatedUsers1);
    }

    const body = {
      id: id,
      isBlocked: true,
    };
    let resp = (await blockCustomer(body)) as any;
    if (resp.success) {
      setShowBlockUserPopup(false);
      setShowBlockSuccessUserPopup(true);
      // this.setState({
      //   showBlockUserPopup: false,
      //   showBlockSuccessUserPopup: true,
      // });
      getAllUsersData();
    }
  };
  const unBlockUser = (user: any) => {
    //   this.setState({ showUnBlockUserPopup: true, userId: user.id });
  };
  const unBlockUserConform = async () => {
    //   let id = this.state.userId;
    //   const updatedUsers = this.state.showApprovedUsers;
    //   const index = updatedUsers.findIndex((item) => item.id == id);
    //   if (index > 0) {
    //     updatedUsers[index].isBlocked = false;
    //     this.setState({ showApprovedUsers: updatedUsers });
    //   }
    //   const updatedUsers1 = this.state.approvedUsers;
    //   const index1 = updatedUsers1.findIndex((item) => item.id == id);
    //   if (index1 > 0) {
    //     updatedUsers1[index1].isBlocked = false;
    //     this.setState({ approvedUsers: updatedUsers1 });
    //   }
    //   const body = {
    //     id: id,
    //     isBlocked: false,
    //   };
    //   let resp = await blockCustomer(body);
    //   if (resp.success) {
    //     this.setState({
    //       showUnBlockUserPopup: false,
    //       showUnBlockSuccessUserPopup: true,
    //     });
    //     this.getAllUsersData();
    //   }
  };
  const showImagePopup1 = (image: any) => {
    //   this.setState({ imageUrlForPopup: image, showImagePopup: true });
  };
  const purchaseDetailsInfo = (id: any) => {
    //   this.props.history.push(`/dashboard/orderDetails/${id}`);
  };
  const getPrimaryAdress = (address: any) => {
    let finalAddress = "";
    (address || []).map((user: any, index: any) => {
      console.log(user);
      if (user?.isPrimary) {
        finalAddress = user?.address1 ? `${user?.address1}` : "";
        finalAddress += user?.address2 ? `, ${user?.address2}` : "";
        finalAddress += user?.city ? `, ${user?.city}` : "";
        finalAddress += user?.state ? `, ${user?.state}` : "";
        finalAddress += user?.county ? `, ${user?.county}` : "";
        finalAddress += user?.zipPostalCode ? `, ${user?.zipPostalCode}` : "";
      }
    });
    return finalAddress;
  };

  const getFullAdress = (user1: any) => {
    let finalAddress = "";

    finalAddress = user1?.address1 ? `${user1?.address1}` : "";
    finalAddress += user1?.address2 ? `, ${user1?.address2}` : "";
    finalAddress += user1?.city ? `, ${user1?.city}` : "";
    finalAddress += user1?.state ? `, ${user1?.state}` : "";
    finalAddress += user1?.county ? `, ${user1?.county}` : "";
    finalAddress += user1?.zipPostalCode ? `, ${user1?.zipPostalCode}` : "";

    return finalAddress;
  };
  const actionPendingUser = (type: any, user: any) => {
    setSelectedPendingUser(user);
    setSelctedType(type);
    setShowPendingUsersPopup(true);
    if (type == "Approve") {
      setShowPendingUsersText("Are you sure you want to Approve this Store?");

      // this.setState({
      //   selectedPendingUser: user,
      //   selctedType: type,
      //   showPendingUsersPopup: true,
      //   showPendingUsersText: ,
      // });
    } else {
      setShowPendingUsersText("Are you sure you want to Reject this Store?");

      // this.setState({
      //   selectedPendingUser: user,
      //   selctedType: type,
      //   showPendingUsersPopup: true,
      //   showPendingUsersText: "Are you sure you want to Reject this User?",
      // });
    }
  };
  const clickConformAction = async () => {
    setShowPendingUsersPopup(false);

    // this.setState({ showPendingUsersPopup: false });
    if (selctedType == "Approve") {
      const body = {
        id: selectedPendingUser?.id,
        isApproved: true,
      };
      const resp = (await dispatch(customerApprovelCall(body))) as any;
      if (resp?.payload?.id) {
        // this.setState({
        //   showPendingUsersPopup: true,
        //   selctedType: "",
        //   showPendingUsersText: "You have Approved the User Successfully.",
        // });
        setShowPendingUsersPopup(true);
        setSelctedType("");
        setShowPendingUsersText("You have Approved the Store Successfully.");
        getAllUsersData();
      } else if (
        resp?.errors?.length > 0 &&
        resp?.errors[0] != undefined &&
        resp?.errors[0] != ""
      ) {
        // this.setState({
        //   showPendingUsersPopup: true,
        //   selctedType: "",
        //   showPendingUsersText: ,
        // });
        setShowPendingUsersPopup(true);
        setSelctedType("");
        setShowPendingUsersText(resp?.errors[0]);
      }
    } else if (selctedType == "Reject") {
      setShowRejectUserUnputPopup(true);
      // this.setState({ showRejectUserUnputPopup: true });
    }
  };
  const clickConformRejectAction = async (comment: any) => {
    if (comment != "") {
      setShowRejectUserUnputPopup(false);
      // this.setState({ showRejectUserUnputPopup: false });
      // const { selctedType, selectedPendingUser } = this.state;
      const body = {
        id: selectedPendingUser?.id,
        isApproved: false,
        comments: comment,
      };
      const resp = (await dispatch(customerRejectCall(body))) as any;
      if (resp?.payload?.id) {
        // this.setState({
        //   showPendingUsersPopup: true,
        //   selctedType: "",
        //   showPendingUsersText: "This user has been rejected.",
        // });
        setShowPendingUsersPopup(true);
        setSelctedType("");
        setShowPendingUsersText("This Store has been rejected.");

        getAllUsersData();
      }
    }
  };
  const cancelRejectPopup = () => {
    setShowRejectUserUnputPopup(false);
    setShowPendingUsersPopup(true);
    setSelctedType("");
    setShowPendingUsersText("This Store has not been rejected.");
    // this.setState({ showRejectUserUnputPopup: false });
    // this.setState({
    //   showPendingUsersPopup: true,
    //   selctedType: "",
    //   showPendingUsersText: "This user has not been rejected.",
    // });
  };

  const eachPageNumberClick = (pageno: any, type: any) => {
    // if (type == "approved") {
    //   this.setState({ approvedcurrentPage: pageno }, () => {
    //     this.getusersBasedType("approved");
    //   });
    // } else if (type == "pending") {
    //   this.setState({ pendingcurrentPage: pageno }, () => {
    //     this.getusersBasedType("pending");
    //   });
    // } else if (type == "rejected") {
    //   this.setState({ rejectedcurrentPage: pageno }, () => {
    //     this.getusersBasedType("rejected");
    //   });
    // }
  };
  const pageNextBtn = (type: any) => {
    // if (type == "approved") {
    //   const a = this.state;
    //   if (a.approvedcurrentPage + 1 > a.approvedmaxPageNumberLimit) {
    //     this.setState({
    //       approvedmaxPageNumberLimit:
    //         a.approvedmaxPageNumberLimit + a.pageNumberLimit,
    //     });
    //     this.setState({
    //       approvedminPageNumberLimit:
    //         a.approvedminPageNumberLimit + a.pageNumberLimit,
    //     });
    //   }
    //   this.setState({ approvedcurrentPage: a.approvedcurrentPage + 1 }, () => {
    //     this.getusersBasedType("approved");
    //   });
    // } else if (type == "pending") {
    //   const a = this.state;
    //   if (a.pendingcurrentPage + 1 > a.pendingmaxPageNumberLimit) {
    //     this.setState({
    //       pendingmaxPageNumberLimit:
    //         a.pendingmaxPageNumberLimit + a.pageNumberLimit,
    //     });
    //     this.setState({
    //       pendingminPageNumberLimit:
    //         a.pendingminPageNumberLimit + a.pageNumberLimit,
    //     });
    //   }
    //   this.setState({ pendingcurrentPage: a.pendingcurrentPage + 1 }, () => {
    //     this.getusersBasedType("pending");
    //   });
    // } else if (type == "rejected") {
    //   const a = this.state;
    //   if (a.rejectedcurrentPage + 1 > a.rejectedmaxPageNumberLimit) {
    //     this.setState({
    //       rejectedmaxPageNumberLimit:
    //         a.rejectedmaxPageNumberLimit + a.pageNumberLimit,
    //     });
    //     this.setState({
    //       rejectedminPageNumberLimit:
    //         a.rejectedminPageNumberLimit + a.pageNumberLimit,
    //     });
    //   }
    //   this.setState({ rejectedcurrentPage: a.rejectedcurrentPage + 1 }, () => {
    //     this.getusersBasedType("rejected");
    //   });
    // }
  };
  // pendingmaxPageNumberLimit:5,
  // pendingminPageNumberLimit:0,

  // rejectedmaxPageNumberLimit:5,
  // rejectedminPageNumberLimit:0,
  const pagePrevBtn = (type: any) => {
    // if (type == "approved") {
    //   const a = this.state;
    //   if ((a.approvedcurrentPage - 1) % a.pageNumberLimit == 0) {
    //     this.setState({
    //       approvedmaxPageNumberLimit:
    //         a.approvedmaxPageNumberLimit - a.pageNumberLimit,
    //     });
    //     this.setState({
    //       approvedminPageNumberLimit:
    //         a.approvedminPageNumberLimit - a.pageNumberLimit,
    //     });
    //   }
    //   this.setState({ approvedcurrentPage: a.approvedcurrentPage - 1 }, () => {
    //     this.getusersBasedType("approved");
    //   });
    // } else if (type == "pending") {
    //   const a = this.state;
    //   if ((a.pendingcurrentPage - 1) % a.pageNumberLimit == 0) {
    //     this.setState({
    //       pendingmaxPageNumberLimit:
    //         a.pendingmaxPageNumberLimit - a.pageNumberLimit,
    //     });
    //     this.setState({
    //       pendingminPageNumberLimit:
    //         a.pendingminPageNumberLimit - a.pageNumberLimit,
    //     });
    //   }
    //   this.setState({ pendingcurrentPage: a.pendingcurrentPage - 1 }, () => {
    //     this.getusersBasedType("pending");
    //   });
    // } else if (type == "rejected") {
    //   const a = this.state;
    //   if ((a.rejectedcurrentPage - 1) % a.pageNumberLimit == 0) {
    //     this.setState({
    //       rejectedmaxPageNumberLimit:
    //         a.rejectedmaxPageNumberLimit - a.pageNumberLimit,
    //     });
    //     this.setState({
    //       rejectedminPageNumberLimit:
    //         a.rejectedminPageNumberLimit - a.pageNumberLimit,
    //     });
    //   }
    //   this.setState({ rejectedcurrentPage: a.rejectedcurrentPage - 1 }, () => {
    //     this.getusersBasedType("rejected");
    //   });
    // }
  };
  const getusersBasedType = async (pageType: any) => {
    // const {
    //   usersPerPage,
    //   approvedcurrentPage,
    //   pendingcurrentPage,
    //   rejectedcurrentPage,
    // } = this.state;
    // if (pageType == "approved") {
    //   const approved = await getApprovedUsersCall(
    //     approvedcurrentPage,
    //     usersPerPage
    //   );
    //   let approvedUsers1 = approved?.data || [];
    //   this.setState({
    //     showApprovedUsers: approvedUsers1,
    //     approvedUsers: approvedUsers1,
    //   });
    // } else if (pageType == "pending") {
    //   const Pending = await getPendingUsersCall(
    //     pendingcurrentPage,
    //     usersPerPage
    //   );
    //   let pendingUsers1 = Pending?.data || [];
    //   this.setState({ pendingUsers: pendingUsers1 });
    // } else if (pageType == "rejected") {
    //   const Rejected = await getRejectedUsersCall(
    //     rejectedcurrentPage,
    //     usersPerPage
    //   );
    //   let rejectedUsers1 = Rejected?.data || [];
    //   this.setState({ rejectedUsers: rejectedUsers1 });
    // }
  };
  const approvedpages = [];
  for (let i = 1; i <= Math.ceil(approvedTotalCount / usersPerPage); i++) {
    approvedpages.push(i);
  }
  const approvedrenderPageNumbers = approvedpages.map((number) => {
    if (
      number < approvedmaxPageNumberLimit + 1 &&
      number > approvedminPageNumberLimit
    ) {
      return (
        <li
          key={number}
          onClick={() => {
            eachPageNumberClick(number, "approved");
          }}
          className={approvedcurrentPage == number ? "active" : "null"}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });
  const pendingpages = [];
  for (let i = 1; i <= Math.ceil(pendingTotalCount / usersPerPage); i++) {
    pendingpages.push(i);
  }
  const pendingrenderPageNumbers = pendingpages.map((number) => {
    if (
      number < pendingmaxPageNumberLimit + 1 &&
      number > pendingminPageNumberLimit
    ) {
      return (
        <li
          key={number}
          onClick={() => {
            eachPageNumberClick(number, "pending");
          }}
          className={pendingcurrentPage == number ? "active" : "null"}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });
  const rejectedpages = [];
  for (let i = 1; i <= Math.ceil(rejectedTotalCount / usersPerPage); i++) {
    rejectedpages.push(i);
  }
  const rejectedrenderPageNumbers = rejectedpages.map((number) => {
    if (
      number < rejectedmaxPageNumberLimit + 1 &&
      number > rejectedminPageNumberLimit
    ) {
      return (
        <li
          key={number}
          onClick={() => {
            eachPageNumberClick(number, "rejected");
          }}
          className={rejectedcurrentPage == number ? "active" : "null"}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  return (
    <>
      <StoresListStyled>
        <div className="USerMangement-wrapper">
          {showAllUsers && (
            <div className="userslIstManagement">
              <Row className="umHeadRow">
                <span className="headerName">Store Mangement</span>
              </Row>
              <Tab.Container
                id="article-tabs"
                activeKey={eventKeyTypes}
                onSelect={(selectedKey: any) => setEventKeyTypes(selectedKey)}
              >
                <Row sm="12">
                  <Col sm="12" className="noPadding">
                    <Nav variant="tabs" className="collectionsTabs">
                      <Nav.Item>
                        <Nav.Link eventKey="approved">Approved Stores</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="pending">Pending Stores</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="rejected">Rejected Stores</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm="12" className="noPadding">
                    <Tab.Content>
                      <Tab.Pane eventKey="approved">
                        <Row className="userSearchRow">
                          <SearchComponent placeHolderText="Quick Search" />
                          <div className="filterByBlock">
                            <span className="sortByText">Filter by:</span>
                            <Form.Control
                              as="select"
                              className="filterBySelect"
                              onChange={selectedUsersType}
                            >
                              <option value="all">All</option>
                              <option value="blocked">Blocked</option>
                              <option value="unBlocked">Un Blocked</option>
                            </Form.Control>
                          </div>
                        </Row>
                        <div className="Usertable-info">
                          {loading ? (
                            <>
                              <div className="spinner-borderWord">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <table>
                              <thead>
                                <tr className="headerNamesList">
                                  <th>Store Name</th>
                                  <th>Store Email</th>
                                  <th>Store ID</th>
                                  <th>Store Phone Number</th>
                                  <th>Address</th>
                                  <th>Status</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(showApprovedUsers || []).map(
                                  (user: any, key: any) => {
                                    return (
                                      <>
                                        {" "}
                                        <tr className="RowLevelClass bodyRowTextStyles">
                                          <td
                                            onClick={() => selectUserInfo(user)}
                                          >
                                            {" "}
                                            {user.storeName}{" "}
                                          </td>
                                          <td
                                            onClick={() => selectUserInfo(user)}
                                          >
                                            {user.storeEmail}
                                          </td>
                                          <td
                                            onClick={() => selectUserInfo(user)}
                                          >
                                            {user.storeId}
                                          </td>
                                          <td
                                            onClick={() => selectUserInfo(user)}
                                          >
                                            {user?.storeMobileNo ||
                                              user?.contactNumber}
                                          </td>
                                          <td
                                            onClick={() => selectUserInfo(user)}
                                          >
                                            {/* {getFullAdress(user?.address)} */}
                                            {user?.address}
                                          </td>
                                          <td>
                                            {user?.approved
                                              ? "Approved"
                                              : "Pending"}
                                          </td>
                                          {user.isBlocked ? (
                                            <td
                                              className="blockIcon"
                                              onClick={() => unBlockUser(user)}
                                            >
                                              <RedBlockIcon />
                                            </td>
                                          ) : (
                                            <td
                                              className="blockIcon"
                                              onClick={() => blockUser(user)}
                                            >
                                              <BlockIcon />
                                            </td>
                                          )}
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          )}
                        </div>
                        {!loading && approvedTotalCount > 10 && (
                          <Row className="paginationBoxes">
                            <button
                              className="arrowBox"
                              onClick={() => pagePrevBtn("approved")}
                              style={{
                                display:
                                  approvedcurrentPage == approvedpages[0]
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {" "}
                              <HorizontalDots />{" "}
                            </button>
                            <ul className="pageNumber">
                              {approvedrenderPageNumbers}{" "}
                            </ul>
                            <button
                              className="arrowBox paginationNextBtn"
                              onClick={() => pageNextBtn("approved")}
                              style={{
                                display:
                                  approvedcurrentPage ==
                                  approvedpages[approvedpages.length - 1]
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {" "}
                              <HorizontalDots />{" "}
                            </button>
                          </Row>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="pending">
                        <Row className="userSearchRow">
                          <SearchComponent placeHolderText="Quick Search" />
                        </Row>
                        <div className="Usertable-info">
                          {loading ? (
                            <>
                              <div className="spinner-borderWord">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <table>
                              <thead>
                                <tr className="headerNamesList">
                                  <th>Store Name</th>
                                  <th>Store Email</th>
                                  <th>Store Phone Number</th>
                                  <th>Address</th>
                                  <th>Status</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(pendingUsers || []).map((user: any, key) => {
                                  return (
                                    <>
                                      {" "}
                                      <tr className="RowLevelClass bodyRowTextStyles">
                                        {/* <td onClick={()=>selectUserInfo(item)}>#BID100777</td> */}
                                        <td
                                          onClick={() => selectUserInfo(user)}
                                        >
                                          {" "}
                                          {user.storeName}{" "}
                                        </td>
                                        <td
                                          onClick={() => selectUserInfo(user)}
                                        >
                                          {user.storeEmail}
                                        </td>
                                        <td
                                          onClick={() => selectUserInfo(user)}
                                        >
                                          {" "}
                                          {user?.storeMobileNo ||
                                            user?.contactNumber}
                                        </td>
                                        <td
                                          onClick={() => selectUserInfo(user)}
                                        >
                                          {/* {getFullAdress(user?.address)} */}
                                          {user?.address}
                                        </td>
                                        <td>
                                          {user?.approved
                                            ? "Approved"
                                            : "Pending"}
                                        </td>
                                        <td>
                                          <Dropdown className="actionsDropdown ">
                                            <Dropdown.Toggle>
                                              <HorizontalDots className="dottedLineGrid" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item> </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  actionPendingUser(
                                                    "Approve",
                                                    user
                                                  );
                                                }}
                                              >
                                                {" "}
                                                Approve
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  actionPendingUser(
                                                    "Reject",
                                                    user
                                                  );
                                                }}
                                              >
                                                {" "}
                                                Reject
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                        {!loading && pendingTotalCount > 10 && (
                          <Row className="paginationBoxes">
                            <button
                              className="arrowBox"
                              onClick={() => pagePrevBtn("pending")}
                              style={{
                                display:
                                  pendingcurrentPage == pendingpages[0]
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {" "}
                              <HorizontalDots />{" "}
                            </button>
                            <ul className="pageNumber">
                              {pendingrenderPageNumbers}{" "}
                            </ul>
                            <button
                              className="arrowBox paginationNextBtn"
                              onClick={() => pageNextBtn("pending")}
                              style={{
                                display:
                                  pendingcurrentPage ==
                                  pendingpages[pendingpages.length - 1]
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {" "}
                              <HorizontalDots />{" "}
                            </button>
                          </Row>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="rejected">
                        <Row className="userSearchRow">
                          <SearchComponent placeHolderText="Quick Search" />
                        </Row>
                        <div className="Usertable-info">
                          {loading ? (
                            <>
                              <div className="spinner-borderWord">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <table>
                              <thead>
                                <tr className="headerNamesList">
                                  {/* <th width="10%">Bidding ID</th> */}
                                  <th>Full name</th>
                                  <th>Email Address</th>
                                  <th>Phone Number</th>
                                  <th>Address</th>
                                  <th>Uploaded Documents</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(rejectedUsers || []).map((user: any, key) => {
                                  // console.log("UsEr", user)
                                  return (
                                    <>
                                      {" "}
                                      <tr className="RowLevelClass bodyRowTextStyles">
                                        <td
                                          onClick={() => selectUserInfo(user)}
                                        >
                                          {" "}
                                          {user.firstName} {user.lastName}{" "}
                                        </td>
                                        <td
                                          onClick={() => selectUserInfo(user)}
                                        >
                                          {user.email}
                                        </td>
                                        <td
                                          onClick={() => selectUserInfo(user)}
                                        >
                                          {user?.phoneCode ? "+" : ""}
                                          {user?.phoneCode} {user.phoneNumber}{" "}
                                        </td>
                                        <td
                                          onClick={() => selectUserInfo(user)}
                                        >
                                          {getFullAdress(user?.address)}
                                        </td>
                                        <td></td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                        {!loading && rejectedTotalCount > 10 && (
                          <Row className="paginationBoxes">
                            <button
                              className="arrowBox"
                              onClick={() => pagePrevBtn("rejected")}
                              style={{
                                display:
                                  rejectedcurrentPage == rejectedpages[0]
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {" "}
                              <HorizontalDots />{" "}
                            </button>
                            <ul className="pageNumber">
                              {rejectedrenderPageNumbers}{" "}
                            </ul>
                            <button
                              className="arrowBox paginationNextBtn"
                              onClick={() => pageNextBtn("rejected")}
                              style={{
                                display:
                                  rejectedcurrentPage ==
                                  rejectedpages[rejectedpages.length - 1]
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {" "}
                              <HorizontalDots />{" "}
                            </button>
                          </Row>
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          )}
          {!showAllUsers && (
            <div className="basicInfoManagement">
              <Row className="backheaderName">
                {" "}
                <span
                  onClick={() => {
                    setShowAllUsers(true);
                  }}
                >
                  <ChevronLeft className="umChevronLeft" /> Store Mangement{" "}
                </span>{" "}
              </Row>
              <div className="centerfullScreen">
                <div className="centerText">
                  <p className="backheaderName1">Basic Information</p>
                  <div className="basicUserInfoRoles">
                    <div className="avatorIcon">
                      <div className="avatarImage">
                        {selectedData?.firstName?.slice(0, 1)}
                      </div>
                      <p className="nameCapitalize">
                        {selectedData?.firstName} {selectedData?.lastName}
                      </p>
                    </div>
                    <div className="infoList card-columns1">
                      <div>
                        <div>
                          <span> Email Address</span>
                        </div>
                        <div>
                          <span> {selectedData?.email}</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span> Mobile</span>
                        </div>
                        <div>
                          <span>
                            {" "}
                            {selectedData?.phoneNumber ? (
                              <>
                                {" "}
                                {selectedData?.phoneCode ? "+" : ""}
                                {selectedData?.phoneCode}{" "}
                                {selectedData?.phoneNumber}{" "}
                              </>
                            ) : (
                              "XXXXXXXXXXXXX"
                            )}{" "}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span> Primary Address</span>
                        </div>
                        <div>
                          <span> {getPrimaryAdress(selectedAddress)}</span>
                        </div>
                      </div>
                    </div>
                    <Row className="infoList card-columns1">
                      {(selectedAddress || []).map((item: any, index: any) => {
                        return (
                          <>
                            {!item?.isPrimary && (
                              <div>
                                <div>
                                  <span> Address {index + 1}</span>
                                </div>
                                <div>
                                  <span> {getFullAdress(item)}</span>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </Row>
                    <div className="infoList card-columns1">
                      <div>
                        <div>
                          <span> Photo ID</span>
                        </div>
                        <div className="showUnderLine">
                          <span>
                            {" "}
                            {
                              selectedData?.identityProof?.split("_")[
                                selectedData?.identityProof?.split("_").length -
                                  1
                              ]
                            }{" "}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span> Proof of Address</span>
                        </div>
                        <div className="showUnderLine">
                          <span>
                            {" "}
                            {
                              selectedData?.addressProof?.split("_")[
                                selectedData?.addressProof?.split("_").length -
                                  1
                              ]
                            }{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <CustomModal
            show={showBlockUserPopup}
            titleText="Are you sure you want to block the Store?"
            deleteText="Yes"
            cancelText="No"
            onCancel={() => hideModel()}
            onDelete={() => blockUserConform()}
          />
          <CustomModal
            show={showBlockSuccessUserPopup}
            titleText="You have blocked Store Successfully."
            deleteText="ok"
            cancelText=""
            onCancel={() => hideModel()}
            onDelete={() => hideModel()}
          />
          <CustomModal
            show={showUnBlockUserPopup}
            titleText="Are you sure you want to Unblock the Store?"
            deleteText="Yes"
            cancelText="No"
            onCancel={() => hideModel()}
            onDelete={() => unBlockUserConform()}
          />
          <CustomModal
            show={showUnBlockSuccessUserPopup}
            titleText="You have Unblocked Store Successfully."
            deleteText="ok"
            cancelText=""
            onCancel={() => hideModel()}
            onDelete={() => hideModel()}
          />
          <CustomModal
            show={showPendingUsersPopup}
            titleText={showPendingUsersText}
            deleteText={selctedType != "" ? selctedType : "OK"}
            cancelText={selctedType != "" ? "Cancel" : ""}
            onCancel={() => hideModel()}
            onDelete={() => clickConformAction()}
          />

          <UserRejectPopup
            show={showRejectUserUnputPopup}
            onCancel={() => {
              cancelRejectPopup();
            }}
            onDelete={clickConformRejectAction}
          />
        </div>
      </StoresListStyled>{" "}
    </>
  );
};
export default StoresList;
