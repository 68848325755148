import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { TestingStyled } from "./Testing.styled";

import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Axios from "axios";
import Swal from "sweetalert2";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import clsx from "clsx";
import CloseButton from "react-bootstrap/CloseButton";
const AlertModel = (props: any) => {
  // console.log("propsprops", props);

  const history = useHistory();
  const [isError, setIsError] = useState("");
  const dispatch = useDispatch();
  const [color, setColor] = useState("rgb(255, 255, 255)");
  const [miles, setMiles] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [isLoding, setIsLoding] = useState(false);

  useEffect(() => {
    showAlertModel();
  }, []);
  const showAlertModel = () => {
    Swal.fire({
      title: "Are you sure!",
      text: "Do you want to Logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#14a2b8",
      // cancelButtonColor: "#fff",
      // cancelTextColor: "#000",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  };
  return (
    <>
      <TestingStyled></TestingStyled>
    </>
  );
};
export default AlertModel;
