import { CreateOrderStyled } from "./CreateOrder.styled";
import React, { useState, useEffect, Component, useRef } from "react";
import CustomModal from "../../components/CustomModel";
import ImageViewModel from "../../components/ImageViewModel";

import { useHistory } from "react-router";
import AddNewUser from "./../../components/AddNewUser/AddNewUser";
import camera_line from "./../../img/cameera_icon.png";

import {
  Row,
  Col,
  Accordion,
  Card,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import TimePicker from "rc-time-picker";
import Select from "react-select";
import {
  getAllCustomersList,
  deleteCustomerApi,
} from "../../redux/slices/auth/authService";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import "rc-time-picker/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createJobCardApi,
  getProductsListApi,
  getJobCardDetailsApi,
  getDisplayJobCardFormApi,
  getBrandsByProductIdApi,
  getItemsRecievedByProductIdAPi,
  getDefaultIssuesByProductIdAPi,
  getCustomerAdditionalReqAPi,
  getJobCardOptionsBasedOnProductId,
} from "./../../redux/slices/serviceManagement/serviceManagementService";
import { getCustomerSearch } from "./../../redux/slices/auth/authService";
import moment from "moment";
import {
  statusList,
  severityOptions,
  outFitTypesList,
} from "./../../Scenes/constants";
// import PrevListSection from "./PrevListSection";
import arrow_down from "./../../img/arrow-205.png";
import password_pattern from "./../../img/password-pattern.png";
import icons_plus from "./../../img/icons8-plus-50.png";
import icons_edit from "./../../img/icons8-edit-32.png";
import ImageUploadModel from "./../PrintPageSetting/ImageUploadModel/ImageUploadModel";

import { triggerMixpanelEvent } from "../../Scenes/common";
const format = "h:mm a";
const currentDate = new Date();

const currentHour = currentDate.getHours();
const currentMinute = currentDate.getMinutes();

var timeNow = moment().hour(currentHour).minute(currentMinute);
// moment(new Date()).format("h:mm a");
const defaultAction = { id: 1, serviceTaken: "" };
const partsReplaced = { id: 1, partName: "", partSerialno: "" };

const requiredFieldsList = {
  customerName: false,
  mobileNo: false,
  productName: false,
  brandName: false,
  serialNo1: false,
  deliveryDateTemp: false,
  deliveryDateTempTime: false,
  minCharges: false,
  paymentType: false,
  incommingSource: false,

  itemRecived: false,
  defaultIssues: false,
  customerAddRequirements: false,
  selectedUserId: false,

  // systemPassword: false,
  // severity: true,
  // customerAdditionalReqRemarks: true,
};
const requiredFieldsList1 = {
  customerName: false,
  mobileNo: false,
  productName: false,
  brandName: false,
  serialNo1: false,
  deliveryDateTemp: false,
  deliveryDateTempTime: false,
  minCharges: false,
  paymentType: false,
  incommingSource: false,

  itemRecived: false,
  defaultIssues: false,
  customerAddRequirements: false,
  selectedUserId: false,
  // receiverPersonSame: false,
  // originalJobCardReceived: false,
  // systemPassword: false,
  // severity: true,
  // customerAdditionalReqRemarks: true,
};
const editRequiredFields1 = {
  toCourierName: false,
  tempToCourierDate: false,
  toDocNumber: false,
  toCompanyName: false,
};

const editRequiredFields3 = {
  receiveingCourierName: false,
  tempReceiveingToCourierDate: false,
  receiveingDocNumber: false,
};

const CreateJobcard = (props) => {
  const [jobData, setJobData] = useState({
    minCharges: "150",
    priority: "",
  });
  // const [requiredData, setRequiredData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [itemsRecievedList, setItemsRecievedList] = useState([]);
  const [defaultIssues, setDefaultIssues] = useState([]);
  const [cARequirements, setCARequirements] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSuccessPopupUser, setShowSuccessPopupUser] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [actionTakenList, setActionTakenList] = useState([]);
  const [engineerObservationList, setEngineerObservationList] = useState([]);
  const [addNewUserPopup, setAddNewUserPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requiredFields, setRequiredFields] = useState(requiredFieldsList);
  const [requiredFields1, setRequiredFields1] = useState(requiredFieldsList1);
  const [selectedUserEditData, setSelectedUserEditData] = useState({});
  const [tempUserPhoneNumber, setTempUserPhoneNumber] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState(
    new Date().toLocaleString()
  );
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showImageMoodel, setShowImageMoodel] = useState(false);
  const [showImageViewMoodel, setShowImageViewMoodel] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [fimgName, setFimgName] = useState("");

  const [editRequiredFields, setEditRequiredFields] =
    useState(editRequiredFields1);
  const [editRequiredFields2, setEditRequiredFields2] =
    useState(editRequiredFields3);
  const [showItemReceived, setShowItemReceived] = useState(true);
  const [showDefaultIssues, setShowDefaultIssues] = useState(true);
  const [showCustomerAR, setShowCustomerAR] = useState(true);

  const [showItemReceivedRemarks, setShowItemReceivedRemarks] = useState(false);
  const [showDefaultIssuesRemarks, setShowDefaultIssuesRemarks] =
    useState(false);
  const [showCustomerARRemarks, setShowCustomerARRemarks] = useState(false);

  const [errorText, setErrorText] = useState("");
  const [errorModel, setErrorModel] = useState(false);
  const [openEngineerObservationsBox, setOpenEngineerObservationsBox] =
    useState(false);
  const [openCustomerNotesBox, setOpenCustomerNotesBox] = useState(false);
  const history = useHistory();
  const dropdownRef = useRef();

  // const [itemsRecievedListNoItems, setItemsRecievedListNoItems] = useState(
  //   false
  // );
  // const [defaultIssuesNoItems, setDefaultIssuesNoItems] = useState(false);
  // const [cARequirementsNoItems, setCARequirementsNoItems] = useState(false);

  const [itemsList, setItemsList] = useState([{ name: "" }]);
  const [updateData, setUpdateData] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showDropDown1, setShowDropDown1] = useState(false);
  const [userSelectedInfo, setUserSelectedInfo] = useState({});
  const [imagesList, setImagesList] = useState([]);
  const [actionTakenItems, setActionTakenItems] = useState([
    { ...defaultAction },
  ]);
  const [partsReplacedItems, setPartsReplacedItems] = useState([
    { ...partsReplaced },
  ]);
  const {
    displayJobCardDetails,
    brandsByProductIdResp,
    itemsRecievedByProductIdResp,
    defaultIssuesByProductIdResp,
    customerAdditionalReqResp,
    jobCardDetails,
    getJobCardOptionsBasedOnProductIdResp,
  } = useSelector((state) => state.orders);
  const { searchUsersList, user, allCustomersList } = useSelector(
    (state) => state.auth
  );
  // console.log("itemsRecievedByProductIdResp", itemsRecievedByProductIdResp);
  // console.log("defaultIssuesByProductIdResp", defaultIssuesByProductIdResp);
  // console.log("customerAdditionalReqResp", customerAdditionalReqResp);
  const jobId = props?.match?.params?.jobId;
  const dispatch = useDispatch();
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropDown(false);
        setShowDropDown1(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // console.log("jobIdjobId", jobId);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getDisplayJobCardFormApi(null));
    dispatch(getAllCustomersList());
    if (jobId) {
      triggerMixpanelEvent("edit_job_card_page", { jobId });
      setShowItemReceived(false);
      setShowDefaultIssues(false);
      setShowCustomerAR(false);
      dispatch(getJobCardDetailsApi(jobId));
      // var timeNow = moment().hour(currentHour).minute(currentMinute);
    } else {
      triggerMixpanelEvent("create_job_card_page");
      // const newTime = moment(timeNow).format("H:mm");
      // const newDDate = moment(timeNow).format("YYYY-MM-DD");
      const newTime = moment(timeNow).format("h:mm a");
      const newDDate = moment(timeNow).format("DD/MM/YYYY");

      setTimeout(() => {
        setJobData({
          ...jobData,
          deliveryDateTempTime: newTime,
          deliveryDateTemp: newDDate,
        });
      }, 100);
      // setJobData({});
    }
  }, []);
  useEffect(async () => {
    const jobCData = jobCardDetails?.jobCard;
    if (jobCData?.id) {
      const dateD = jobCardDetails?.date?.split("-");
      const dateDFull = dateD[2] + "/" + dateD[1] + "/" + dateD[0];
      if (jobCData?.closingDateTemp) {
      }
      const todayTime1 = moment(new Date()).format("DD/MM/YYYY");
      const todayTime2 = moment(new Date()).format("h:mm a");

      setJobData({
        ...jobCData,
        paymentType: jobCData?.paymentType || jobCData?.disWarrantyService,
        selectedBrandId: jobCData?.brand?.id,
        brandName: jobCData?.brand?.name,
        selectedProductId: jobCData?.product?.id,
        productName: jobCData?.product?.name,
        deliveryDateTemp: dateDFull,
        deliveryDateTempTime: `${jobCardDetails?.time} am`,
        selectedUserId: jobCData?.assignedTo?.id,
        originalJobCardReceived: jobCardDetails?.originalJobCardReceived,
        receiverPersonSame: jobCardDetails?.receiverPersonSame,
        priority: jobCardDetails?.priority,
        closingDateTemp: jobCData?.closingDateTemp ?? todayTime1,
        closingDateTempTime: jobCData?.closingDateTempTime ?? todayTime2,
        // customerNotes: jobCardDetails?.customerNotes?.[0]?.note,
      });
      setItemsRecievedList(jobCardDetails?.itemRecivedOptions);
      setDefaultIssues(jobCardDetails?.defaultIssuesOptions);
      setCARequirements(jobCardDetails?.additionalRequirementsOptions);

      setActionTakenList(jobCardDetails?.actionTakenOptions);
      setEngineerObservationList(jobCardDetails?.engineerObservationOptions);
      const newObj = {
        id: jobCData?.selectedCustomerId,
        mobileNo: jobCData?.mobileNo,
        customerName: jobCData?.customerName,
      };
      setUserSelectedInfo(newObj);
      // console.log("jobCardDetails?.mobileNo", jobCData?.mobileNo);
      // console.log("newObjnewObj", newObj);
      getDropDownValues(jobCData?.mobileNo);
      if (jobCData?.imageUrls) {
        setImagesList(jobCData?.imageUrls?.split(","));
      }

      const newList = [];
      jobCardDetails?.brands?.map((item) => {
        newList.push({
          ...item,
          value: item?.brandName,
          label: item?.brandName,
        });
      });
      setBrandsList(newList);

      var newList12 = requiredFields1;

      if (jobCardDetails?.originalJobCardReceived === "No") {
        newList12.originalNotJobCardReason = false;
      } else {
        delete newList12.originalNotJobCardReason;
      }
      if (jobCardDetails?.receiverPersonSame === "No") {
        newList12.recevierName = false;
        newList12.recevierMobileNo = false;
      } else {
        delete newList12.recevierName;
        delete newList12.recevierMobileNo;
      }
      // setRequiredFields(newList12);
      setRequiredFields1(newList12);
    }
  }, [jobCardDetails]);
  useEffect(() => {
    // const newList = [];
    // displayJobCardDetails?.brands?.map((item) => {
    //   newList.push({ ...item, value: item?.name, label: item?.name });
    // });
    // setBrandsList(newList);
    const newListProducts = [];

    displayJobCardDetails?.products?.map((item) => {
      newListProducts.push({ ...item, value: item?.id, label: item?.name });
    });
    setProductsList(newListProducts);
    const usersList1 = [];
    displayJobCardDetails?.users?.map((user) => {
      const name =
        user?.firstName.toLowerCase() + " " + user?.lastName.toLowerCase();
      usersList1.push({ id: user?.id, value: user?.id, label: name });
    });
    setUsersList(usersList1);
    // setItemsRecievedList(displayJobCardDetails?.itemRecivedOptions);
    // setDefaultIssues(displayJobCardDetails?.defaultIssuesOptions);
    // setCARequirements(displayJobCardDetails?.additionalRequirementsOptions);
  }, [displayJobCardDetails]);

  useEffect(() => {
    const newList = [];
    var selecbID = null;
    var selecbName = null;
    brandsByProductIdResp?.brands?.map((item) => {
      newList.push({ ...item, value: item?.name, label: item?.name });
      if (item?.selected === "selected") {
        selecbID = item?.id;
        selecbName = item?.name;
      }
    });
    setBrandsList(newList);
    // setItemsRecievedList(itemsRecievedByProductIdResp);

    const prevValues = { ...jobData };
    if (selecbID) {
      prevValues["selectedBrandId"] = selecbID;
      prevValues["brandName"] = selecbName;
    }
    setJobData(prevValues);
  }, [brandsByProductIdResp]);

  useEffect(() => {
    setActionTakenList(
      getJobCardOptionsBasedOnProductIdResp?.actionTakenOptions
    );
    setEngineerObservationList(
      getJobCardOptionsBasedOnProductIdResp?.engineerObservationOptions
    );
  }, [getJobCardOptionsBasedOnProductIdResp]);
  useEffect(() => {
    setItemsRecievedList(itemsRecievedByProductIdResp);
  }, [itemsRecievedByProductIdResp]);
  useEffect(() => {
    setDefaultIssues(defaultIssuesByProductIdResp);
  }, [defaultIssuesByProductIdResp]);

  useEffect(() => {
    setCARequirements(customerAdditionalReqResp);
  }, [customerAdditionalReqResp]);
  useEffect(() => {}, [jobData?.status]);
  const isDisabledBtn = () => {
    // console.log(
    //   "jobCardDetails?.jobCard?.status",
    //   jobCardDetails?.jobCard?.status
    // );
    let returnF = false;
    if (jobId) {
      if (
        jobCardDetails?.jobCard?.status === "Closed" ||
        jobCardDetails?.jobCard?.status === "Closing Approval"
      ) {
        returnF = true;
      }
      if (user?.roles[0] === "ROLE_STORE_OWNER") {
        returnF = false;
      }
    }
    return returnF;
  };
  // console.log("jobCardDetails", jobCardDetails);
  // // useEffect(() => {
  // console.log("itemsRecievedByProductIdResp", itemsRecievedByProductIdResp);
  // console.log("ItemsRecievedList", itemsRecievedList);
  const searchOnChangeValue = (e) => {
    const { name, value } = e.target;
    if (name === "items-recieved") {
      setShowItemReceived(true);
      if (value === "") {
        setItemsRecievedList(itemsRecievedByProductIdResp);
      } else {
        const newItems = [];
        itemsRecievedByProductIdResp?.map((item) => {
          if (
            item?.optionName &&
            item?.optionName?.toLowerCase().includes(value?.toLowerCase())
          ) {
            newItems.push(item);
          }
        });
        setItemsRecievedList(newItems);
      }
    } else if (name === "default-issues") {
      setShowDefaultIssues(true);
      if (value === "") {
        setDefaultIssues(defaultIssuesByProductIdResp);
      } else {
        const newItems = [];
        defaultIssuesByProductIdResp?.map((item) => {
          if (
            item?.optionName &&
            item?.optionName?.toLowerCase().includes(value?.toLowerCase())
          ) {
            newItems.push(item);
          }
        });
        setDefaultIssues(newItems);
      }
    } else if (name === "customer-additional") {
      setShowCustomerAR(true);
      if (value === "") {
        setCARequirements(displayJobCardDetails?.additionalRequirementsOptions);
      } else {
        const newItems = [];
        displayJobCardDetails?.additionalRequirementsOptions?.map((item) => {
          if (
            item?.optionName &&
            item?.optionName?.toLowerCase().includes(value?.toLowerCase())
          ) {
            newItems.push(item);
          }
        });
        setCARequirements(newItems);
      }
    }
  };
  // }, [searchValue]);

  const onChangeValue = async (e) => {
    const { name, value } = e.target;
    // console.log("namename", name);
    // console.log("valuevalue", value);
    // console.log("namename", name);
    const prevValues = { ...jobData };
    if (
      name === "minCharges" ||
      name === "printPageCount" ||
      name === "printBlack" ||
      name === "printColor"
    ) {
      var value1 = value;
      if (value.trim() == "") {
        value1 = value.trim();
      }
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        prevValues[name] = value?.length === 1 ? value.toUpperCase() : value;
        setJobData(prevValues);
      }
    } else {
      prevValues[name] = value?.length === 1 ? value.toUpperCase() : value;
      setJobData(prevValues);
    }

    const newList = requiredFields;
    newList[name] = false;
    await setRequiredFields(newList);

    const newList1 = editRequiredFields;
    newList1[name] = false;
    setEditRequiredFields(newList1);

    const newList22 = editRequiredFields2;
    newList22[name] = false;
    setEditRequiredFields2(newList22);

    if (name === "customerName") {
      getDropDownValues(value);
      setShowDropDown(true);
    } else if (name === "mobileNo") {
      getDropDownValues(value);
      setShowDropDown1(true);
    }
    if (name === "engineerObservationOption") {
      setOpenEngineerObservationsBox(value ? true : false);
    } else if (name === "customerNotes") {
      setOpenCustomerNotesBox(value ? true : false);
    }
    updateRecevierPersonList(name, value);
  };
  const updateRecevierPersonList = async (name, value) => {
    if (name === "receiverPersonSame") {
      if (value === "Yes") {
        var newList12 = requiredFields1;
        delete newList12.recevierName;
        delete newList12.recevierMobileNo;
        // await setRequiredFields(newList12);
        await setRequiredFields1(newList12);
      } else if (value === "No") {
        const fiallIst = {
          ...requiredFields1,
          recevierName: false,
          recevierMobileNo: false,
        };
        // await setRequiredFields(fiallIst);
        await setRequiredFields1(fiallIst);
      }
    }
    if (name === "originalJobCardReceived") {
      if (value === "Yes") {
        var newList12 = requiredFields1;
        delete newList12.originalNotJobCardReason;
        // await setRequiredFields(newList12);
        await setRequiredFields1(newList12);
      } else if (value === "No") {
        // await setRequiredFields({
        //   ...requiredFields1,
        //   originalNotJobCardReason: false,
        // });
        await setRequiredFields1({
          ...requiredFields1,
          originalNotJobCardReason: false,
        });
      }
    }
  };
  const onChangeValueTime = (e) => {
    const newTime = moment(e?._d).format("H:mm");
    setJobData({ ...jobData, deliveryDateTempTime: newTime });
  };
  const handleDateTime = (date) => {
    // console.log("date", date);
    setSelectedDateTime(new Date(date).toLocaleString());
    const prevValues = { ...jobData };
    prevValues["deliveryDateTemp"] = moment(new Date(date)).format(
      "DD/MM/YYYY"
    );
    prevValues["deliveryDateTempTime"] = moment(new Date(date)).format(
      "h:mm a"
    );

    setJobData(prevValues);

    // deliveryDateTemp
    // :
    // "2023-10-08"
    // deliveryDateTempTime
    // :
    // "14:08"
  };
  const handleClosingDateTime = (date) => {
    // console.log("date", date);
    const prevValues = { ...jobData };
    prevValues["closingDateTemp"] = moment(new Date(date)).format("DD/MM/YYYY");
    prevValues["closingDateTempTime"] = moment(new Date(date)).format("h:mm a");
    console.log("prevValues", prevValues);
    setJobData(prevValues);

    // deliveryDateTemp
    // :
    // "2023-10-08"
    // deliveryDateTempTime
    // :
    // "14:08"
  };

  // console.log("SelectedDateTime(", selectedDateTime);
  const disablePastDt = (current) => {
    let days_date = 1;
    // if (user?.roles[0] === "ROLE_STORE_OWNER") {
    days_date = 365;
    // }
    const yesterday = moment().subtract(days_date, "day");
    return current.isAfter(yesterday);
  };

  const onChangeValueSelect = (name1, e) => {
    const PId = e?.id;
    // const { name, value } = e.target;
    const pValue = e.label;
    const prevValues = { ...jobData };
    prevValues[name1] = e.label;
    const newList = requiredFields;
    newList[name1] = false;
    setRequiredFields(newList);
    if (name1 === "productName") {
      prevValues["selectedProductId"] = PId;
      prevValues["itemRecived"] = "";
      prevValues["defaultIssues"] = "";
      prevValues["customerAddRequirements"] = "";

      if (e.value === "") {
        prevValues["brandName"] = "";
      } else {
        const jsonBody = { PId, jobId: jobId ? jobId : 0 };
        dispatch(getDisplayJobCardFormApi(PId));
        dispatch(getBrandsByProductIdApi(jsonBody));
        dispatch(getItemsRecievedByProductIdAPi(jsonBody));
        dispatch(getDefaultIssuesByProductIdAPi(jsonBody));
        dispatch(getCustomerAdditionalReqAPi(jsonBody));
        dispatch(getJobCardOptionsBasedOnProductId(jsonBody));
      }
      if (
        pValue === "Desktop" ||
        pValue === "Laptop" ||
        pValue === "Mobile" ||
        pValue === "TAB" ||
        pValue === "DVR"
      ) {
        // setRequiredFields({ ...requiredFields, systemPassword: false });
        setRequiredFields1({ ...requiredFields1, systemPassword: false });
      } else if (pValue === "Printer" && jobId) {
        setRequiredFields1({ ...requiredFields1, printPageCount: false });
      } else {
        // var newList1 = requiredFields;
        // delete newList1.systemPassword;
        // setRequiredFields(newList1);
        var newList12 = requiredFields1;
        delete newList12.systemPassword;
        delete newList12.printPageCount;
        setRequiredFields1(newList12);
      }
      setShowItemReceived(true);
      setShowDefaultIssues(true);
      setShowCustomerAR(true);
    } else {
      const braId = e?.brandId || e?.id;
      prevValues["selectedBrandId"] = braId;
    }

    setJobData(prevValues);
  };
  const onChangeValueSelectAssigned = (name1, e) => {
    // const { name, value } = e.target;
    const prevValues = { ...jobData };
    prevValues[name1] = e.id;
    const newList = requiredFields;
    newList[name1] = false;
    setRequiredFields(newList);

    setJobData(prevValues);
  };
  const dateFormatType = (date) => {
    var dtToday = new Date();
    if (date != undefined && date != "") {
      dtToday = new Date(date);
    }
    var month = "";
    var day = "";
    month = dtToday.getMonth() + 1;
    day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    if (user?.roles[0] === "ROLE_STORE_OWNER") {
      year = year - 1;
    }

    var DateNow = year + "-" + month + "-" + day;
    return DateNow;
  };
  const lowerName = jobData?.productName?.toLowerCase();
  const showExtraFields =
    lowerName === "desktop" ||
    lowerName === "laptop" ||
    lowerName === "mobile" ||
    lowerName === "tab" ||
    lowerName === "dvr"
      ? true
      : false;
  const showExtraSerialFields =
    lowerName === "laptop" ||
    lowerName === "mobile" ||
    lowerName === "tab" ||
    lowerName === "dvr"
      ? true
      : false;
  // (jobData?.status === "" ||
  // jobData?.status === "Repair Closed" ||
  // jobData?.status === "Closed" ||
  // jobData?.status === "Ready For Delivery")

  const showPrinterFields = jobData?.productName === "Printer" ? true : false;
  const noItems = [1, 2, 3, 4, 5, 66, 7, 7, 8, 8];
  // $("#items-recieved-search-box").on("keyup", function () {
  //   var query = this.value?.toLowerCase();
  //   $("#loadItemsRecived_dev > div").each(function (i, elem) {
  //     var currentV = $(this).find("input")?.val()?.toLowerCase();
  //     if (currentV.indexOf(query) != -1) {
  //       $(this).removeClass("dis-none-imp");
  //     } else {
  //       $(this).addClass("dis-none-imp");
  //     }
  //   });
  // });
  const addNewRowForAction = (item, index, value1) => {
    const prevValues = actionTakenItems;
    prevValues.push({ ...defaultAction, id: index });
    setActionTakenItems(prevValues);
    setUpdateData(!updateData);
    // dispatch(setActionTakenItems(prevValues));
  };
  const onChangeValueActionTaken = (e, item, index) => {
    const { name, value } = e.target;

    const prevValues = actionTakenItems;
    prevValues[index].serviceTaken = value;
    setActionTakenItems(prevValues);
    setUpdateData(!updateData);
  };
  const showPartsReplacedSec = () => {
    let returnF = false;
    actionTakenItems?.map((itemV) => {
      // console.log("itemV", itemV);
      if (
        itemV?.serviceTaken &&
        itemV?.serviceTaken?.toLowerCase().includes("replaced")
      ) {
        returnF = true;
      }
    });
    if (jobCardDetails?.partReplaceds?.length > 0) {
      returnF = true;
    }
    return returnF;
  };
  const deleteItemForAction = (item, index) => {
    const prevValues = actionTakenItems;

    prevValues.splice(index, 1);
    setActionTakenItems(prevValues);
    setUpdateData(!updateData);
  };
  const addNewRowForAction1 = (item, index) => {
    const prevValues = partsReplacedItems;
    prevValues.push({ ...defaultAction, id: index });
    setPartsReplacedItems(prevValues);
    setUpdateData(!updateData);
    // dispatch(setActionTakenItems(prevValues));
  };
  const deleteItemForAction1 = (item, index) => {
    const prevValues = partsReplacedItems;

    prevValues.splice(index, 1);
    setPartsReplacedItems(prevValues);
    setUpdateData(!updateData);
  };
  const handleValidate = () => {
    var firstEleId = "";
    var refurnFlag = false;
    const newList = requiredFields1;
    for (var key in newList) {
      const Pvalue = jobData[key];

      if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
        newList[key] = true;
        refurnFlag = true;
        if (firstEleId === "") {
          firstEleId = key;
        }
      }
    }
    setUpdateData(!updateData);
    setRequiredFields(newList);
    if (
      // jobData?.status === "Ready For Delivery" ||
      jobData?.status === "Open Warranty"
    ) {
      const editnewList = {
        toCourierName: false,
        tempToCourierDate: false,
        toDocNumber: false,
        toCompanyName: false,
      };
      for (var key1 in editnewList) {
        const Pvalue = jobData[key1];
        if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
          editnewList[key1] = true;
          refurnFlag = true;
          if (firstEleId === "") {
            firstEleId = key1;
          }
        }
      }
      setEditRequiredFields(editnewList);
    }

    if (
      jobData?.toCourierName &&
      (jobData?.status === "Repair Closed" ||
        jobData?.status === "Ready For Delivery")
    ) {
      const editRequiredFields3_temp = {
        receiveingCourierName: false,
        tempReceiveingToCourierDate: false,
        receiveingDocNumber: false,
      };
      for (var key1 in editRequiredFields3_temp) {
        const Pvalue = jobData[key1];
        if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
          editRequiredFields3_temp[key1] = true;
          refurnFlag = true;
          if (firstEleId === "") {
            firstEleId = key1;
          }
        }
      }
      setEditRequiredFields2(editRequiredFields3_temp);
    }
    // console.log("firstEleId", firstEleId);
    // console.log("refurnFlag", refurnFlag);
    if (refurnFlag && firstEleId) {
      const element = document.getElementById(firstEleId);
      if (element) {
        window.scrollTo({ top: element?.offsetTop - 100, behavior: "smooth" });
        // element.scrollIntoView({ behavior: "smooth" });
      }
    }

    return refurnFlag;
  };
  const handleSubmitBtn = async () => {
    if (!handleValidate() && !isLoading) {
      // jobData?.customerAddRequirements.map()
      const finalJson = {
        ...jobData,
        // disWarrantyService: "No",
        // selectedUserId: 1,
        // minCharges: 200,
        // warrantyService: 0,
        // serialNo1: "uuuuu",
        // serialNo2: "",
        // serialNo3: "",
        // systemPassword: "sys",
      };
      if (jobId) {
        triggerMixpanelEvent("edit_save_job_card_click");
        finalJson.partReplaceds = partsReplacedItems;
        const finalV = [];
        actionTakenItems?.map((itmm) => {
          if (itmm?.serviceTaken) finalV.push(itmm?.serviceTaken);
        });
        finalJson.actionTaken = finalV;
        // console.log("jobDatajobData", jobData);
        if (jobData?.status === "Closed" && finalV?.length === 0) {
          setErrorText("Please selct at least one Action Taken.");
          setErrorModel(true);
          return false;
        }
        // console.log("actionTakenItems", actionTakenItems);
      } else {
        triggerMixpanelEvent("create_job_card_click");
      }

      finalJson.imageUrls = imagesList.join(",");
      if (userSelectedInfo?.id) {
        finalJson.selectedCustomerId = userSelectedInfo?.id;
      }
      setIsLoading(true);

      const resp = await dispatch(createJobCardApi(finalJson));
      setIsLoading(false);
      // const resp1 = await dispatch(createJobCardApi(finalJson));
      // const resp2 = await dispatch(createJobCardApi(finalJson));
      // const resp3 = await dispatch(createJobCardApi(finalJson));
      // const resp4 = await dispatch(createJobCardApi(finalJson));
      // const resp5 = await dispatch(createJobCardApi(finalJson));
      // const resp6 = await dispatch(createJobCardApi(finalJson));
      // const resp7 = await dispatch(createJobCardApi(finalJson));
      // const resp8 = await dispatch(createJobCardApi(finalJson));
      // const resp9 = await dispatch(createJobCardApi(finalJson));
      // const resp24 = await dispatch(createJobCardApi(finalJson));
      // const resp34 = await dispatch(createJobCardApi(finalJson));
      // const resp45 = await dispatch(createJobCardApi(finalJson));
      // const resp556 = await dispatch(createJobCardApi(finalJson));
      // const resp63 = await dispatch(createJobCardApi(finalJson));
      // const resp27 = await dispatch(createJobCardApi(finalJson));
      // const resp856 = await dispatch(createJobCardApi(finalJson));
      if (resp?.payload?.id) {
        if (jobId) {
          triggerMixpanelEvent("edit_job_card_success");
        } else {
          triggerMixpanelEvent("create_job_card_success");
        }
        setShowSuccessPopup(true);
        window.scrollTo(0, 0);
        setJobData({});
      } else if (resp?.error?.message) {
        if (jobId) {
          triggerMixpanelEvent("edit_job_card_error", {
            message: resp?.error?.message,
          });
        } else {
          triggerMixpanelEvent("create_job_card_error", {
            message: resp?.error?.message,
          });
        }
        setErrorText(resp?.error?.message);
        setErrorModel(true);
      }
    }
  };
  const onChnageValueList = (Jtype, item, e, newList = "") => {
    const newList1 = requiredFields;
    newList1[Jtype] = false;
    setRequiredFields(newList1);

    const isChecked = e.target.checked;
    const prevValues = { ...jobData };
    if (isChecked) {
      var newList2 = "";
      if (newList === "") {
        newList2 = item?.optionName;
      } else {
        newList2 = newList + "," + item?.optionName;
      }
      newList = newList2;
      // newList.push(item);
      if (
        item?.optionName === "No Items Received" ||
        item?.optionName === "No Additional Required" ||
        item?.optionName === "NO ADDITIONAL REQUIRED"
      ) {
        newList = item?.optionName;
      }
    } else {
      const newList1 = newList.split(",");
      const idx = newList1.findIndex((u) => u === item?.optionName);
      if (idx !== -1) {
        newList1.splice(idx, 1);
        newList = newList1.join(",");
      }
    }

    if (Jtype === "itemRecived") {
      prevValues.itemRecived = newList;
    } else if (Jtype === "defaultIssues") {
      prevValues.defaultIssues = newList;
    } else if (Jtype === "customerAddRequirements") {
      prevValues.customerAddRequirements = newList;
    }
    // var newList = prevValues?.itemRecived || [];

    // "itemRecived": "iropt2",
    // "defaultIssues": "di1",
    // "customerAddRequirements": " aropt2",

    // "itemRecived": "iropt2",
    // "defaultIssues": "di1",
    // "customerAddRequirements": " aropt2",

    // prevValues["itemRecived"] = value;
    setJobData(prevValues);
  };
  const checkItemByList = (list, item) => {
    if (list) {
      const newList1 = list.split(",");
      const idx = newList1?.findIndex((u) => u === item?.optionName);
      if (idx === -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const isNoitemSelectedCheck = (list) => {
    if (list) {
      const newList1 = list.split(",");
      const idx = newList1?.findIndex(
        (u) =>
          u === "No Items Received" ||
          u === "No Additional Required" ||
          u === "NO ADDITIONAL REQUIRED"
      );
      if (idx === -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const handlePartChange = (value, key, index) => {
    const newValues = partsReplacedItems;
    newValues[index][key] = value;
    setPartsReplacedItems(newValues);
  };
  const handleAddNewUSer = () => {
    setSelectedUserEditData({});

    triggerMixpanelEvent("add_new_customer_click");
    setAddNewUserPopup(true);
  };
  const handleEditUSer = () => {
    if (userSelectedInfo?.id) {
      const found = allCustomersList.find(
        (element) => element?.id === userSelectedInfo?.id
      );
      // console.log("found", found);
      setSelectedUserEditData(found);

      triggerMixpanelEvent("edit_customer_click");
      setAddNewUserPopup(true);
    }
  };
  const addUserSuccess = (select) => {
    triggerMixpanelEvent("add_new_customer_success", select);
    handleCloseAddNewUSer();
    dispatch(getAllCustomersList());
    setShowSuccessPopupUser(true);
    setUserSelectedInfo(select);
    const prevValues = { ...jobData };
    prevValues["customerName"] = select?.customerName;
    prevValues["mobileNo"] = select?.mobileNo;
    setJobData(prevValues);
    const newList = requiredFields;
    newList["customerName"] = false;
    newList["mobileNo"] = false;
    setRequiredFields(newList);
  };
  const handleCloseAddNewUSer = () => {
    setAddNewUserPopup(false);
  };

  const handleClickBack = () => {
    history.push("/jobcardsummary");
  };
  const getDropDownValues = (val) => {
    if (val) {
      dispatch(getCustomerSearch(val));
    }
  };
  // const onChangeValuesManufacturer = (e) => {
  //   setBrandValue(e.target.value);
  //   // setTimeout(() => {
  //   const regJson = {
  //     Page: 1,
  //     PageSize: 25,
  //     Brand: e?.target?.value,
  //   };
  //   dispatch(getBrandsListApi(regJson));
  //   setFieldValue("brand", "");
  //   setFieldValue("manufacture", "");
  //   setFieldValue("manufacturerCode", "");
  //   setFieldValue("brandCode", "");
  //   if (e?.target?.value === "") {
  //     setShowDropDown(false);
  //     setFieldValue("manufacturer", "");
  //     setFieldValue("manufacturerCode", "");
  //   } else {
  //     setShowDropDown(true);
  //   }
  //   // }, 300);
  // };
  const handleManuClick = () => {
    setShowDropDown(true);
  };
  const handleItemClick = async (item) => {
    setShowDropDown(false);
    setShowDropDown1(false);
    setUserSelectedInfo(item);
    const prevValues = { ...jobData };
    prevValues["customerName"] = item?.customerName;
    prevValues["mobileNo"] = item?.mobileNo;
    setJobData(prevValues);
    const newList = requiredFields;
    newList["customerName"] = false;
    newList["mobileNo"] = false;
    setRequiredFields(newList);

    if (item?.value) {
      // await setBrandValue(item?.label);
      // const regJson = {
      //   brandCode: item?.value,
      // };
      // setFieldValue("brand", item?.label);
      // setFieldValue("brandCode", item?.value);
      // setFieldValue("manufacturer", item?.brandOwner);
      // setFieldValue("manufacturerCode", item?.brandOwnerCode);
      // setFieldValue("brandOemflag", item?.brandOemflag);
      // dispatch(getManfacturerApi(regJson));
    }
  };

  const handleKeypress = (e) => {
    if (e?.keyCode === 13) {
      if (searchUsersList?.length === 0) {
        setShowDropDown1(false);
        setSelectedUserEditData({});
        setTempUserPhoneNumber(e?.target?.value);
        handleAddNewUSer();
      } else {
        handleItemClick(searchUsersList[0]);
      }
    }
  };
  const handleImageClick = () => {
    // setTempImageData(obj);
    // setUploadImageIndex(indexww);
    setShowImageMoodel(true);
  };
  const handleImageView = (imgg) => {
    setShowImageViewMoodel(true);
    setSelectedImageUrl(imgg);
  };
  const handleCloseImage = () => {
    setShowImageMoodel(false);
  };
  const handleImageUploadSuccess = (imgName) => {
    console.log("imgName", imgName);
    handleCloseImage();
    const pplistItems = [...imagesList];
    const fimgName1 = `https://rasko-images.s3.ap-south-1.amazonaws.com/${imgName}`;
    pplistItems.push(fimgName1);
    setImagesList(pplistItems);
  };
  const handleDeleteImage = (index1) => {
    const pplistItems = [...imagesList];
    pplistItems.splice(index1, 1);
    setImagesList(pplistItems);
  };

  const getDiffDays = (fromDate) => {
    const toDate = moment().format("YYYY-MM-DD");
    var a = moment(fromDate, "YYYY-MM-DD");
    var b = moment(toDate, "YYYY-MM-DD");
    const returnVal = b.diff(a, "days"); // 1
    return returnVal;
  };
  const addAnotherItem = () => {
    const prevItems = itemsList;
    prevItems.push({ name: "" });
    setItemsList(prevItems);
    setUpdateData(!updateData);
  };
  const userSName = usersList?.find(
    (item1) => item1?.id === jobData?.selectedUserId
  )?.label;
  // let currencyName = this.props?.currenciesList.find(
  //   (i: any) => collectionData?.currency == i?.id
  // )?.currencyCode;
  console.log("jobDatajobData", jobData);
  console.log("requiredFields", requiredFields);
  console.log("requiredFields1222", requiredFields1);
  // console.log("brandsList", brandsList);
  // const statusList1 = [{ id: 0, value: "", label: "All" }];
  const statusList3 = [{ id: 5, value: "Closed", label: "Closed" }];
  let statusList4 = [
    { id: 5, value: "Closing Approval", label: "Closing Approval" },
    { id: 25, value: "Re Open", label: "Re Open" },
  ];
  if (jobData?.status === "Closed") {
    statusList4.push({ id: 5, value: "Closed", label: "Closed" });
  }
  const statusList9 = [
    { id: 5, value: "Closing Approval", label: "Closing Approval" },
    { id: 5, value: "Closed", label: "Closed" },
  ];

  // console.log()
  // age;
  const statusList2 =
    jobData?.age > 10
      ? [...statusList, ...statusList4]
      : [...statusList, ...statusList3];
  console.log("jobData?.deliveryDateTemp", jobData?.deliveryDateTemp);
  console.log("jobData?.deliveryDateTemp", jobData?.deliveryDateTempTime);
  console.log("jobData?.closingDateTemp", jobData?.closingDateTemp);
  console.log("itemsList", itemsList);

  return (
    <CreateOrderStyled>
      <div>
        <ImageUploadModel
          show={showImageMoodel}
          handleClose={handleCloseImage}
          handleImageUploadSuccess={handleImageUploadSuccess}
        />
        <ImageViewModel
          onHide={() => {
            setShowImageViewMoodel(false);
          }}
          show={showImageViewMoodel}
          url={selectedImageUrl}
        />
        <CustomModal
          show={showSuccessPopup}
          titleText={
            jobId
              ? "Job Card Successfully Updated."
              : "Job Card Successfully Created."
          }
          deleteText="Ok"
          cancelText=""
          onDelete={() => {
            setShowSuccessPopup(false);
            history.push("/jobcardsummary");
          }}
        />
        <CustomModal
          show={showSuccessPopupUser}
          titleText={"Successfully Added Customer."}
          deleteText="Ok"
          cancelText=""
          onDelete={() => {
            setShowSuccessPopupUser(false);
            // history.push("/jobcardsummary");
          }}
        />
        <CustomModal
          show={errorModel}
          titleText={errorText}
          deleteText="Ok"
          cancelText=""
          dynamicClassName={`error_message`}
          onDelete={() => {
            setErrorModel(false);
            // history.push("/jobcardsummary");
          }}
        />

        <AddNewUser
          addUserSuccess={addUserSuccess}
          show={addNewUserPopup}
          onHide={handleCloseAddNewUSer}
          selectedEditData={selectedUserEditData}
          tempUserPhoneNumber={tempUserPhoneNumber}
        />

        <div className="focusnext-boby-section">
          <div className="">
            {jobId && (
              <div className="add-back-arrow">
                <span onClick={handleClickBack}>{`< Back`}</span>
              </div>
            )}
            <div className="section-container-header">
              <h3> {jobId ? `Order ID: ${jobId}` : "Create Order"} </h3>
              <span>
                <a href="/allOrders">View All Orders</a>
              </span>
              <span onClick={handleAddNewUSer} className="add-new-user-sec">
                Add New Customer
              </span>
            </div>
          </div>
          <div className="focusnext-boby-content">
            <div className="col datatable1">
              <div className="fluid-container ProductDetails1">
                <div className="row">
                  <div className="col-sm-12 left-side-content-sec">
                    <div className="row align-items-center">
                      <div className="col-sm-6 ">
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Mobile No <span className="manditary">*</span>
                          </Form.Label>
                          <div className="extra-fields-content">
                            <Form.Control
                              maxLength={10}
                              isInvalid={requiredFields?.mobileNo}
                              value={jobData?.mobileNo}
                              type="text"
                              name="mobileNo"
                              onKeyDown={handleKeypress}
                              id="mobileNo"
                              autoComplete="off"
                              onChange={onChangeValue}
                              // onChange={this.handleChangeValue}
                            />
                            {userSelectedInfo?.id && (
                              <span
                                onClick={handleEditUSer}
                                className="add_edit_icons_sec"
                              >
                                <img src={icons_edit} />{" "}
                              </span>
                            )}
                            <span
                              onClick={handleAddNewUSer}
                              className="add_edit_icons_sec"
                            >
                              <img src={icons_plus} />{" "}
                            </span>
                          </div>

                          <Form.Control.Feedback type="invalid">
                            {requiredFields?.mobileNo ? <></> : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                        {showDropDown1 && jobData?.mobileNo && (
                          <div className="menu-items-list col-12">
                            <ul id="results" className="list-group">
                              {isLoading && (
                                <li className="list-group-item list-group-item-action">
                                  Loading...
                                </li>
                              )}
                              {searchUsersList?.length === 0 && (
                                <li
                                  onClick={handleItemClick}
                                  className="list-group-item list-group-item-action"
                                >
                                  No results found.
                                </li>
                              )}
                              {searchUsersList?.map((item) => {
                                return (
                                  <li
                                    onClick={() => {
                                      handleItemClick(item);
                                    }}
                                    className="list-group-item list-group-item-action"
                                  >
                                    {item?.customerName}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className="col-sm-6 " ref={dropdownRef}>
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Customer Name <span className="manditary">*</span>
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            isInvalid={requiredFields?.customerName}
                            value={jobData?.customerName}
                            type="text"
                            name="customerName"
                            id="customerName"
                            // disabled={true}
                            onChange={onChangeValue}
                          />
                          <Form.Control.Feedback type="invalid">
                            {requiredFields?.customerName ? <></> : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                        {showDropDown && jobData?.customerName && (
                          <div className="menu-items-list col-12">
                            <ul id="results" className="list-group">
                              {isLoading && (
                                <li className="list-group-item list-group-item-action">
                                  Loading...
                                </li>
                              )}
                              {searchUsersList?.length === 0 && (
                                <li
                                  onClick={handleItemClick}
                                  className="list-group-item list-group-item-action"
                                >
                                  No results found.
                                </li>
                              )}
                              {searchUsersList?.map((item) => {
                                return (
                                  <li
                                    onClick={() => {
                                      handleItemClick(item);
                                    }}
                                    className="list-group-item list-group-item-action"
                                  >
                                    {item?.customerName}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-6 ">
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Alternative Mobile No
                          </Form.Label>
                          <Form.Control
                            maxLength={10}
                            // isInvalid={errors?.title}
                            value={jobData?.alternativeNo}
                            type="text"
                            name="alternativeNo"
                            onChange={onChangeValue}
                            // onChange={this.handleChangeValue}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-sm-6 ">
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Email
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            className="user-email-sec"
                            // isInvalid={errors?.title}
                            value={jobData?.customerEmail}
                            type="text"
                            name="customerEmail"
                            onChange={onChangeValue}
                            // onChange={this.handleChangeValue}
                          />
                        </Form.Group>
                      </div> */}
                  <div className="col-sm-12 add-items-row-all">
                    <div className="header-all-items">
                      <div className="section-container-header2">
                        <h5>Add Your Items </h5>
                      </div>
                    </div>
                    <div className="all-items-section-full">
                      {itemsList?.map((itemData, itemIndex) => {
                        const curentNo = itemIndex + 1;
                        return (
                          <>
                            <div>
                              <div className="header-sub-item"> </div>
                              <div className="row items-section-row">
                                <div className="col-sm-3">
                                  <label htmlFor="" className="col-form-label">
                                    Outfit Type
                                    <span className="manditary">*</span>
                                  </label>
                                  <select
                                    name="outfitType"
                                    onChange={onChangeValue}
                                    required
                                  >
                                    <option value="">Select</option>

                                    {outFitTypesList.map((sName) => {
                                      return (
                                        <option
                                          value={sName}
                                          selected={
                                            sName === jobData?.priority
                                              ? true
                                              : false
                                          }
                                        >
                                          {sName}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div className="col-sm-3">
                                  <label htmlFor="" className="col-form-label">
                                    Type
                                    <span className="manditary">*</span>
                                  </label>
                                  <select
                                    name="outfitType"
                                    onChange={onChangeValue}
                                    required
                                  >
                                    <option value="">Select</option>

                                    {["Stitching", "Alteration"].map(
                                      (sName) => {
                                        return (
                                          <option
                                            value={sName}
                                            selected={
                                              sName === jobData?.priority
                                                ? true
                                                : false
                                            }
                                          >
                                            {sName}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                                <div className="col-sm-3">
                                  <label htmlFor="" className="col-form-label">
                                    Measurements
                                    <span className="manditary">*</span>
                                  </label>
                                  <div>Add</div>
                                </div>

                                <div className="col-sm-3">
                                  <label htmlFor="" className="col-form-label">
                                    Priority
                                    <span className="manditary">*</span>
                                  </label>
                                  <select
                                    name="priority"
                                    onChange={onChangeValue}
                                    required
                                  >
                                    <option value="">Select</option>

                                    {severityOptions.map((sName) => {
                                      return (
                                        <option
                                          value={sName}
                                          selected={
                                            sName === jobData?.priority
                                              ? true
                                              : false
                                          }
                                        >
                                          {sName}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div
                                  className="col-sm-3 "
                                  id="deliveryDateTemp"
                                >
                                  <Form.Group>
                                    <Form.Label>
                                      Special Instructions
                                      <span className="manditary">*</span>
                                      {/* {selectedDateTime} */}
                                    </Form.Label>
                                    <textarea
                                      placeholder="Please Enter"
                                      id="w3review"
                                      name="w3review"
                                      rows="2"
                                      // cols="20"
                                    ></textarea>
                                  </Form.Group>
                                </div>

                                <div
                                  className="col-sm-3 "
                                  id="deliveryDateTemp"
                                >
                                  <Form.Group>
                                    <Form.Label>
                                      Delivery Date & Time
                                      <span className="manditary">*</span>
                                      {/* {selectedDateTime} */}
                                    </Form.Label>
                                    <Datetime
                                      onChange={handleDateTime}
                                      value={`${jobData?.deliveryDateTemp} ${jobData?.deliveryDateTempTime}`}
                                      dateFormat={"DD/MM/YYYY"}
                                      isValidDate={disablePastDt}

                                      // dateFormat={moment(new Date()).format("DD/MM/YYYY")}
                                      // timeFormat={moment(new Date()).format("h:mm A")}
                                    />
                                  </Form.Group>
                                </div>
                                <div
                                  className="col-sm-3 "
                                  id="deliveryDateTemp"
                                >
                                  <Form.Group>
                                    <Form.Label>
                                      Trail Date & Time
                                      <span className="manditary">*</span>
                                      {/* {selectedDateTime} */}
                                    </Form.Label>
                                    <Datetime
                                      onChange={handleDateTime}
                                      value={`${jobData?.deliveryDateTemp} ${jobData?.deliveryDateTempTime}`}
                                      dateFormat={"DD/MM/YYYY"}
                                      isValidDate={disablePastDt}

                                      // dateFormat={moment(new Date()).format("DD/MM/YYYY")}
                                      // timeFormat={moment(new Date()).format("h:mm A")}
                                    />
                                  </Form.Group>
                                </div>
                                <div
                                  className="col-sm-3 "
                                  id="deliveryDateTemp"
                                >
                                  <Form.Group>
                                    <Form.Label>
                                      Item Images
                                      <span className="manditary">*</span>
                                      {/* {selectedDateTime} */}
                                    </Form.Label>
                                    <div>
                                      <img src={null} /> <img src={null} />{" "}
                                      <img src={null} />{" "}
                                    </div>
                                  </Form.Group>
                                </div>
                                <div className="col-sm-3 ">
                                  <Form.Group>
                                    <Form.Label className="requiredField">
                                      Stitching Charges
                                      <span className="manditary">*</span>
                                    </Form.Label>
                                    <Form.Control
                                      maxLength={20}
                                      isInvalid={requiredFields?.minCharges}
                                      value={jobData?.minCharges}
                                      type="text"
                                      id="minCharges"
                                      name="minCharges"
                                      onChange={onChangeValue}
                                      // onChange={this.handleChangeValue}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {requiredFields?.minCharges ? (
                                        <></>
                                      ) : null}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </div>
                                <div className="col-sm-3 ">
                                  <label htmlFor="" className="col-form-label">
                                    Assigned To
                                    <span className="manditary">*</span>
                                  </label>

                                  <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    defaultValue={
                                      userSName ? userSName : "Select"
                                    }
                                    isSearchable={true}
                                    name="selectedUserId"
                                    options={usersList}
                                    onChange={(e) => {
                                      onChangeValueSelectAssigned(
                                        "selectedUserId",
                                        e
                                      );
                                    }}
                                    required
                                    value={userSName}
                                    placeholder={
                                      userSName ? userSName : "Select"
                                    }
                                  />
                                </div>
                                <div className="col-sm-3 add-another-item-sec">
                                  {curentNo == itemsList?.length ? (
                                    <button onClick={addAnotherItem}>
                                      Add Another Item
                                    </button>
                                  ) : (
                                    <button onClick={addAnotherItem}>
                                      Remove this Item
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <br />
                  <br />
                  <br /> <br />
                  <br />
                  <br /> <br />
                  <br />
                  <br />
                  <div className="col-sm-12 left-side-content-sec">
                    <div className="row align-items-center">
                      <div className="col-sm-6 " id="deliveryDateTemp">
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Total Amount
                            {/* <span className="manditary">*</span> */}
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            isInvalid={requiredFields?.estimationAmount}
                            value={jobData?.estimationAmount}
                            type="text"
                            id="estimationAmount"
                            name="estimationAmount"
                            onChange={onChangeValue}
                            // onChange={this.handleChangeValue}
                          />
                          <Form.Control.Feedback type="invalid">
                            {requiredFields?.estimationAmount ? <></> : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-sm-6 ">
                        <Form.Group>
                          <Form.Label className="requiredField">
                            Advance Amount
                            {/* <span className="manditary">*</span> */}
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            isInvalid={requiredFields?.advanceAmount}
                            value={jobData?.advanceAmount}
                            type="text"
                            id="advanceAmount"
                            name="advanceAmount"
                            onChange={onChangeValue}
                            // onChange={this.handleChangeValue}
                          />
                          <Form.Control.Feedback type="invalid">
                            {requiredFields?.advanceAmount ? <></> : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                jobData?.productName
                  ? " create-job-action create-job-action1 mb-3"
                  : "add-border-top create-job-action  create-job-action1 mb-3"
              }
            >
              {jobId && (
                <div className="col-sm-12 " id="deliveryDateTemp">
                  <Form.Group>
                    <Form.Label>
                      Closing Date & Time
                      {/* <span className="manditary">*</span> */}
                      {/* {selectedDateTime} */}
                    </Form.Label>
                    <Datetime
                      onChange={handleClosingDateTime}
                      value={`${jobData?.closingDateTemp} ${jobData?.closingDateTempTime}`}
                      dateFormat={"DD/MM/YYYY"}
                      // isValidDate={disablePastDt}

                      // dateFormat={moment(new Date()).format("DD/MM/YYYY")}
                      // timeFormat={moment(new Date()).format("h:mm A")}
                    />
                  </Form.Group>
                </div>
              )}
            </div>
            <div className="submit-btn-sec-top create-job-action99">
              <div className="submit-button-action mt-15">
                <button
                  type="submit"
                  id="btn_submit"
                  disabled={isDisabledBtn()}
                  name="btn_submit"
                  onClick={handleSubmitBtn}
                  className="btn btn-primary job-card-submit-btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CreateOrderStyled>
  );
};
export default CreateJobcard;
