import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createBillingAPI,
  getAllBillings,
  getBillingDetailsAPI,
} from "./BillingsService";

export interface leadManagementState {
  loading: boolean;
  error: any;
  billingDetails: any;
  allBillings: any;
}

const initialState: leadManagementState = {
  loading: false,
  error: null,
  billingDetails: {},
  allBillings: {},
};

export const BillingManagement = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    // updateUserDetails: (
    //   state: leadManagementState,
    //   action: PayloadAction<boolean>
    // ) => {
    //   state.user = action.payload;
    // },
    updateErrorMessage: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
    updatesBillingDetails: (
      state: leadManagementState,
      action: PayloadAction<any>
    ) => {
      state.billingDetails = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(createBillingAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      createBillingAPI.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.billingDetails = action.payload?.data;
      }
    );
    builder.addCase(
      createBillingAPI.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.billingDetails = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    builder.addCase(getBillingDetailsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getBillingDetailsAPI.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.billingDetails = action.payload?.data;
      }
    );
    builder.addCase(
      getBillingDetailsAPI.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.billingDetails = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );

    //getAllBillings
    builder.addCase(getAllBillings.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllBillings.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allBillings = action.payload;
      }
    );
    builder.addCase(
      getAllBillings.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.allBillings = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
  },
});

export const { setLoading, updateErrorMessage, updatesBillingDetails } =
  BillingManagement.actions;

export default BillingManagement.reducer;
