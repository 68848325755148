import styled from "styled-components";

export const InstituteSetUpStyled = styled.div`
  .no-results {
    color: rgb(89, 85, 85);
    margin-top: 100px;
    text-align: center;
    border-top: 1px solid #e2e2e2;
    padding-top: 45px;
  }
  .create-new-institute-sec-content {
    width: 500px;
    margin: auto;
    label {
      font-size: 18px;
      margin-bottom: 2px;
    }
    input {
      height: 50px;
    }
  }
  .create-new-institute-header {
    margin-bottom: 50px;
  }
  .create-new-sec {
    text-align: center;
    margin-top: 30px;
  }
  .create-new-institute-header {
    span {
      color: #4682b4;
      cursor: pointer;
    }
  }
`;
