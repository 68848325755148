import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

export const createBillingAPI = createAsyncThunk(
  "reports/createBillingAPI",
  async (body: any) => await post(`${SERVER_IP}/billing/create`, body)
);
export const getBillingDetailsAPI = createAsyncThunk(
  "reports/getBillingDetailsAPI",
  async (iID: any) => await get(`${SERVER_IP}/billing/${iID}`)
);

export const updateBillingDetailsAPI = createAsyncThunk(
  "reports/updateBillingDetailsAPI",
  async (body: any) => await put(`${SERVER_IP}/billing/update`, body)
);

export const getAllBillings = createAsyncThunk(
  "reports/getAllBillings",
  async (body: any) =>
    await post(`${SERVER_IP}/billing/all?page=${body?.page || 10}`, body)
);
