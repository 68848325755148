import React, { useEffect, useState } from "react";
import { StudentsStyled } from "./Students.styled";
import Select from "react-select";

import { Modal, Button } from "react-bootstrap";
import { dropOffReasonList } from "./../../config";
const DropOffStudent = (props: any) => {
  const [dropOffReason, setDropOffReason] = useState({} as any);

  const handleCancel = () => {
    props?.handleEditClose();
  };
  const handleConform = () => {
    props?.handleEditClose();
  };
  const handleChangedropOffReason = (selectedOption: any) => {
    setDropOffReason(selectedOption);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body
        className={`modalBodyDefault alert-model-popup-sec dynamic_class_${props?.dynamicClassName}`}
      >
        <StudentsStyled>
          <div>
            <h2>Mark student as drop off</h2>
            <br />
            <div className="drops-off-marked-against ">
              <p>Payments of ₹9,999.00 marked against this student</p>
            </div>
            <div>
              <p>
                Any active payment plans will be cancelled and no more payments
                will be collected against this student.
              </p>
            </div>
            <div>
              <p>
                Please <strong>select the reason</strong> and confirm to
                complete the process.
              </p>
            </div>
            <div>
              <div className="delta-select-column">
                <label>Drop-Off Reason </label>
                <Select
                  value={dropOffReason}
                  placeholder="Select Class"
                  onChange={handleChangedropOffReason}
                  options={dropOffReasonList}
                  className="delta-select"
                  classNamePrefix="delta-select"
                />
              </div>
            </div>
            <br />
            <br />
            <div>
              <div className="actions-btn-sec align-center">
                <button
                  className="deltape-cancel-button"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button className="deltape-button" onClick={handleConform}>
                  Conform
                </button>
              </div>
            </div>
          </div>
        </StudentsStyled>
      </Modal.Body>
    </Modal>
  );
};

export default DropOffStudent;
