import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import logo1 from "../../img/delta/Logo.png";

import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";

import Button from "react-bootstrap/Button";
import { loginUser } from "../../redux/slices/auth/authService";
import { updateUserDetails } from "../../redux/slices/auth/authSlice";
// import "./styles.css";
import { useHistory } from "react-router-dom";
import eyeIcon from "../../images/eyeClose.svg";
import { IndexStyled } from "./Index.styled";
import openEyeIcon from "../../images/openEye.svg";

import "../loginStyles.css";

const UserLogin = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [errorMobileNumber, setErrorMobileNumber] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [erroressageText, setErroressageText] = useState("");
  const [showPass, setShowPass] = useState(false);

  const { error, loading, user } = useSelector(({ auth }) => auth);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (user?._id) {
      history.push("/myDashboard");
    }
  }, [user]);

  function handleSubmit(event) {
    event.preventDefault();
  }
  const gotoValidateLogin = () => {
    if (password === "") {
      setErrorPassword(true);
    }
    if (mobileNumber === "") {
      setErrorMobileNumber(true);
    }
    if (password !== "" && mobileNumber !== "") {
      signInCall();
      // gotoDelivery();
    }
  };
  const signInCall = async () => {
    console.log("vvvv");
    const body = {
      email: mobileNumber,
      password,
    };
    // dispatch(updateUserDetails(body1));
    // localStorage.setItem("user", JSON.stringify(body1));
    const resp = await dispatch(loginUser(body));
    console.log("resp", resp);
    if (resp?.payload?.error) {
      setErroressageText(resp?.payload?.error);
    }
  };
  const handleChangeValue = (e) => {
    let { name, value } = e.target;
    setErroressageText("");
    setErrorMobileNumber(false);
    setMobileNumber(value);
  };

  return (
    <>
      <div className="loginsignupContainer">
        <div className="data-loginsignupContainer">
          <div className="extension_signin_header">
            <div className="exten-sign-header-logo">
              <img src={logo1} />
            </div>
          </div>
          <IndexStyled>
            <div className="LoginContainer exten-login-container">
              <Form.Group size="lg" controlId="mobileNumber">
                <Form.Label>Email Address*</Form.Label>
                <Form.Control
                  autoFocus
                  name="mobileNumber"
                  type="text"
                  placeholder="Enter Email Address"
                  // maxLength={10}
                  value={mobileNumber}
                  className={errorMobileNumber ? "error" : ""}
                  onChange={(e) => {
                    handleChangeValue(e);
                  }}
                />
              </Form.Group>
              <Form.Group
                size="lg"
                controlId="password"
                className="password_field"
              >
                <Form.Label>Password</Form.Label>
                {showPass ? (
                  <img
                    src={eyeIcon}
                    alt="eye"
                    className="eyeIcon"
                    onClick={() => setShowPass(!showPass)}
                  />
                ) : (
                  <img
                    src={openEyeIcon}
                    alt="open-eye"
                    className="eyeIcon"
                    onClick={() => setShowPass(!showPass)}
                  />
                )}
                <Form.Control
                  type={showPass ? "text" : "password"}
                  placeholder="Enter Password"
                  value={password}
                  className={errorPassword ? "error" : ""}
                  onChange={(e) => {
                    setErroressageText("");
                    setPassword(e.target.value);
                    setErrorPassword(false);
                  }}
                />
              </Form.Group>

              {error && <p className="errorMessage">{error}</p>}
              {erroressageText && (
                <p className="errorMessage">{erroressageText}</p>
              )}

              <Button
                onClick={() => {
                  gotoValidateLogin();
                }}
                block
                size="lg"
                type="submit"
              >
                {loading ? "Loading" : "Login"}
              </Button>
            </div>
          </IndexStyled>
        </div>
      </div>
    </>
  );
};
export default UserLogin;
