import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get, del } from "../../../library/Requests/helpers";

export const getAllBrands = createAsyncThunk(
  "serviceManagement/getAllBrands",
  // async (body:any) => {
  //   return await post(`${SERVER_IP}/auth/login`, body);
  // }
  async () => await get(`${SERVER_IP}/brandlist`)
);
export const getDashboards = createAsyncThunk(
  "serviceManagement/getDashboards",
  async (flagV?: any) =>
    await get(`${SERVER_IP}/dashboard${flagV ? "?getMydata=true" : ""}`)
);
