import styled from "styled-components";

export const HomeStyled = styled.div`
  .focusnext-home-page-container {
    padding-top: 0px;
    background: #fff;
  }

  .focusnext-home-wrapper {
  }
  .main-top-sec {
    padding: 70px 0px;
    width: 80%;
    margin: auto;
    display: flex;
    gap: 100px;
    > div {
      width: 50%;
    }

    .main-top-left {
      display: table;
    }
    .main-top-left-content {
      display: table-cell;
      vertical-align: middle;
    }
    h2 {
      font-size: 46px;
      line-height: 55px;
      color: #0b2238;
      margin: 15px 0 25px;
      font-weight: 600;
      letter-spacing: -1px;
    }
  }
  .main-top-left-btn {
    margin-top: 30px;
    button {
      background-color: #4682b4;
      text-transform: uppercase;
      font-family: "Work Sans", sans-serif;
      color: #fff;
      font-size: 16px;
      border-radius: 2px;
      line-height: 50px;
      font-weight: 600;
      display: inline-block;
      letter-spacing: 0.5px;
      padding: 0 30px;
      box-shadow: 0 3px 10px rgba(71, 44, 173, 0.2);
      border: none;
    }
  }
  .main-top-left {
    img {
      max-height: 350px;
    }
  }
  .features-top-sec {
    padding: 70px 0px;
    display: flex;
    width: 80%;
    margin: auto;
    gap: 100px;
  }
  .features-top-left {
    width: 40%;
  }
  .features-top-right {
    width: 60%;
  }
  .content-sec-all {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .features-top-left {
    display: table;
    // text-align: center;
  }

  .features-top-left-content {
    display: table-cell;
    vertical-align: middle;
    h2 {
      font-size: 46px;
      line-height: 47px;
      color: #0b2238;
      margin: 10px 0 15px;
      font-weight: 600;
      letter-spacing: -1px;
    }
  }
  .content-sec-row h3 {
    margin: 0 0 10px;
    font-weight: 400;
    line-height: 1.7;
    margin-top: 20px;
    color: #0b2238;
    letter-spacing: -0.5px;
    font-size: 20px;
  }
  .our-features-text {
    text-transform: uppercase;
    font-size: 12px;
    color: #fa071c;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .bg-color-shadow1 {
    background-color: #f8f8f8;
    background-color: #d8e2ea;
  }
  .bg-color-shadow {
    background-color: #fff;
  }
  .sign-up-top-sec {
    padding: 70px 0px;
    text-align: center;
    h3 {
      font-size: 46px;
      line-height: 47px;
      color: #0b2238;
      margin: 10px 0 15px;
      font-weight: 600;
      -webkit-letter-spacing: -1px;
      -moz-letter-spacing: -1px;
      -ms-letter-spacing: -1px;
      letter-spacing: -1px;
    }
    button {
      margin-top: 20px;
      background-color: #4682b4;
      text-transform: uppercase;
      font-family: "Work Sans", sans-serif;
      color: #fff;
      font-size: 16px;
      border-radius: 25px;
      line-height: 50px;
      font-weight: 600;
      display: inline-block;
      letter-spacing: 0.5px;
      padding: 0 30px;
      box-shadow: 0 3px 10px rgba(71, 44, 173, 0.2);
      border: none;
    }
    p {
      margin-top: 12px;
      // font-size: 20px;
      a {
        color: #f9751b;
        text-decoration: none;
      }
    }
  }
  .users-top-sec {
    padding: 70px 0px;
    width: 80%;
    margin: auto;
    display: flex;
    gap: 100px;
    .users-top-left {
      width: 40%;
      text-align: center;
    }
    .main-top-left {
    }
    .users-top-right {
      width: 60%;
      display: table;
    }
    .users-top-left-content {
      display: table-cell;
      vertical-align: middle;
    }

    h2 {
      font-size: 46px;
      line-height: 55px;
      color: #0b2238;
      margin: 15px 0 25px;
      font-weight: 600;
      letter-spacing: -1px;
    }
    img {
      max-height: 300px;
    }
  }
  .reports-top-sec {
    padding: 70px 0px;
    width: 80%;
    margin: auto;
    display: flex;
    gap: 100px;
    .reports-top-left {
      width: 60%;
      display: table;
      // text-align: center;
    }
    .main-top-left {
      display: table;
      text-align: center;
    }
    .reports-top-right {
      width: 40%;
      text-align: center;
    }
    .reports-top-left-content {
      display: table-cell;
      vertical-align: middle;
    }

    h2 {
      font-size: 46px;
      line-height: 55px;
      color: #0b2238;
      margin: 15px 0 25px;
      font-weight: 600;
      letter-spacing: -1px;
    }
    img {
      max-height: 300px;
    }
  }
`;
