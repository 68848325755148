import React, { useEffect, useState } from "react";
import { InstituteSetUpStyled } from "./InstituteSetUp.styled";
import { Row, Col, Button, Form, Dropdown } from "react-bootstrap";
import Select from "react-select";
import {
  createInstituteAPI,
  getInstituteDetailsAPI,
  updateInstituteDetailsAPI,
} from "../..//redux/slices/InstituteManagement/InstituteManagementService";

import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./../../components/Common/Loader";
import CustomModal from "../../components/CustomModel";

// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "./../../Scenes/common";
const initValStore = {
  instituteName: undefined,
  establishedYear: undefined,
  email: undefined,
  mobileNumber: undefined,
  instituteLocation: undefined,
  instituteCode: undefined,
  panNo: undefined,
};
const initValError = {
  instituteName: false,
  establishedYear: false,
  email: false,
  mobileNumber: false,
  instituteLocation: false,
  instituteCode: false,
  panNo: false,
};
const errorDataValue = {
  instituteName: "Please Enter First name",
  establishedYear: "Please Enter Last name",
  email: "Please Enter Phone number",
  mobileNumber: "Please Enter Last name",
  instituteLocation: "Please Enter Last name",
  instituteCode: "Please Enter Last name",
  panNo: "Please Enter Last name",
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const CreateApplication = (props: any) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setmobileNumber] = useState("");
  const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [instituteerrorMessage, setInstituteErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptymobileNumber, setEmptymobileNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [locationValue, setLocationValue] = useState({} as any);
  const [boardValue, setBoardValue] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [errorData, setErrorData] = useState(initValError as any);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  // const query = useQuery();
  // const selectedTab = query.get("selectedTab");
  const { instituteId } = props?.match?.params;

  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((state: any) => state?.auth);
  const { instituteDetails } = useSelector(
    (state: any) => state?.instituteManagement
  );
  useEffect(() => {
    triggerMixpanelEvent("Contact_Us");
    // dispatch(getAllInstitutes());
    if (instituteId) {
      dispatch(getInstituteDetailsAPI(instituteId));
    }
  }, []);
  useEffect(() => {
    if (instituteDetails) {
      const prevDetail = {
        instituteName: instituteDetails?.instituteName,
        establishedYear: instituteDetails?.establishedYear,
        email: instituteDetails?.email,
        mobileNumber: instituteDetails?.mobileNumber,
        instituteLocation: instituteDetails?.instituteLocation,
        instituteCode: instituteDetails?.instituteCode,
        panNo: instituteDetails?.panNo,
      };
      setSignUpData({ ...prevDetail });
    }
  }, [instituteDetails]);

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };

  const handleChangeValue = (e: any) => {
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    setSignUpData({
      ...addr,
      [name]: value1,
    });
    setErrorData({
      ...addr_error,
      [name]: false,
    });
  };
  const handleSaveClick = async () => {
    var isValid = true;
    var errornew = { ...initValError } as any;
    for (var key in errornew) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    setErrorData({ ...errornew });
    if (isValid) {
      triggerMixpanelEvent("save_my_profile");
      //   , _id: user?._id

      setIsLoading(true);
      if (instituteId) {
        const body = { ...signUpData, _id: instituteId };
        const resp = (await dispatch(updateInstituteDetailsAPI(body))) as any;
        setIsLoading(false);
        if (resp?.payload?.success) {
          setShowSuccessMessage(true);
        }
      } else {
        const body = { ...signUpData };
        const resp = (await dispatch(createInstituteAPI(body))) as any;
        setIsLoading(false);
        if (resp?.payload?.success) {
          setShowSuccessMessage(true);
        }
      }
    }
  };
  const handleCancel = () => {
    history.push("/institutes");
  };
  const handleEdit = () => {
    setCurrentStep("1");
  };
  console.log("errorDataerrorData", errorData);
  console.log("errorDataerrorData", errorDataValue);

  return (
    <>
      <InstituteSetUpStyled>
        <div className="create-new-institute-sec">
          {isLoading && <Loader />}
          <div className="create-new-institute-sec-content">
            <div className="student-info-values">
              <div className="create-new-institute-header">
                <h3>Create New Institute</h3>
                <span onClick={handleCancel}>{"< All Institutes"} </span>
              </div>
              {/* <div className="delta-select-row">
                    <label>Select Class </label>
                    <Select
                      value={academicYear}
                      placeholder="Select Class"
                      onChange={handleChangeYear}
                      options={locationsList3}
                      className="delta-select"
                      classNamePrefix="delta-select"
                    />
                  </div> */}
              <div className="student-info-row">
                <Form.Group className="delta-signup-md" controlId="email">
                  <Form.Label>
                    {" "}
                    School / College / University Name{" "}
                    <span className="mand-sign-field">*</span>
                  </Form.Label>
                  <Form.Control
                    // autoFocus
                    isInvalid={errorData?.instituteName}
                    name="instituteName"
                    type="text"
                    value={signUpData?.instituteName}
                    onChange={(e) => handleChangeValue(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData?.instituteName ? (
                      <>{errorDataValue?.instituteName}</>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="student-info-row">
                <Form.Group className="delta-signup-md" controlId="email">
                  <Form.Label>
                    {" "}
                    School / College / University Code{" "}
                    <span className="mand-sign-field">*</span>
                  </Form.Label>
                  <Form.Control
                    // autoFocus
                    isInvalid={errorData?.instituteCode}
                    name="instituteCode"
                    type="text"
                    value={signUpData?.instituteCode}
                    onChange={(e) => handleChangeValue(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData?.instituteCode ? (
                      <>{errorDataValue?.instituteCode}</>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="student-info-row">
                <Form.Group className="delta-signup-md" controlId="email">
                  <Form.Label>
                    {" "}
                    Location <span className="mand-sign-field">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    // autoFocus
                    isInvalid={errorData?.instituteLocation}
                    name="instituteLocation"
                    type="text"
                    value={signUpData?.instituteLocation}
                    onChange={(e) => handleChangeValue(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData?.instituteLocation ? (
                      <>{errorDataValue?.instituteLocation}</>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="student-info-row">
                <Form.Group className="delta-signup-md" controlId="email">
                  <Form.Label>
                    {" "}
                    Phone Number<span className="mand-sign-field">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    // autoFocus
                    maxLength={10}
                    name="mobileNumber"
                    isInvalid={errorData?.mobileNumber}
                    type="text"
                    value={signUpData?.mobileNumber}
                    onChange={(e) => handleChangeValue(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData?.mobileNumber ? (
                      <>{errorDataValue?.mobileNumber}</>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="student-info-row">
                <Form.Group className="delta-signup-md" controlId="email">
                  <Form.Label>
                    {" "}
                    Email <span className="mand-sign-field">*</span>
                  </Form.Label>
                  <Form.Control
                    // autoFocus
                    name="email"
                    isInvalid={errorData?.email}
                    type="text"
                    value={signUpData?.email}
                    onChange={(e) => handleChangeValue(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData?.email ? <>{errorDataValue?.email}</> : null}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="student-info-row">
                <Form.Group className="delta-signup-md" controlId="panNo">
                  <Form.Label>
                    {" "}
                    Pan No <span className="mand-sign-field">*</span>
                  </Form.Label>
                  <Form.Control
                    // autoFocus
                    name="panNo"
                    maxLength={12}
                    isInvalid={errorData?.panNo}
                    type="text"
                    value={signUpData?.panNo}
                    onChange={(e) => handleChangeValue(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData?.panNo ? <>{errorDataValue?.panNo}</> : null}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="student-info-row">
                <Form.Group className="delta-signup-md" controlId="email">
                  <Form.Label>
                    {" "}
                    Established Year <span className="mand-sign-field">*</span>
                  </Form.Label>
                  <Form.Control
                    // autoFocus
                    maxLength={4}
                    isInvalid={errorData?.establishedYear}
                    name="establishedYear"
                    type="text"
                    value={signUpData?.establishedYear}
                    onChange={(e) => handleChangeValue(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData?.establishedYear ? (
                      <>{errorDataValue?.establishedYear}</>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="actions-btn-sec align-center">
            <button className="deltape-cancel-button" onClick={handleCancel}>
              Cancel
            </button>
            <button className="deltape-button" onClick={handleSaveClick}>
              {instituteId ? "Update Institute" : "Create Institute"}
            </button>
          </div>
        </div>
        <CustomModal
          show={showSuccessMessage}
          titleText={
            instituteId
              ? "Institute Successfully Updated."
              : "Institute Successfully Created."
          }
          deleteText="Ok"
          cancelText=""
          onDelete={() => {
            setShowSuccessMessage(false);
            // setIsProfileEdit(false);
            history.push("/institutes");
          }}
        />
      </InstituteSetUpStyled>
    </>
  );
};

export default CreateApplication;
