import styled from "styled-components";

export const PrintJobCardStyled = styled.div`
  .print-page-box-container {
    width: 950px;
    margin: auto;
    padding-top: 50px;
    background-color: #fff;
    min-height: 800px;
    padding: 40px;
  }
  .print-sub-head {
    color: #000;
    font-weight: 800;
  }
  .print-page-box-sec {
    border: 1px solid #000;
  }
  .print-head img {
    max-width: 100px;
    max-height: 50px;
  }
  .print-imag-lists {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    max-height: 120px;
    height: 120px;
    img {
      height: 100%;
      width: 100%;
      max-height: 110px;
      margin-right: 30px;
      object-fit: contain;
    }
  }
  .print-sub-head {
    p {
      margin-bottom: 0px;
      line-height: 28px;
    }
  }
  .logo-header-left-text {
    display: inline-block;
    margin-left: 10px;
  }
  .mainLogoImage-print {
    height: 60px;
  }
  .remarks-header {
    font-weight: bold;
  }
  .extra-items-value p {
    margin-bottom: 0px;
  }
  .print-job-id {
    font-weight: bold;
    font-size: 24px;
  }
  .bold-text {
    font-weight: bold;
  }
  .print-head {
    font-size: 30px;
    color: #0f3edb;
    font-weight: 600;
    text-align: center;
    border-bottom: 1px solid #000;
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    display: flex;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    > span {
      position: relative;
      top: 12px;
    }
  }
  .bar-code-example-con svg {
    // height: 50px;
  }
  .print-sub-head {
    font-size: 20px;
    color: rgb(15, 62, 219);
    font-weight: 500;
    text-align: center;
    // border-bottom: 1px solid #000;
    // padding-top: 5px;
  }
  .user-details-sec {
    display: flex;
    border-bottom: 1px solid #000;
  }
  .user-customer-sec {
    width: 50%;
  }
  .user-customer-sec-header,
  .sub-sub-head {
    text-align: center;
    color: #0f3edb;
    font-size: 20px;
    border-bottom: 1px solid #000;
    padding: 4px;
  }
  .checkbox-checked {
    margin-right: 6px;
  }
  .checkbox-checked-span-left {
    margin-right: 10px;
    opacity: 0.5;
  }

  .checkbox-checked-span {
    margin-right: 10px;
  }
  .footer-bottom-text {
    text-align: center;
  }
  .left-items {
    display: flex;
    padding: 12px;
    // height: 300px;
  }
  .names-list {
    width: 30%;
    padding-left: 7px;
    border-right: 1px solid #000;
  }
  .names-value {
    width: 70%;
    padding-left: 7px;
  }
  .user-customer-sec-left {
    border-right: 1px solid #000;
  }
  .product-details-item-row {
    display: flex;
    border-bottom: 1px solid #000;
  }
  .product-details-name {
    width: 20%;
    padding: 4px;
    padding-left: 7px;

    border-right: 1px solid #000;
  }
  .product-details-value {
    width: 30%;
    padding: 4px;
    padding-left: 7px;
  }
  .product-details-value1 {
    border-right: 1px solid #000;
  }
  .extra-items-list {
    display: flex;
    border-bottom: 1px solid #000;
  }
  .extra-items-key {
    color: #0f3edb;
    font-size: 20px;
    padding-left: 7px;
    padding: 4px;
    width: 26%;
    border-right: 1px solid #000;
  }
  .extra-items-value {
    width: 74%;
    padding: 5px;
  }
  .print-compu-text {
    border-bottom: 1px solid #000;
    font-size: 18px;
    padding-left: 7px;
    padding: 4px;
  }
  .print-sign {
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
    font-size: 20px;
    padding-left: 7px;
    padding: 5px;
  }
  .print-compu-text {
    text-align: center;
  }
  .print-sign-bottom {
    text-align: center;
  }
  .sign-head {
    color: #0f3edb;
    font-size: 20px;
    padding-left: 7px;
    padding: 4px;
  }
  .sign-sub-sub-head {
    color: #0f3edb;
    font-size: 16px;
    padding-left: 7px;
    padding: 6px;
  }
  .terms-list {
    font-size: 15px;
  }
  .terms-list-small-font {
    font-size: 13px;
  }
  .note-list-sec {
    padding-left: 7px;
  }
  .names-list1 {
    width: 40%;
  }
  .names-value1 {
    width: 60%;
  }
  .items-content-table {
    table {
      border: 1px solid #e2e2e2;
    }
    .table_s_no {
      width: 10% !important;
    }
    .table_s_desc {
      width: 70% !important;
    }
    .table_s_amount {
      width: 20% !important;
    }
    tr {
      border-bottom: 1px solid #e2e2e2;
    }
    th,
    td {
      padding: 10px;
    }
    .table-content-sec-bott {
      margin-top: 10px;
      padding-left: 10px;
      p {
        margin-bottom: 3px;
      }
    }
    .form-bank-details-se {
      padding-left: 10px;
    }
    .form-bank-details-sec-con {
      > div {
        display: flex;
        gap: 5px;
        > div:first-child {
          width: 100px;
          font-weight: 600;
        }
      }
    }
  }
`;
