import React, { useEffect, useState } from "react";
import { InstituteDashboardStyled } from "./InstituteDashboard.styled";
import { Row, Col, Button, Form, Dropdown } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import info_icon from "../../img/delta/info_icon.png";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "./../../Scenes/common";
import PiCharts from "./PiChart/PiCharts";
import { Chart } from "react-google-charts";
import { classesList } from "./../../config";
import {
  getAllStudentReportAPI,
  getAllPaymentReportAPI,
  getAllCounts,
} from "./../../redux/slices/Reports/ReportsService";
const InstituteDashboard = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [classData, setClassData] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFeeCollBar, setIsFeeCollBar] = useState(true);
  const [isAdmiBar, setIsAdmiBar] = useState(true);
  const [isStudentsBar, setIsStudentsBar] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user, isAdminSite } = useSelector(
    (state) => state?.auth
  );

  const { paymentsReportData, studentsReportData, allCountsData } = useSelector(
    (state) => state?.reports
  );
  console.log("studentsReportData", studentsReportData);
  console.log("allCountsData", allCountsData);
  useEffect(() => {
    triggerMixpanelEvent("Contact_Us");
    var newList = [];
    newList.push(["Class", "Count"]);

    classesList.map((item) => {
      const randamN = Math.random() * (100 - 40) + 40;
      const randamN1 = parseInt(randamN.toFixed());

      // newList.push([item?.label, randamN1]);
    });
    setClassData(newList);
    getDashboardDataCall();
  }, []);
  useEffect(() => {
    if (studentsReportData?.data?.length > 0) {
      var newList = [];
      var ttoC = 0;
      newList.push(["Class", "Count"]);
      studentsReportData?.data?.map((item) => {
        // const randamN = Math.random() * (100 - 40) + 40;
        // const randamN1 = parseInt(randamN.toFixed());
        newList.push([item?._id, item?.studentCount]);
        ttoC += item?.studentCount;
      });
      setClassData(newList);
      setTotalStudents(ttoC);
    }
  }, [studentsReportData?.data]);

  const getDashboardDataCall = () => {
    if (user?._id) {
      let body = {};
      if (!isAdminSite) {
        body.instituteId = user?._id;
      }
      dispatch(getAllStudentReportAPI(body));
      dispatch(getAllPaymentReportAPI(body));
      dispatch(getAllCounts(user?._id));
    }
  };
  const onChangeValue = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const data = [
    ["Assignments", "Count"],
    ["Total Assignments", 305300],
    ["Pending Assignments", 104500],
  ];

  const options = {
    // title: "Fee Collection Status",
    pieHole: 0.5,
    // is3D: true,
    // pieSliceText: "none",
    colors: ["#52cde7", "#8365fe"],
    bar: { groupWidth: "20%" },
    // chartArea: { width: "10px" },
    // width: "20px",
  };
  const options1 = {
    title: "Fee Collection Status",
    pieHole: 0.5,
    is3D: false,
    colors: ["#109CF1", "#FFB200", "#34B53A"],
  };
  const data2 = [
    ["Class", "Count"],
    ["2014", 40],
    ["2015", 90],
    ["2016", 30],
    ["2017", 20],
  ];

  const options2 = {
    colors: ["#52cde7", "#8365fe"],
    bar: { groupWidth: "20%" },
    pieHole: 0.5,
    // chart: {
    //   title: "Students List",
    //   subtitle: "All Students List by Class wise",
    // },
  };
  const data3 = [
    ["Admission Type", "Count"],
    ["New Admission", 350],
    ["Old Admission", 80],
  ];

  const options3 = {
    chart: {
      title: "Total Students: 430",
      subtitle: "All Students List by Admission wise",
    },
  };
  const options4 = {
    chartArea: { width: "70%" }, // Adjusts the chart area width
    hAxis: {
      minValue: 0,
    },
    colors: ["#52cde7", "#8365fe"],
    bar: { groupWidth: "40px" }, // Sets custom bar width
    legend: { position: "none" }, // Hides legend
  };

  return (
    <>
      <InstituteDashboardStyled>
        <div className="freshbag-wrapper">
          <div className="content getinTouchPage">
            <div className="contact">
              <div className="row dashboard-header-top-sec">
                <h4>Dashboard</h4>
                <p>A quick overview of your business.</p>
              </div>
              <div className="row dashboard-statistics-sec">
                <h4>Statistics</h4>

                <div className="dashboard-statistics-row-all">
                  <div className="dashboard-statistics-row">
                    <p className="statistics-row-text">
                      Total Assignments <img src={info_icon} />{" "}
                    </p>
                    <h4 className="statistics-row-count">
                      {allCountsData?.students}
                    </h4>
                  </div>
                  <div className="dashboard-statistics-row">
                    <p className="statistics-row-text">
                      Pending Assignments <img src={info_icon} />{" "}
                    </p>
                    <h4 className="statistics-row-count">
                      {allCountsData?.transactions}
                    </h4>
                  </div>
                  <div className="dashboard-statistics-row">
                    <p className="statistics-row-text">
                      Users <img src={info_icon} />{" "}
                    </p>
                    <h4 className="statistics-row-count">
                      {allCountsData?.users}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="chart-sec-full-view">
                <div>
                  <div className="chart-sec-head-sec">
                    <div>
                      <span
                        className={isFeeCollBar ? "active" : ""}
                        onClick={() => {
                          setIsFeeCollBar(true);
                        }}
                      >
                        Bar
                      </span>
                      <span
                        onClick={() => {
                          setIsFeeCollBar(false);
                        }}
                        className={!isFeeCollBar ? "active" : ""}
                      >
                        Line
                      </span>
                    </div>
                  </div>
                  <div className="chart-sec-body-sec">
                    <p>Assignments Status</p>
                    <div className="my-chart-container">
                      {isFeeCollBar ? (
                        <Chart
                          chartType="PieChart"
                          width="100%"
                          height="400px"
                          data={data}
                          options={options}
                        />
                      ) : (
                        <Chart
                          chartType="ColumnChart"
                          width="100%"
                          height="400px"
                          data={data}
                          options={options}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="chart-sec-head-sec">
                    <div>
                      <span
                        className={isAdmiBar ? "active" : ""}
                        onClick={() => {
                          setIsAdmiBar(true);
                        }}
                      >
                        Bar
                      </span>
                      <span
                        onClick={() => {
                          setIsAdmiBar(false);
                        }}
                        className={!isAdmiBar ? "active" : ""}
                      >
                        Line
                      </span>
                    </div>
                  </div>
                  <div className="chart-sec-body-sec">
                    <p>Assignments Status</p>
                    <div className="my-chart-container">
                      {isAdmiBar ? (
                        <Chart
                          chartType="PieChart"
                          width="100%"
                          height="400px"
                          data={data}
                          options={options}
                        />
                      ) : (
                        <Chart
                          chartType="ColumnChart"
                          width="100%"
                          height="400px"
                          data={data}
                          options={options}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="students-list-sec-all">
                <div className="chart-sec-head-sec chart-sec-head-sec1">
                  <span>Assignments List Day Wise</span>
                  <div>
                    <span
                      className={isStudentsBar ? "active" : ""}
                      onClick={() => {
                        setIsStudentsBar(true);
                      }}
                    >
                      Bar
                    </span>
                    <span
                      onClick={() => {
                        setIsStudentsBar(false);
                      }}
                      className={!isStudentsBar ? "active" : ""}
                    >
                      Line
                    </span>
                  </div>
                </div>

                <div>
                  {isStudentsBar ? (
                    <Chart
                      chartType="PieChart"
                      width="100%"
                      height="400px"
                      data={classData}
                      options={options2}
                    />
                  ) : (
                    <Chart
                      chartType="ColumnChart"
                      width="100%"
                      height="400px"
                      data={classData}
                      options={options2}
                    />
                  )}
                </div>
                {/* <h3>Admission Wise</h3>
                <div className="chart-view-section-fee">
                  <Chart
                    chartType="PieChart"
                    width="100%"
                    height="300px"
                    data={data3}
                    options={options3}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </InstituteDashboardStyled>
    </>
  );
};

export default InstituteDashboard;
