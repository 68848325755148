import styled from "styled-components";

export const StudentDetailsStyled = styled.div`
  .no-results {
    color: rgb(89, 85, 85);
    margin-top: 100px;
    text-align: center;
    border-top: 1px solid #e2e2e2;
    padding-top: 45px;
  }
  .create-new-sec {
    text-align: center;
    margin-top: 30px;
  }
  .dots-space-sec {
    margin-right: 5px;
    display: inline-block;
    span {
      font-size: 45px;
      line-height: 32px;
      padding: 10px;
      padding-top: -10px;
      font-weight: 800;
      letter-spacing: 5px;
      color: rgb(20, 162, 184);
    }
  }
  .student-details-rows {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    // border: 1px solid #e2e2e2;
    // box-shadow: 0px 6px 16px 0px #00000029;
  }
  .student-details-sec-con-de {
    display: flex;
    box-shadow: 0px 6px 16px 0px #00000029;
    margin-bottom: 30px;
    border-radius: 8px;
    padding: 10px;
    .svg-icon-secion-sd {
      width: 40px;
    }
    svg {
      fill: #14a2b8;
      cursor: pointer;
    }
  }
  .student-details-header {
    margin-bottom: 30px;
    span {
      cursor: pointer;
      color: #4682b4;
    }
  }

  create-new-institute-sec-edit {
    overflow: scroll;
    max-height: 500px;
    overflow-y: scroll;
  }
  .student-details-row {
    span {
      display: block;
    }
    .s-name-key {
      font-weight: 400;
      color: #9c9696;
    }
    .s-name-value {
      font-weight: 600;
      color: #000;
    }
  }
  .student-fee-details {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    box-shadow: 0px 6px 16px 0px #00000029;
    border-radius: 8px;
    margin-bottom: 30px;
    .student-type-sec {
      // border: 1px solid #e2e2e2;
      padding: 20px;
      border-radius: 4px;
      // display: flex;
      justify-content: space-between;
      // background: #e2e2e2;
      .amount-type {
        font-size: 18px;
        text-transform: capitalize;
      }
      .amount-value {
        font-weight: 800;
      }
    }
  }
  .student-full-structure-body {
    margin-top: 20px;
    margin-bottom: 100px;
    .ant-tabs-tab-btn {
      font-size: 20px;
    }
  }
`;
