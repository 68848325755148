import styled from "styled-components";

export const CreateApplicationStyled = styled.div`
  max-height: 520px;
  padding-left: 5px;
  overflow: scroll;
  .create-new-application-sec {
    width: 100%;
    margin: auto;
    // margin-top: 30px;
    // margin-bottom: 30px;
  }
  .actions-btn-sec {
    margin-top: 30px;
    text-align: right;
  }
  .student-info-row,
  .student-info-row-sec {
    width: 100%;
  }
  .cancel-btn-sec {
    border-radius: 4px;
    width: 85px;
    margin-right: 20px;
    border: 1px solid #e2e2e2;
    background: #fff;
    color: #000;
    height: 40px;
  }
  .Admission-Type-sec {
    display: flex;
    gap: 30px;
    label {
      cursor: pointer;
    }
  }
  .student-info-values {
    input.form-control {
      height: 36px;
    }
    .form-group {
      margin-bottom: 0px;
    }
  }
  .student-info-row-sec {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .student-info-row {
    // margin-top: 10px;
    margin-bottom: 10px;
    label {
      margin-bottom: 3px;
    }
  }
  .prev-values-section {
    border: 1px solid #e2e2e2;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
  .edit-sec-prev-values {
    text-align: right;
    button {
      border-radius: 4px;
      width: 85px;
      border: 1px solid #e2e2e2;
      background: #fff;
      color: #000;
      height: 40px;
    }
  }
  .prev-valu-row {
    span {
      font-size: 15px;
      color: rgb(107 105 105);
    }
    span:nth-child(1) {
      //   font-size: 20px;
      //   font-weight: bold;
      width: 120px;
      display: inline-block;
    }
    span:nth-child(2) {
      width: 10px;
      display: inline-block;
    }
  }
  .student-info-row label {
    font-size: 18px;
  }
  .add-child-header-sec-close {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 14px;
      line-height: 18px;
      color: #4c4c4c;
      cursor: pointer;
    }
    img {
      cursor: pointer;
    }
  }
  .step1-header-sec-main-img {
    text-align: center;
    h5 {
      font-size: 24px;
      color: #4c4c4c;
    }
    span {
      color: #1565c0;
    }
    p {
      font-size: 16px;
      color: #343434;
    }
  }
  input[type="radio"] {
    accent-color: #1565c0;
  }

  .actions-top-content .admision-type-sec-con,
  .student-info-values .admision-type-sec-con {
    margin-bottom: 0px;
    label {
      cursor: pointer;
    }
    .values-sec-txt label {
      color: #000;
    }
  }
  .student-info-values .admision-type-sec-con {
    margin-bottom: 5px;
  }
  .actions-top-content,
  .student-info-values {
    label {
      font-size: 12px;
      font-weight: 500;
      color: #9c9d9d;
    }
    input {
      border-radius: 8px;
    }
    li {
      cursor: pointer;
    }
  }
  .date-of-birth-row {
    .ant-picker {
      width: 100%;
      border-radius: 8px;
    }
  }
  .student-info-values .row {
    margin: 0px;
    .student-info-row {
      padding: 0px;
    }
  }
  .add-child-full-content-sec {
    display: flex;
    gap: 24px;
    margin-top: 30px;
  }

  .add-child-left-content-sec {
    width: 360px;
    min-width: 360px;
    background: #14a2b8;
    border-radius: 8px;
  }
  .add-child-right-content-sec {
    width: 100%;
  }
  .academic-class-selection-code {
    display: flex;
    gap: 16px;
    > div {
      width: 50%;
    }
  }
  .fee-payment-sec-main {
    padding: 15px 32px;
    p {
      font-size: 16px;
      font-weight: 300;
      color: rgba(242, 242, 242, 0.93);
      margin-bottom: 0px;
      padding-bottom: 0px;
      line-height: 20px;
    }
    h5 {
      font-size: 24px;
      text-transform: capitalize;
      color: #fff;
      margin-bottom: 6px;
    }
    h6 {
      font-size: 18px;
      color: #fff;
      margin-bottom: 6px;
    }
    .main-content-left-static {
      margin-top: 50px;
      margin-bottom: 10px;
      // margin-left: 30px;
      > div {
        display: flex;
        gap: 16px;
      }
      .step-content {
        padding-bottom: 28px;
      }
      .circle {
        background: #16ac87;
        height: 40px;
        width: 40px;
        text-align: center;
        border-radius: 63px;
        padding-top: 4px;
        border: 3px solid #fff;
        color: #fff;
      }
      .circle1 {
        height: 40px;
        padding-left: 0px;
        padding-top: 3px;
        width: 40px;
      }
      .circle2 {
        background: #17a2bb;
      }
      .circle3 {
        background: #b6b6b6;
      }
      .line {
        width: 3px;
        height: auto;
        background: #b6b6b6;
        height: 60px;
        margin-left: 18px;
      }
      .line2 {
        height: 60px;
      }
    }
    .Fee-Payment-text-sec {
      font-size: 14px;
    }
    .contact-sup-team-t {
      font-size: 14px;
    }
  }
  .user_info_icon_sec_top p {
    margin-top: 7px;
    font-size: 14px;
    font-weight: 500;
    color: #272727;
  }
  .pan-card-address-sec,
  .user-emp-details-sec,
  .income-verification-sec {
    .all-pan-address-row-all {
      width: 100%;
      padding-right: 30px;
      > div {
        box-shadow: 0px 8px 24px -6px #00000029;
        padding: 16px 16px 16.49px 16px;
        border-radius: 12px 0px 0px 0px;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        border: 1px solid #e2e2e2;
        border-radius: 8px;
        .d-flex {
          gap: 16px;
          img {
            padding-top: 4px;
          }
          h6 {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            margin-bottom: 3px;
          }
          p {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #4c4c4c;
            margin-bottom: 0px;
          }
        }
        input[type="radio"] {
          height: 24px;
          width: 24px;
          cursor: pointer;
        }

        button {
          background: rgb(255, 255, 255);
          padding: 3px 24px;
          border: 1px solid rgb(23, 162, 187);
          color: rgb(23, 162, 187);
          height: 30px;
          /* font-size: 20px; */
          /* margin-right: 30px; */
          border-radius: 8px;
        }
      }
    }
    .form-label {
      color: rgb(156, 157, 157);
    }
    .what-Resident-Type {
      label {
        cursor: pointer;
      }
    }
  }
  .user-emp-details-sec {
    h5 {
      font-size: 14px;
      font-weight: 600;
      line-height: 19.6px;
      margin-bottom: 10px;
    }
  }
  .income-verification-sec {
    .d-flex1 {
      display: flex;
      gap: 16px;
      border: 1px solid rgb(226, 226, 226);
      background: #e2e2e2;
      padding: 12px;
      border-radius: 8px;
      margin-bottom: 16px;
      p {
        margin-bottom: 0px;
      }
    }
    .income-verification-con-sec {
      .delta-select-row {
        margin-bottom: 32px;
      }
    }
  }
  .user-emi-plan-details-sec {
    .user-emi-plan-top {
      text-align: center;
      h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 29.05px;

        span {
          color: #1565c0;
        }
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
        color: #343434;
      }
    }
    .all-emi-plans-row-all {
      width: 95%;
      margin: auto;
    }
    .contact-sup-team {
      font-weight: 400;
      margin-bottom: 5px;
    }

    .all-emi-plans-row {
      box-shadow: 0px 8px 24px -6px #00000029;
      padding: 16px 16px 16.49px 16px;
      border-radius: 12px 0px 0px 0px;
      margin-bottom: 16px;
      // display: flex;
      justify-content: space-between;
      // gap: 20px;
      cursor: pointer;
      border: 1px solid #e2e2e2;
      border-radius: 8px;
      > div {
        display: flex;
        justify-content: space-between;
        padding: 0px 10px;
      }
      .circle-number {
        background: #b1e6ee;
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 50px;
        padding: 8px;
        padding-left: 9px;
      }
      .all-emi-plans-top-sec1 {
        h6 {
          font-size: 16px;
          font-weight: 700;
          line-height: 22.4px;
          padding-top: 10px;
        }
        .d-flex {
          gap: 16px;
        }
        input {
          margin-top: 10px;
          width: 24px;
          height: 24px;
        }
      }
      .all-emi-plans-top-sec2 {
        border-top: 1px solid #ebeef0;
        margin-top: 16px;
        padding-top: 16px;
        width: 100%;
        display: block;
        > div {
          border: 1px solid #ebeef0;
          display: flex;
          justify-content: space-between;
          // padding: 16px 0px;
          border-radius: 8px;
          > div {
            width: 50%;
            text-align: left;
            padding: 16px;
            h5 {
              font-size: 16px;
              font-weight: 400;
              line-height: 19.36px;
              color: #666666;
            }
            h6 {
              font-size: 18px;
              font-weight: 500;
              line-height: 25.2px;
              color: #2f3031;
            }
          }
        }
        .emi-plans-top-sec-sub1 {
          border-right: 1px solid #ebeef0;
        }
      }
      .all-emi-plans-top-sec3 {
        margin-top: 10px;
        p {
          padding: 5px;
          background: #e4eafa;
          margin-bottom: 0px;
          font-size: 16px;
          font-weight: 300;
          line-height: 16px;
          border-radius: 8px;
          .dot-circle {
            width: 8px;
            height: 8px;
            background: #2c4584;
            display: inline-block;
            margin-right: 10px;
            border-radius: 50px;
          }
        }
      }
    }
  }
  .user-preview-sec {
    width: 97%;
    margin: auto;
    .user-emi-plan-top {
      text-align: center;
      h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 29.05px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
        color: #343434;
      }
    }
    .d-flex1 {
      display: flex;
      gap: 16px;
      border: 1px solid rgb(226, 226, 226);
      background: #e2e2e2;
      padding: 12px;
      border-radius: 8px;
      margin-bottom: 16px;
      p {
        margin-bottom: 0px;
      }
    }
    .app-preview-row {
      margin-top: 16px;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #e2e2e2;
      box-shadow: 0px 0px 1px 0px #00000066;

      box-shadow: 0px 8px 24px -6px #00000029;

      .app-preview-top {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ebeef0;
        justify-content: space-between;
        padding-bottom: 10px;
        margin-bottom: 10px;
        img {
          margin-right: 10px;
        }
        .edit-img {
          cursor: pointer;
        }
      }
      .all-fields-rows {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        p {
          margin-bottom: 0px;
        }
        .key {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: #666666;
          margin-bottom: 3px;
        }
        .value {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          color: #333333;
        }
      }
    }
  }
`;
