import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import { SideMenuStyled } from "./SideMenu.styled";
import booking from "./../../img/booking.png";
import teamWorkImg from "./../../img/teamwork.png";
import amcmana from "./../../img/icons8-analytics-30.png";
import homeicon from "./../../img/house-black-silhouette-without-door.png";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { checkRolePermission, checkAccessMenu } from "./../../Scenes/common";
import {
  getJobcardSummaryApi,
  getFilteredjobcardsummaryApi,
  getFilteredjobcardsummaryApiPending,
  getJobCardDetailsApi,
  jobCardRejectCall,
  jobCardClsoeCall,
} from "./../../redux/slices/serviceManagement/serviceManagementService";
import { updateUserDetails } from "./../../redux/slices/auth/authSlice";
// import teamWorkImg from "./../../img/teamwork.png";
// import teamWorkImg from "./../../img/teamwork.png";
const Sidebar = (props: any) => {
  const [sidebar, setSidebar] = useState(false);
  const [pendingJobcardsCount, setPendingJobcardsCount] = useState(0);
  const { user, isParentSite, isAdminSite } = useSelector(
    (stateR: any) => stateR?.auth
  );

  const menuAcceFlag = checkAccessMenu();

  const dispatch = useDispatch();
  const history = useHistory();

  const showSidebar = () => setSidebar(!sidebar);
  useEffect(() => {
    // allPendingApprovalJobs(1);
  }, []);
  //   left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  //   Home
  //   Service Management
  //   Lead Management
  //   Booking Management
  //   AMC Management
  //   Users List
  //  Change Password

  // Jobcard Summary
  // Create Job Card
  // Items Received List
  // Default IssuesOption List
  // Additional Requirement List
  // Brand List
  // Product List
  // Actiontaken List
  // Engineer Observation List
  // Customer Note List
  // Postal Code List
  // Area / Ward List
  console.log("props", props);
  const allPendingApprovalJobs = async (pageN: any) => {
    const paramsList = [] as any;
    const page222 = pageN;
    paramsList.push(`pageNo=${page222}`);
    paramsList.push(`status=Closing Approval`);
    const paramsV = paramsList.join("&");
    const resp1 = (await dispatch(
      getFilteredjobcardsummaryApiPending(paramsV)
    )) as any;
    console.log("noOfJobCardsnoOfJobCards", resp1);
    setPendingJobcardsCount(resp1?.payload?.noOfJobCards);
  };

  const menuLinks = isParentSite
    ? [
        {
          title: "Profile",
          id: "Profile",
          icon: homeicon,
          sublinks: [],
          link: "/dashboard/Profile",
        },
        {
          title: "Dashboard",
          id: "Dashboard",
          icon: homeicon,
          sublinks: [],
          link: "/customerDashboard",
        },

        {
          title: "Applications",
          id: "Applications",
          icon: homeicon,
          sublinks: [],
          link: "/dashboard/Applications",
        },
        {
          title: "Transactions",
          id: "PlansandPayments",
          icon: homeicon,
          sublinks: [],
          link: "/dashboard/transactions",
        },
      ]
    : isAdminSite
    ? [
        // {
        //   title: "Profile",
        //   id: "Profile",
        //   icon: homeicon,
        //   sublinks: [],
        //   link: "/dashboard/Profile",
        // },
        {
          title: "Dashboard",
          id: "Dashboard",
          icon: homeicon,
          sublinks: [],
          link: "/adminDashboard",
        },
        {
          title: "Task List",
          id: "Task",
          icon: homeicon,
          sublinks: [],
          link: "/taskList",
        },
        {
          title: "Students",
          id: "Students",
          icon: homeicon,
          sublinks: [],
          link: "/Students",
        },

        // {
        //   title: "Transactions",
        //   id: "PlansandPayments",
        //   icon: homeicon,
        //   sublinks: [],
        //   link: "/dashboard/transactions",
        // },
        // {
        //   title: "Delta Zero",
        //   id: "DeltaZero",
        //   icon: homeicon,
        //   sublinks: [],
        //   link: "/dashboard/DeltaZero",
        // },
        // {
        //   title: "Delta Auto",
        //   id: "DeltaAuto",
        //   icon: homeicon,
        //   link: "/dashboard/DeltaAuto",
        // },
        // {
        //   title: "Delta Pay",
        //   id: "DeltaPay",
        //   icon: homeicon,
        //   link: "/dashboard/DeltaPay",
        // },
        // {
        //   title: "Reports",
        //   id: "Reports",
        //   icon: homeicon,
        //   sublinks: [],
        //   link: "/Reports",
        // },
        {
          title: "Manage Users",
          id: "manageUsers",
          icon: homeicon,
          sublinks: [],
          link: "/manageUsers",
        },
      ]
    : [
        {
          title: "Dashboard",
          id: "Dashboard",
          icon: homeicon,
          sublinks: [],
          link: isParentSite ? "/myDashboard" : "/myDashboard",
        },
        {
          title: "Clients",
          id: "Clients",
          icon: homeicon,
          sublinks: [],
          link: "/Clients",
        },
        {
          title: "Assignments",
          id: "Assignments",
          icon: homeicon,
          sublinks: [],
          link: "/Assignments",
        },
        {
          title: "Billing",
          id: "Billings",
          icon: homeicon,
          sublinks: [],
          link: "/billings",
        },
        {
          title: "Reports",
          id: "Reports",
          icon: homeicon,
          sublinks: [],
          link: "/Reports",
        },
        {
          title: "Manage Users",
          id: "manageUsers",
          icon: homeicon,
          sublinks: [],
          link: "/manageUsers",
        },
      ];
  const gotoNewPage = (url: any) => {
    console.log("urlurlurl", url);
    history.push(url);
  };
  const logoutGotoNewPage = () => {
    localStorage.clear();
    dispatch(updateUserDetails(null));
    history.push("/signin");
  };
  const selectedUrl = window.location.pathname;
  return (
    <>
      <SideMenuStyled>
        <div className="accordion side-bar-container" id="accordionExample">
          <div className="left-header-sec-cont-name">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#fff"
                className="bi bi-person-circle"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                <path
                  fill-rule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                />
              </svg>
            </div>
            <div className="color-fff left-menu-sec-name">
              <span>{`${user?.firstName || "User"} ${
                user?.lastName || "Name"
              }`}</span>
              {!isParentSite && <></>}
            </div>
          </div>
          <span className="main-menu-sec">MAIN MENU</span>
          {menuLinks.map((item: any, index: any) => {
            // console.log("itemitem", item);
            const idx = item?.sublinks?.findIndex(
              (u: any) => u?.link === selectedUrl
            );
            console.log("item?.link", item?.link);
            console.log("itemselectedUrl", selectedUrl);
            const isOpenSec = selectedUrl === item?.link ? true : false;
            // console.log("selectedUrl", selectedUrl);
            // console.log("checkRolePermission");
            return (
              <>
                {checkRolePermission(item?.id) && (
                  <div
                    className={`accordion-item ${item?.id} ${
                      !checkAccessMenu(item?.id)
                        ? ""
                        : "accordion-item-disabled-view"
                    }`}
                  >
                    <h2
                      className={
                        isOpenSec
                          ? "accordion-header accordion-header-selected"
                          : "accordion-header"
                      }
                      id={item?.id}
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        title={
                          !checkAccessMenu(item?.id)
                            ? item?.title
                            : "Coming Soon"
                        }
                        disabled={checkAccessMenu(item?.id)}
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${item?.id}`}
                        // aria-expanded={isOpenSec ? true : false}
                        aria-controls={`collapse${item?.id}`}
                        onClick={() => {
                          gotoNewPage(item?.link);
                        }}
                      >
                        {index === 0 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            // fill="currentColor"
                            className="bi MuiSvgIcon-fontSizeMedium bi-person-lines-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z" />
                          </svg>
                        ) : index === 1 ? (
                          <svg
                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            data-testid="DvrOutlinedIcon"
                          >
                            <path d="M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 14H3V5h18v12zm-2-9H8v2h11V8zm0 4H8v2h11v-2zM7 8H5v2h2V8zm0 4H5v2h2v-2z"></path>
                          </svg>
                        ) : index === 2 ? (
                          <svg
                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            data-testid="DvrOutlinedIcon"
                          >
                            <path d="M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 14H3V5h18v12zm-2-9H8v2h11V8zm0 4H8v2h11v-2zM7 8H5v2h2V8zm0 4H5v2h2v-2z"></path>
                          </svg>
                        ) : index > 2 ? (
                          <svg
                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            data-testid="ReceiptLongOutlinedIcon"
                          >
                            <path d="M19.5 3.5 18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2v14H3v3c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3V2l-1.5 1.5zM15 20H6c-.55 0-1-.45-1-1v-1h10v2zm4-1c0 .55-.45 1-1 1s-1-.45-1-1v-3H8V5h11v14z"></path>
                            <path d="M9 7h6v2H9zm7 0h2v2h-2zm-7 3h6v2H9zm7 0h2v2h-2z"></path>
                          </svg>
                        ) : null}
                        {/* {item?.icon && <img src={item?.icon} />} */}
                        {item?.title}{" "}
                      </button>
                    </h2>
                    {item?.sublinks?.length > 0 && (
                      <div
                        id={`collapse${item?.id}`}
                        className={
                          isOpenSec
                            ? "accordion-collapse collapse show"
                            : "accordion-collapse collapse"
                        }
                        aria-labelledby={item?.id}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body accordion-body-items">
                          <ul>
                            {item?.sublinks?.map((linkItem: any) => {
                              let newJson =
                                linkItem?.itemId || linkItem?.id || item?.id;
                              if (
                                item?.id === "focusNextLeadManagement" ||
                                item?.id === "focusNextAMCManagement"
                              ) {
                                newJson = item?.id;
                              }
                              return (
                                <>
                                  {checkRolePermission(newJson) && (
                                    <li
                                      onClick={() => {
                                        gotoNewPage(linkItem?.link);
                                      }}
                                    >
                                      <a
                                        href="#"
                                        className={
                                          selectedUrl === linkItem?.link
                                            ? "selectedItem"
                                            : ""
                                        }
                                      >
                                        <span>{linkItem?.title}</span>{" "}
                                      </a>
                                    </li>
                                  )}
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            );
          })}
          {/* <div className={`accordion-item`}>
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                onClick={() => {
                  logoutGotoNewPage();
                }}
              >
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  data-testid="LogoutOutlinedIcon"
                >
                  <path d="m17 8-1.41 1.41L17.17 11H9v2h8.17l-1.58 1.58L17 16l4-4-4-4zM5 5h7V3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h7v-2H5V5z"></path>
                </svg>
                Log out
              </button>
            </h2>
          </div> */}
        </div>
      </SideMenuStyled>
    </>
  );
};

export default Sidebar;
