import React, { useEffect, useState } from "react";

import { Row, Col, Button, Form } from "react-bootstrap";
import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Profile_pp from "../../img/Profile_pp.png";
import UserProfile from "./UserProfile";
import InstituteProfile from "./InstituteProfile";
import { triggerMixpanelEvent } from "../../Scenes/common";
const MyProfile = () => {
  const [addressEnabled, setAddressEnabled] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user, isParentSite } = useSelector(
    ({ auth }) => auth
  );
  const [currentStep, setCurrentStep] = useState("profile");

  useEffect(() => {
    triggerMixpanelEvent("my_profile_page");
  }, []);
  console.log("user", user);
  const onClickInfo = (val) => {
    setCurrentStep(val);
  };
  const gotoPage = (url) => {
    history.push(url);
  };
  return (
    <>
      <div className="myProfile-wrapper">
        <div className="myProfile-text">
          <div className="myProfile-container">
            <div className="profile-right-container">
              <div className="profile-content">
                {isParentSite ? <UserProfile /> : <InstituteProfile />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
