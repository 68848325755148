import styled from "styled-components";

export const CustomerDashboardStyled = styled.div`
  .no-results {
    color: rgb(89, 85, 85);
    margin-top: 100px;
    text-align: center;
  }
  .all-applocations-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 20px;
  }
  .child-name-row {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 30px;
  }
  .prev-values-section {
    // border: 1px solid #e2e2e2;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 30px;
    // display: flex;
    // justify-content: space-between;
    gap: 30px;
    box-shadow: 0px 6px 16px 0px #00000029;
  }
  .prev-values-section-left {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .edit-sec-prev-values {
    button {
      // margin-top: 20px;
      // width: 250px;
    }
  }
  .prev-valu-row {
    span {
      font-size: 15px;
      color: rgb(107 105 105);
    }
    span:nth-child(1) {
      //   font-size: 20px;
      //   font-weight: bold;
      width: 120px;
      display: inline-block;
    }
    span:nth-child(2) {
      width: 10px;
      display: inline-block;
    }
  }
  .app-preview-row {
    margin-top: 10px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e2e2e2;
    box-shadow: 0px 0px 1px 0px #00000066;
    box-shadow: 0px 8px 24px -6px #00000029;

    .app-preview-top {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ebeef0;
      justify-content: space-between;
      padding-bottom: 10px;
      margin-bottom: 10px;
      img {
        margin-right: 10px;
      }
      .edit-img {
        cursor: pointer;
      }
    }
    .all-fields-rows {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      p {
        margin-bottom: 0px;
      }
      .key {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #666666;
        margin-bottom: 3px;
      }
      .value {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        color: #333333;
      }
    }
  }
`;
