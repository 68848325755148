import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createStudentAPI,
  getAllStudents,
  getStudentDetailsAPI,
  getAllDirectPaymentsAPI,
  getAllFeeComponentsAPI,
} from "./StudentManagementService";

export interface leadManagementState {
  loading: boolean;
  error: any;
  studentDetails: any;
  allStudents: any;
  allDirectPayments: any;
  allFeeComponents: any;
}

const initialState: leadManagementState = {
  loading: false,
  error: null,
  studentDetails: [],
  allStudents: [],
  allDirectPayments: [],
  allFeeComponents: [],
};

export const StudentManagement = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    // updateUserDetails: (
    //   state: leadManagementState,
    //   action: PayloadAction<boolean>
    // ) => {
    //   state.user = action.payload;
    // },
    updateErrorMessage: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
    updatesStudentDetails: (
      state: leadManagementState,
      action: PayloadAction<any>
    ) => {
      state.studentDetails = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(createStudentAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      createStudentAPI.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.studentDetails = action.payload?.data;
      }
    );
    builder.addCase(
      createStudentAPI.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.studentDetails = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    builder.addCase(getStudentDetailsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getStudentDetailsAPI.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.studentDetails = action.payload?.data;
      }
    );
    builder.addCase(
      getStudentDetailsAPI.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.studentDetails = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );

    //getAllStudents
    builder.addCase(getAllStudents.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllStudents.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allStudents = action.payload;
      }
    );
    builder.addCase(
      getAllStudents.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.allStudents = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //getAllDirectPaymentsAPI
    builder.addCase(getAllDirectPaymentsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllDirectPaymentsAPI.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allDirectPayments = action.payload?.data;
      }
    );
    builder.addCase(
      getAllDirectPaymentsAPI.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.allDirectPayments = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //getAllFeeComponentsAPI
    builder.addCase(getAllFeeComponentsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllFeeComponentsAPI.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allFeeComponents = action.payload?.data;
      }
    );
    builder.addCase(
      getAllFeeComponentsAPI.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.allFeeComponents = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
  },
});

export const { setLoading, updateErrorMessage, updatesStudentDetails } =
  StudentManagement.actions;

export default StudentManagement.reducer;
