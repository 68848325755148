import styled from "styled-components";

export const CreateApplicationStyled = styled.div`
  .create-new-application-sec {
    width: 500px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .actions-btn-sec {
    margin-top: 30px;
    text-align: right;
  }
  .student-info-row,
  .student-info-row-sec {
    width: 100%;
  }
  .cancel-btn-sec {
    border-radius: 4px;
    width: 85px;
    margin-right: 20px;
    border: 1px solid #e2e2e2;
    background: #fff;
    color: #000;
    height: 40px;
  }
  .Admission-Type-sec {
    display: flex;
    gap: 30px;
    label {
      cursor: pointer;
    }
  }
  .student-info-values {
    input.form-control {
      height: 50px;
    }
  }
  .student-info-row-sec {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .student-info-row {
    margin-top: 10px;
    margin-bottom: 20px;
    label {
      margin-bottom: 3px;
    }
  }
  .prev-values-section {
    border: 1px solid #e2e2e2;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
  .edit-sec-prev-values {
    text-align: right;
    button {
      border-radius: 4px;
      width: 85px;
      border: 1px solid #e2e2e2;
      background: #fff;
      color: #000;
      height: 40px;
    }
  }
  .prev-valu-row {
    span {
      font-size: 15px;
      color: rgb(107 105 105);
    }
    span:nth-child(1) {
      //   font-size: 20px;
      //   font-weight: bold;
      width: 120px;
      display: inline-block;
    }
    span:nth-child(2) {
      width: 10px;
      display: inline-block;
    }
  }
  .student-info-row label {
    font-size: 18px;
  }
`;
