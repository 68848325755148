import React, { useEffect, useState } from "react";
import { IndexsStyled } from "./Index.styled";
import { Table, Badge, Dropdown, Space, Pagination } from "antd";

import { Row, Col, Button, Form } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddNewUserModel from "./AddNewMedicineModel";
import moment from "moment";
import Loader from "../../components/Common/Loader";
import doctor_img from "./../../img/young-hispanic-woman-working-scientist-laboratory-holding-safety-glasses-smiling-happy-pointing-with-hand-finger-side 1(1).png";
import Select from "react-select";
// import add_icon from "../../images/img/add.png";
// import add_icon1 from "../../images/img/download-icon.png";
import { Tabs } from "antd";
import { getAllAssignments } from "../../redux/slices/Assignments/AssignmentsService";
import {
  getAllBillings,
  createBillingAPI,
} from "../../redux/slices/Billings/BillingsService";
import { checkIsMobile } from "../../Scenes/common";

// import {
//   getAllDoctors,
//   updateDoctorStatusCall,
//   updateDoctorStatusApproveCall,
//   updateDoctorStatusRejectCall,
// } from "../../redux/slices/doctors/doctorsService";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import CustomModal from "../../components/CustomModel";
import Switch from "react-switch";
import print_img from "../../img/delta/print-6568980_640.png";
import edit_img from "../../img/delta/edit-6931553_640.png";

const Billings = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [showAddNewUserModel, setShowAddNewUserModel] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [signUpData, setSignUpData] = useState({} as any);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showSuccessMessageText, setShowSuccessMessageText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedType, setSelectedType] = useState({} as any);
  const [selectedStatus, setSelectedStatus] = useState({} as any);
  const [selectedDoctor, setSelectedDoctor] = useState({} as any);
  const [selectedAction, setSelectedAction] = useState("");
  const [conformClosingModel, setConformClosingModel] = useState(false);
  const [conformClosingModelText, setConformClosingModelText] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const [pageNo, setPageNo] = useState(0);

  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user, isAdminSite } = useSelector(
    (state: any) => state?.auth
  );
  const { allAssignments } = useSelector((state: any) => state?.assignments);
  console.log("allAssignments", allAssignments);
  const { billingDetails, allBillings } = useSelector(
    (state: any) => state?.billings
  );
  console.log("allBillings", allBillings);
  console.log("allBillings", allBillings);
  useEffect(() => {
    getAllAssignmentsCall();
    getAllBillingsCall();
  }, []);
  useEffect(() => {
    getAllAssignmentsCall();
  }, [
    searchText,
    selectedType?.label,
    selectedStatus?.label,
    pageNo,
    pageSize,
  ]);

  const getAllAssignmentsCall = async () => {
    let body = { vendorId: user?._id } as any;
    // if (searchText) {
    body.searchText = searchText;
    // }
    if (selectedType?.label && selectedType?.label !== "All") {
      body.type = selectedType?.value;
    }
    if (selectedStatus?.label && selectedStatus?.label !== "All") {
      body.status = selectedStatus?.value;
    }

    body.pageSize = pageSize;
    body.pageNo = pageNo;

    setIsLoading(true);
    dispatch(getAllAssignments(body));
    setIsLoading(false);
  };
  const getAllBillingsCall = async () => {
    let body = { vendorId: user?._id } as any;
    // if (searchText) {
    body.searchText = searchText;
    // }
    if (selectedType?.label && selectedType?.label !== "All") {
      body.type = selectedType?.value;
    }
    if (selectedStatus?.label && selectedStatus?.label !== "All") {
      body.status = selectedStatus?.value;
    }

    body.pageSize = pageSize;
    body.pageNo = pageNo;

    setIsLoading(true);
    dispatch(getAllBillings(body));
    setIsLoading(false);
  };
  const handlePageChange = (val: any, val1: any) => {
    setPageSize(val1);
    setPageNo(val);
  };
  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleCancel = () => {
    setShowAddNewUserModel(false);
  };
  const addUserSuccess = () => {
    setShowAddNewUserModel(false);
    setShowSuccessMessage(true);
  };
  const handleCreatenewApp = () => {
    setSelectedUser("");
    setShowAddNewUserModel(true);
  };
  const handleChangeValue = (e: any) => {
    let { name, value } = e.target;
    setPageNo(0);
    setSearchText(value);
  };
  const instituteAction = (itemV: any, actionN: any) => {
    setSelectedAction(actionN);
    setSelectedDoctor(itemV);
    if (actionN === "edit") {
    } else if (actionN == "approve") {
      setConformClosingModel(true);
      setConformClosingModelText(
        "Are you sure do you want to <span>Approve</span> below Doctor."
      );
    } else if (actionN == "reject") {
      setConformClosingModel(true);
      setConformClosingModelText(
        "Are you sure do you want to <span>Reject</span>  below Doctor."
      );
    }
  };
  const pageonChange = (page: any) => {
    console.log("page", page);
    setCurrentPage(page);
  };
  const handleChangeRole = (selectedOption: any) => {
    setPageNo(0);
    setSelectedType(selectedOption);
  };
  const handleChangeRole1 = (selectedOption: any) => {
    setPageNo(0);
    setSelectedStatus(selectedOption);
  };

  const handleCreateBilling = async (itemd: any) => {
    console.log("itemd", itemd);
    const body = {
      clientID: itemd?.clientID,
      assignmentId: itemd?._id,
      vendorId: itemd?.vendorId,
      amount: 500,
    };
    await dispatch(createBillingAPI(body));
    getAllBillingsCall();
  };
  const handleViewBilling = async (itemd: any) => {
    window.open(`/billingDetails/${itemd?._id}`, "_blank");
  };
  const handleEditBilling = async (itemd: any) => {
    // console.log("itemd", itemd);
    // const body = {
    //   clientID: itemd?.clientID,
    //   assignmentId: itemd?._id,
    //   vendorId: itemd?.vendorId,
    //   amount: 500,
    // };
    // await dispatch(createBillingAPI(body));
    // getAllBillingsCall();
  };
  const handleChangeSwitch = async (itemV: any, fPro: any) => {
    // setShowOnlyOfferProducts(fPro);
    console.log("fPro", itemV, fPro);
    const body = {
      id: itemV?.id,
      status: fPro ? "approved" : "inactive",
    };
    // await dispatch(updateDoctorStatusCall(body));
    // getAllUsersCall();
  };
  const handleSubmitBtnConform = async (reasonText: any) => {
    if (selectedDoctor?.id) {
      if (selectedAction === "approve") {
        const body = {
          id: selectedDoctor?.id,
        };
        // await dispatch(updateDoctorStatusApproveCall(body));
        // getAllUsersCall();
        setShowSuccessMessage(true);
        setShowSuccessMessageText("Successfullt Approved the Doctor");
      } else if (selectedAction === "reject") {
        const body = {
          id: selectedDoctor?.id,
          reason: reasonText,
        };
        // await dispatch(updateDoctorStatusRejectCall(body));
        // getAllUsersCall();
        setShowSuccessMessage(true);
        setShowSuccessMessageText("Successfullt Rejected the Doctor");
      }
      console.log("se", selectedAction, selectedDoctor);
    }
  };

  const items = [
    {
      key: "approve",
      label: <span className="drop-down-single-item-sec">Approve</span>,
    },
    {
      key: "reject",
      label: <span className="drop-down-single-item-sec">Reject</span>,
    },
    {
      key: "edit",
      label: <span className="drop-down-single-item-sec">Edit</span>,
    },
    {
      key: "delete",
      label: <span className="drop-down-single-item-sec">Delete</span>,
    },
  ];
  const columns = [
    {
      title: "Client Name",
      width: 200,
      key: "0",
      fixed: `${checkIsMobile() ? "none" : "fixed"}`,
      render: (item: any) => {
        return (
          <>
            <span className="table-student-name">
              {item?.clientName || "Client 1"}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Ref No",
    //   width: 100,
    //   dataIndex: "RefNo",
    //   key: "1",
    // },

    {
      title: "Assignment Name",
      dataIndex: "assignmentName",
      key: "2",
      width: 200,
    },
    {
      title: "Assignment Type",
      dataIndex: "assignmentType",
      key: "2",
      width: 180,
    },
    {
      title: "Assign To",
      dataIndex: "assignTo",
      key: "2",
      width: 200,
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "3",
      width: 200,
      render: (item: any) => {
        return <>{item || "24-11-2024"}</>;
      },
    },

    {
      title: "End Date",
      dataIndex: "endDate",
      key: "3",
      width: 200,
      render: (item: any) => {
        return <>{item || "24-11-2024"}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "3",
      width: 200,
      render: (item: any) => {
        return <>{item || "In-progress"}</>;
      },
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "3",
      width: 500,
      render: (item: any) => {
        return <>{item || "In-progress"}</>;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "7",
      width: 150,
      render: (item: any) => {
        return <>{moment(item).format("DD/MM/YYYY")}</>;
      },
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (itemId: any) => {
        console.log("item", itemId);

        return (
          <div className="action-icons-sec-new">
            <button
              disabled={
                itemId?.status === "Done" && !itemId?.billingId ? false : true
              }
              onClick={() => {
                handleCreateBilling(itemId);
              }}
            >
              {itemId?.billingId ? "Billing Created" : "Create Billing"}
            </button>
          </div>
        );
      },
    },
  ] as any;
  const billingsColumns = [
    {
      title: "Client Name",
      width: 200,
      key: "0",
      fixed: `${checkIsMobile() ? "none" : "fixed"}`,
      render: (item: any) => {
        return (
          <>
            <span className="table-student-name">
              {item?.clientName || "Client 1"}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Ref No",
    //   width: 100,
    //   dataIndex: "RefNo",
    //   key: "1",
    // },

    {
      title: "Assignment Name",
      dataIndex: "assignmentName",
      key: "2",
      width: 200,
    },
    {
      title: "Assignment Type",
      dataIndex: "assignmentType",
      key: "2",
      width: 180,
    },
    {
      title: "Assign To",
      dataIndex: "assignTo",
      key: "2",
      width: 200,
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "3",
      width: 200,
      render: (item: any) => {
        return <>{item || "24-11-2024"}</>;
      },
    },

    {
      title: "End Date",
      dataIndex: "endDate",
      key: "3",
      width: 200,
      render: (item: any) => {
        return <>{item || "24-11-2024"}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "3",
      width: 200,
      render: (item: any) => {
        return <>{item || "In-progress"}</>;
      },
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "3",
      width: 500,
      render: (item: any) => {
        return <>{item || "In-progress"}</>;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "7",
      width: 150,
      render: (item: any) => {
        return <>{moment(item).format("DD/MM/YYYY")}</>;
      },
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (itemId: any) => {
        console.log("item", itemId);

        return (
          <div className="action-icons-sec-new">
            <button
              onClick={() => {
                handleEditBilling(itemId);
              }}
            >
              Edit
            </button>
            <button
              onClick={() => {
                handleViewBilling(itemId);
              }}
            >
              View Bill
            </button>
          </div>
        );
      },
    },
  ] as any;

  const categoryColumns = [
    {
      title: "Category Name",
      width: 70,
      dataIndex: "name",
      key: "30",
    },
    {
      title: "image",

      key: "3",
      dataIndex: "image",
      width: 100,
      render: (item: any) => {
        return (
          <>
            <img className="item-img-sec" src={item} />
          </>
        );
      },
    },
    {
      title: "image",
      key: "4",
      dataIndex: "image",
      width: 100,
      render: (item: any) => {
        return <>Change Image</>;
      },
    },
  ] as any;
  const subCategoryColumns = [
    {
      title: "Category Name",
      width: 70,
      dataIndex: "name",
      key: "30",
    },
    {
      title: "Sub Category Name",
      width: 70,
      dataIndex: "cname",
      key: "30",
    },
    {
      title: "image",
      key: "3",
      dataIndex: "image",
      width: 100,
      render: (item: any) => {
        return (
          <>
            <img className="item-img-sec" src={item} />
          </>
        );
      },
    },
    {
      title: "image",
      key: "4",
      dataIndex: "image",
      width: 100,
      render: (item: any) => {
        return <>Change Image</>;
      },
    },
  ] as any;
  const rolesList = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Virtual",
      value: "virtual",
    },
    {
      label: "Opd",
      value: "opd",
    },
  ] as any;

  const rolesList1 = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
  ] as any;
  console.log("conformClosingModel", conformClosingModel);
  const GenerateBillingSection = () => {
    return (
      <>
        {" "}
        <div className="content getinTouchPage">
          <div>
            <div className="profileinfoHeader">
              <p>View All Bills</p>
            </div>
          </div>

          <div className="create-new-institute-sec-content-all">
            <div className="student-fields-sec-content-all deltape-form">
              <div className="student-info-row">
                <Form.Group className="delta-signup-md" controlId="email">
                  <Form.Label> Search</Form.Label>
                  <Form.Control
                    placeholder="Search: Name, Email, Phone"
                    // autoFocus
                    // isInvalid={errorData?.studentName}
                    name="studentName"
                    type="text"
                    value={searchText}
                    onChange={(e) => handleChangeValue(e)}
                  />
                </Form.Group>
              </div>

              <div className="delta-select-column">
                <label>Select Type </label>
                <Select
                  value={selectedType?.value ? selectedType : null}
                  placeholder="Select Role"
                  onChange={handleChangeRole}
                  options={rolesList}
                  className="delta-select"
                  classNamePrefix="delta-select"
                />
              </div>
              <div className="delta-select-column">
                <label>Select Status </label>
                <Select
                  value={selectedStatus?.value ? selectedStatus : null}
                  placeholder="Select Role"
                  onChange={handleChangeRole1}
                  options={rolesList1}
                  className="delta-select"
                  classNamePrefix="delta-select"
                />
              </div>
              <div className="download-icon-sec"></div>
              {/* <div className="delta-select-column submit-filter-btn-sec">
                  <button className="deltape-button">SUBMIT</button>
                </div> */}
            </div>
          </div>
          <br />
          <div>
            <div className="total-count-row"></div>
            <div className="institutes-filters"></div>
            <div className="all-Students-data deltape-table-view">
              <Table
                columns={columns}
                pagination={false}
                dataSource={allAssignments?.data}
                scroll={{ x: 1500, y: 500 }}
                rowClassName={(record, index) =>
                  `row_selected_priority_${record?.priority}`
                }
              />

              <br />
              <div className="delata-table-pagination">
                <Pagination
                  defaultCurrent={1}
                  current={currentPage}
                  total={allAssignments?.totalRecords}
                  onChange={(val) => {
                    pageonChange(val);
                  }}
                  pageSize={10}
                  showSizeChanger={false}
                  // size="small"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const UpdateBillingSection = () => {
    return (
      <>
        {" "}
        <div className="content getinTouchPage">
          <div>
            <div className="profileinfoHeader">
              <p>View All Bills</p>
            </div>
          </div>

          <div className="create-new-institute-sec-content-all">
            <div className="student-fields-sec-content-all deltape-form">
              <div className="student-info-row">
                <Form.Group className="delta-signup-md" controlId="email">
                  <Form.Label> Search</Form.Label>
                  <Form.Control
                    placeholder="Search: Name, Email, Phone"
                    // autoFocus
                    // isInvalid={errorData?.studentName}
                    name="studentName"
                    type="text"
                    value={searchText}
                    onChange={(e) => handleChangeValue(e)}
                  />
                </Form.Group>
              </div>

              <div className="delta-select-column">
                <label>Select Type </label>
                <Select
                  value={selectedType?.value ? selectedType : null}
                  placeholder="Select Role"
                  onChange={handleChangeRole}
                  options={rolesList}
                  className="delta-select"
                  classNamePrefix="delta-select"
                />
              </div>
              <div className="delta-select-column">
                <label>Select Status </label>
                <Select
                  value={selectedStatus?.value ? selectedStatus : null}
                  placeholder="Select Role"
                  onChange={handleChangeRole1}
                  options={rolesList1}
                  className="delta-select"
                  classNamePrefix="delta-select"
                />
              </div>
              <div className="download-icon-sec"></div>
              {/* <div className="delta-select-column submit-filter-btn-sec">
                <button className="deltape-button">SUBMIT</button>
              </div> */}
            </div>
          </div>
          <br />
          <div>
            <div className="total-count-row"></div>
            <div className="institutes-filters"></div>
            <div className="all-Students-data deltape-table-view">
              <Table
                columns={billingsColumns}
                pagination={false}
                dataSource={allBillings?.data}
                scroll={{ x: 1500, y: 500 }}
                rowClassName={(record, index) =>
                  `row_selected_priority_${record?.priority}`
                }
              />

              <br />
              <div className="delata-table-pagination">
                <Pagination
                  defaultCurrent={1}
                  current={currentPage}
                  total={allBillings?.totalRecords}
                  onChange={(val) => {
                    pageonChange(val);
                  }}
                  pageSize={10}
                  showSizeChanger={false}
                  // size="small"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SubCategoriesSection = () => {
    const list = [
      {
        name: "Sub Category Medicines",
        cname: "Generic ",
        image:
          "https://raphacure-public-images.s3.ap-south-1.amazonaws.com/generic+med.png",
      },
      {
        name: "Branded",
        came: "Category Name",
        image:
          "https://raphacure-public-images.s3.ap-south-1.amazonaws.com/Brand+Medic.png",
      },
    ];
    return (
      <>
        {" "}
        <div className="content getinTouchPage"></div>
      </>
    );
  };

  return (
    <>
      <IndexsStyled>
        <div className="freshbag-wrapper">
          {isLoading && <Loader />}
          <div className="rapha-tabs-sec">
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  label: "Generate Bill",
                  key: "1",
                  children: <GenerateBillingSection />,
                },
                {
                  label: "Track out Standing",
                  key: "2",
                  children: <UpdateBillingSection />,
                },
                {
                  label: "Update Billing",
                  key: "3",
                  children: <SubCategoriesSection />,
                },
              ]}
            />
          </div>

          <AddNewUserModel
            handleCancel={handleCancel}
            selectedUser={selectedUser}
            addUserSuccess={addUserSuccess}
            show={showAddNewUserModel}
            getAllUsersCall={getAllBillingsCall}
          />
          <CustomModal
            show={conformClosingModel}
            titleText={conformClosingModelText}
            deleteText="CONFIRM"
            cancelText="Cancel"
            doctorD={selectedDoctor}
            selectedAction={selectedAction}
            dynamicClassName={`error_message1`}
            onCancel={() => {
              setConformClosingModel(false);
              // history.push("/jobcardsummary");
            }}
            onDelete={() => {
              setConformClosingModel(false);
              handleSubmitBtnConform("");
              // history.push("/jobcardsummary");
            }}
            onRejectCall={(rText: any) => {
              setConformClosingModel(false);
              handleSubmitBtnConform(rText);
              // history.push("/jobcardsummary");
            }}
          />

          <CustomModal
            show={showSuccessMessage}
            titleText={showSuccessMessageText}
            deleteText="Ok"
            cancelText=""
            onDelete={() => {
              setShowSuccessMessage(false);
              handleCancel();
            }}
          />
        </div>
      </IndexsStyled>
    </>
  );
};

export default Billings;
