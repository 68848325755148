export const API_BASE = "/api";
export const GRAPH_BASE = "https://graph.facebook.com/v6.0";

// http://195.95.200.22:8080/focusnextrestservices/api/auth/signin

// http://195.95.200.22:8080/focusnextrestservices/api/getallleads
// [7:17 pm] Upendra Kumar Manike
// 65.0.75.211

let SERVER_IP_URL;
if (typeof window !== "undefined") {
  if (window.location.hostname === "localhost") {
    // SERVER_IP_URL = "http://localhost:4000/api";
    SERVER_IP_URL = "http://localhost:4001/api";
    // SERVER_IP_URL = "https://api.deltape.in/api";
  } else {
    // SERVER_IP_URL = "http://3.111.69.195:4001/api";
    SERVER_IP_URL = "https://api.deltape.in/api";
    // https://api.deltape.in/api/students/all
  }
}
const MIXPANEL_ID1 = "ef8786e1b4be644396cef0543f380841";
export const SERVER_IP = SERVER_IP_URL;
const map_url =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyDILWRZ3d-zi2xIjmATRtOUNkFJ8kEr4p0&libraries=places";
export const GOOGLE_MAP_URL = map_url;
export const MIXPANEL_ID = MIXPANEL_ID1;
export const admissionList = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];
export const dropOffReasonList = [
  {
    label: "Dropped during admission process",
    value: "Dropped during admission process",
  },
  {
    label: "Admisssion terminated(TC)",
    value: "Admisssion terminated(TC)",
  },
  {
    label: "Test Student",
    value: "Test Student",
  },
  {
    label: "Detained",
    value: "Detained",
  },
];

export const acadamicYearsList = [
  {
    label: "2021-2022",
    value: "2021-2022",
  },
  {
    label: "2022-2023",
    value: "2022-2023",
  },
  {
    label: "2023-2024",
    value: "2023-2024",
  },
  {
    label: "2025-2025",
    value: "2024-2025",
  },
];
export const classesList = [
  {
    label: "Class-I",
    value: "Class-I",
  },
  {
    label: "Class-II",
    value: "Class-II",
  },
  {
    label: "Class-III",
    value: "Class-III",
  },
  {
    label: "Class-IV",
    value: "Class-IV",
  },
  {
    label: "Class-V",
    value: "Class-V",
  },
  {
    label: "Class-VI",
    value: "Class-VI",
  },
  {
    label: "Class-VII",
    value: "Class-VII",
  },
  {
    label: "Class-VIII",
    value: "Class-VIII",
  },
  {
    label: "Class-IX",
    value: "Class-IX",
  },
  {
    label: "Class-X",
    value: "Class-X",
  },
  {
    label: "Class-XI",
    value: "Class-XI",
  },
  {
    label: "Class-XII",
    value: "Class-XII",
  },
];
