import styled from "styled-components";

export const ApplicationsStyled = styled.div`
  .no-results {
    color: rgb(89, 85, 85);
    margin-top: 100px;
    text-align: center;
    border-top: 1px solid #e2e2e2;
    padding-top: 45px;
  }
  .all-emi-plans-row-all {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  .all-emi-plans-row {
    box-shadow: 0px 8px 24px -6px #00000029;
    padding: 14px;
    cursor: pointer;
    border-radius: 12px 0px 0px 0px;
    margin-bottom: 16px;
    // display: flex;
    justify-content: space-between;
    // gap: 20px;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    > div {
      display: flex;
      justify-content: space-between;
      padding: 0px 10px;
    }
    .circle-number {
      background: #b1e6ee;
      width: 40px;
      height: 40px;
      display: block;
      border-radius: 50px;
      padding: 8px;
      padding-left: 9px;
    }
    .all-emi-plans-top-sec1 {
      p {
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 500;
        line-height: 19.36px;
        color: #8696a5;
      }
      h6 {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        // padding-top: 10px;
        margin-bottom: 5px;
      }
      .d-flex {
        gap: 16px;
      }
    }
    .all-emi-plans-top-sec2 {
      padding-top: 16px;
      width: 100%;
      display: block;
      > div {
        border: 1px solid #ebeef0;
        display: flex;
        justify-content: space-between;
        // padding: 16px 0px;
        border-radius: 8px;
        > div {
          width: 50%;
          text-align: left;
          padding: 12px;
          h5 {
            font-size: 16px;
            font-weight: 400;
            line-height: 19.36px;
            color: #666666;
          }
          h6 {
            font-size: 18px;
            font-weight: 500;
            line-height: 25.2px;
            color: #2f3031;
          }
        }
      }
      .emi-plans-top-sec-sub1 {
        border-right: 1px solid #ebeef0;
      }
    }
    .all-emi-plans-top-sec3 {
      margin-top: 10px;
      p {
        padding: 5px;
        background: #e4eafa;
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
        border-radius: 8px;
        span {
          color: #4a73dc;
          font-weight: 400;
        }
      }
    }
    .status-badge {
      display: inline-block;
      background-color: #8bc34a; /* Green background */
      color: white; /* Text color */
      font-family: "Arial", sans-serif; /* Font */
      font-size: 16px; /* Adjust font size */
      font-weight: bold; /* Bold text */
      padding: 8px 16px; /* Inner spacing */
      border-radius: 20px 20px 0 20px; /* Rounded corners */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
    }
  }
`;
