import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllLocationsByIId,
  getAllBoards,
  getAllAcademicYears,
  getAllApplications,
  createApplicationAPI,
  getApplicationDetailById,
  getAllApplicationsbyuser,
} from "./PlansandPaymentsService";

export interface leadManagementState {
  loading: boolean;
  error: any;
  allLocations: any;
  allAcadamicYears: any;
  allApplications: any;
  applicationDetails: any;
  userAllApplications: any;
}

const initialState: leadManagementState = {
  loading: false,
  error: null,
  allLocations: [],
  allAcadamicYears: [],
  allApplications: [],
  applicationDetails: {},
  userAllApplications: [],
};

export const PlansandPayments = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    // updateUserDetails: (
    //   state: leadManagementState,
    //   action: PayloadAction<boolean>
    // ) => {
    //   state.user = action.payload;
    // },
    updateErrorMessage: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
    updateApplicationDetails: (
      state: leadManagementState,
      action: PayloadAction<any>
    ) => {
      state.applicationDetails = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getAllLocationsByIId.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllLocationsByIId.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allLocations = action.payload;
      }
    );
    builder.addCase(
      getAllLocationsByIId.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.allLocations = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    builder.addCase(getAllBoards.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllBoards.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        // state.allAcadamicYears = action.payload;
      }
    );
    builder.addCase(
      getAllBoards.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        // state.allAcadamicYears = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    builder.addCase(getAllAcademicYears.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllAcademicYears.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allAcadamicYears = action.payload;
      }
    );
    builder.addCase(
      getAllAcademicYears.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.allAcadamicYears = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    builder.addCase(getAllApplications.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllApplications.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allApplications = action.payload?.data;
      }
    );
    builder.addCase(
      getAllApplications.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.allApplications = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //createApplicationAPI
    builder.addCase(createApplicationAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      createApplicationAPI.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.applicationDetails = action.payload;
      }
    );
    builder.addCase(
      createApplicationAPI.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.applicationDetails = {};
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //getApplicationDetailById
    builder.addCase(getApplicationDetailById.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getApplicationDetailById.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.applicationDetails = action.payload?.data;
      }
    );
    builder.addCase(
      getApplicationDetailById.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.applicationDetails = {};
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //getAllApplicationsbyuser
    builder.addCase(getAllApplicationsbyuser.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllApplicationsbyuser.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.userAllApplications = action.payload?.data;
      }
    );
    builder.addCase(
      getAllApplicationsbyuser.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.userAllApplications = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
  },
});

export const { setLoading, updateErrorMessage, updateApplicationDetails } =
  PlansandPayments.actions;

export default PlansandPayments.reducer;
