import React, { useEffect, useState } from "react";
import { InstitutesStyled } from "./Institutes.styled";
import { Table, Badge, Dropdown, Space, Pagination } from "antd";

import { Row, Col, Button, Form } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { getAllInstitutes } from "./../../redux/slices/InstituteManagement/InstituteManagementService";
import { triggerMixpanelEvent } from "../../Scenes/common";
import CreateApplication from "../../components/CreateApplication/CreateApplication";
const Institutes = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [showCreateAppli, setShowCreateAppli] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((state: any) => state?.auth);
  const { allInstitutes } = useSelector(
    (state: any) => state?.instituteManagement
  );
  console.log("allInstitutes", allInstitutes);

  useEffect(() => {
    triggerMixpanelEvent("Contact_Us");
    dispatch(getAllInstitutes());
  }, []);

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleCreatenewApp = () => {
    history.push("/institutesetup");
  };
  const instituteAction = (id: any, actionN: any) => {
    console.log("idid", id);
    if (actionN === "edit") {
      history.push(`/institutesetup/${id}`);
    }
  };
  const pageonChange = (page: any) => {
    console.log("page", page);
    setCurrentPage(page);
  };
  const items = [
    {
      key: "edit",
      label: <span className="drop-down-single-item-sec">Edit</span>,
    },
    {
      key: "view",
      label: <span className="drop-down-single-item-sec">View</span>,
    },

    {
      key: "download",
      label: <span className="drop-down-single-item-sec">Download</span>,
    },
    {
      key: "delete",
      label: <span className="drop-down-single-item-sec">Delete</span>,
    },
  ];
  const columns = [
    {
      title: "Name",
      width: 200,
      dataIndex: "instituteName",
      key: "0",
      fixed: "left",
    },
    // {
    //   title: "Ref No",
    //   width: 100,
    //   dataIndex: "RefNo",
    //   key: "1",
    // },

    {
      title: "Institute Code",
      dataIndex: "instituteCode",
      key: "2",
      width: 200,
    },

    {
      title: "Institute Location",
      dataIndex: "instituteLocation",
      key: "3",
      width: 200,
      render: (item: any) => {
        return <>{item}</>;
      },
    },

    {
      title: "Phone Number",
      dataIndex: "mobileNumber",
      key: "42",
      width: 200,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "4",
      width: 200,
    },
    {
      title: "Pan No",
      dataIndex: "panNo",
      key: "2",
      width: 200,
    },

    {
      title: "Established Year",
      dataIndex: "establishedYear",
      key: "5",
      width: 150,
    },

    {
      title: "Created",
      dataIndex: "createdAt",
      key: "7",
      width: 150,
      render: (item: any) => {
        return <>{moment(item).format("DD/MM/YYYY")}</>;
      },
    },
    { title: "Age", dataIndex: "age", key: "8", width: 70 },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 90,
      dataIndex: "_id",
      render: (itemId: any) => {
        console.log("item", itemId);

        return (
          <Space size="middle">
            <Dropdown
              placement="topLeft"
              className="menu-items-sec-all"
              menu={{
                items,
                onClick: ({ key }) => {
                  instituteAction(itemId, key);
                },
              }}
            >
              <span className="hover-menu-item-sec">...</span>
            </Dropdown>
          </Space>
        );
      },
    },
  ] as any;

  return (
    <>
      <InstitutesStyled>
        <div className="freshbag-wrapper">
          <div className="content getinTouchPage">
            <div className="contact">
              <div className=" d-flex space-between">
                <div className="col-md-6">
                  <h2>All Institutes</h2>
                  <p>View All Institutes and download reports</p>
                </div>
                <div className="create-new-sec">
                  <button
                    className="deltape-button"
                    onClick={handleCreatenewApp}
                  >
                    CREATE NEW INSTITUTE
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className="institutes-filters"></div>
              <div className="all-institutes-data deltape-table-view">
                <Table
                  columns={columns}
                  pagination={false}
                  dataSource={allInstitutes}
                  scroll={{ x: 1500, y: 500 }}
                  rowClassName={(record, index) =>
                    `row_selected_priority_${record?.priority}`
                  }

                  // total={50}
                />
                {/* <Pagination defaultCurrent={1} total={50} /> */}
                {/* noOfPages */}
                <div className="delata-table-pagination">
                  <Pagination
                    defaultCurrent={1}
                    current={currentPage}
                    total={allInstitutes?.length}
                    onChange={(val) => {
                      pageonChange(val);
                    }}
                    pageSize={10}
                    showSizeChanger={false}
                    // size="small"
                  />
                </div>
              </div>
            </div>
          </div>
          {allInstitutes?.length === 0 && (
            <div className="no-results">
              <p>No Institutes available</p>
            </div>
          )}
        </div>
      </InstitutesStyled>
    </>
  );
};

export default Institutes;
