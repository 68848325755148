import React from "react";
import { Modal, Button } from "react-bootstrap";
import CreateStudent from "./../CreateStudent";

const EditStudentDetails = (props: any) => {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="create-student-model-box"
    >
      <Modal.Body
        className={`modalBodyDefault alert-model-popup-sec dynamic_class_${props?.dynamicClassName}`}
      >
        <CreateStudent {...props} isEdit={true} />
      </Modal.Body>
    </Modal>
  );
};

export default EditStudentDetails;
