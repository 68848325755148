import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  signUpUser,
  addNewUserData,
} from "../../redux/slices/auth/authService";
import { useHistory, useLocation } from "react-router-dom";
import { GOOGLE_MAP_URL } from "../../config";
import CustomModal from "../../components/CustomModel";
import { triggerMixpanelEvent } from "../../Scenes/common";

// import "./styles.css";
const initVal = {
  firstName: undefined,
  lastName: undefined,
  mobileNo: undefined,
  username: undefined,
  adharCardNo: undefined,
  userType: undefined,
  password: undefined,
  conformPassword: undefined,
};

const initValError = {
  firstName: undefined,
  lastName: undefined,
  mobileNo: undefined,
  username: undefined,
  userType: undefined,
  password: undefined,
  conformPassword: undefined,
};

const initValError1 = {
  firstName: undefined,
  lastName: undefined,
  mobileNo: undefined,
  username: undefined,
  userType: undefined,
};

const initValErrorData = {
  firstName: "Please Enter First name",
  lastName: "Please Enter Last name",
  mobileNo: "Please Enter Phone number",
  username: "Please Enter valid email",
  password: "Please Enter Password",
  conformPassword: "Please Enter Confirm Password",
};

const AddNewUser = (props) => {
  const { selectedData, addUserSuccess, onHide } = props;
  const [signUpData, setSignUpData] = useState(initVal);
  const [errorData, setErrorData] = useState(initValError);
  console.log("errorData", errorData);
  const [errorDataValue, setErrorDataValue] = useState(initValErrorData);
  const { error, loading, user } = useSelector(({ auth }) => auth);
  const history = useHistory();
  const [passError, setPassError] = useState(null);
  const [showSuccessUserPopup, setShowSuccessUserPopup] = useState(false);
  const [errorMessage, errorErrorMessage] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.data?._id) {
      history.push("/signin");
    }
  }, [user]);

  useEffect(() => {
    if (selectedData?.id) {
      const prevVJson = { ...signUpData };
      prevVJson.id = selectedData?.id;
      prevVJson.firstName = selectedData?.firstName;
      prevVJson.lastName = selectedData?.lastName;
      prevVJson.adharCardNo = selectedData?.adharCardNo;
      prevVJson.mobileNo = selectedData?.mobileNo;
      prevVJson.username = selectedData?.username;
      prevVJson.userType = selectedData?.userType;
      setSignUpData(prevVJson);
    }
  }, [selectedData]);

  const handleChangeValue = (e) => {
    setPassError("");
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };
    if (
      name == "mobileNo" ||
      name == "officeNo" ||
      name == "storeMobileNo" ||
      name == "contactNumber"
    ) {
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        setSignUpData({
          ...addr,
          [name]: value1,
        });
        if (value1?.length > 9) {
          setErrorData({
            ...addr_error,
            [name]: false,
          });
        }
      }
    } else if (
      name === "username" ||
      name == "officeEmail" ||
      name == "storeEmail" ||
      name == "email"
    ) {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRegex.test(String(value).toLowerCase())) {
        setErrorData({
          ...addr_error,
          [name]: false,
        });
      } else {
        setErrorData({
          ...addr_error,
          [name]: true,
        });
      }
    } else {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      setErrorData({
        ...addr_error,
        [name]: false,
      });
    }
  };
  const createAccountData = () => {
    setPassError(null);
    var isValid = true;
    var errornew = { ...errorData };
    for (var key in initValError1) {
      const pValue = signUpData[key];
      if (
        signUpData[key] === "Select" ||
        signUpData[key] === "" ||
        !signUpData[key]
      ) {
        errornew[key] = true;
        isValid = false;
      }
      if (key === "contactNumber" && pValue?.length < 10) {
        errornew[key] = true;
        isValid = false;
      }
    }
    console.log("errornew", errornew);
    setErrorData({ ...errornew });
    if (!selectedData?.id) {
      if (
        signUpData?.password &&
        signUpData?.conformPassword &&
        signUpData?.password !== signUpData?.conformPassword
      ) {
        isValid = false;
        setPassError(
          "Password and Confirm Password fields value must be matched."
        );
      } else if (signUpData?.password && signUpData?.password?.length < 6) {
        isValid = false;
        setPassError("Password requires 6 characters minimum.");
      }
    }
    if (isValid) {
      makeSignUpCall(signUpData);
      console.log("signUpData 444", signUpData);
    }
  };
  const makeSignUpCall = async (data) => {
    const resp = await dispatch(addNewUserData(data));
    console.log("vinodddd", resp);
    if (resp?.payload?.id) {
      onHide();
      triggerMixpanelEvent("Add_user_success");
      addUserSuccess();
    } else if (resp?.error?.message) {
      errorErrorMessage(resp?.error?.message);
    }
  };
  const hideModel = () => {
    addUserSuccess(selectedData?.id);
    setShowSuccessUserPopup(false);
  };
  // const validationCheck = () => {
  //   var formValid = false;
  //   console.log("signUpData", signUpData);
  //   console.log("errorData", errorData);
  //   for (var key in errorData) {
  //     if (signUpData[key] === "" || !signUpData[key]) {
  //       formValid = true;
  //     }
  //   }
  //   return formValid;
  // };
  // const userRoles = [
  //   { label: "ADMIN", value: "ADMIN" },
  //   { label: "SUPER_ADMIN", value: "SUPER ADMIN" },
  //   { label: "ENGINEER", value: "ENGINEER" },
  //   { label: "FREELANCER", value: "FREELANCER" },
  // ];
  const userRoles = [
    { label: "Admin", value: "Admin" },
    { label: "Manager", value: "Manager" },
    { label: "Coordinator", value: "Coordinator" },
    { label: "Engineer", value: "Engineer" },
    { label: "Freelancer", value: "Freelancer" },
  ];

  // Manager
  // Coordinator
  // Engineer
  // Freelancer

  console.log("signUpData final", JSON.stringify(signUpData));
  console.log("errorData?.email", errorData);
  return (
    <div className="SignupContainer-signup SignupContainer-signup-add-new-user">
      <Form.Group className="signup-md" size="lg" controlId="email">
        <Form.Label>
          First Name <span className="mand-sign-field">*</span>{" "}
        </Form.Label>
        <Form.Control
          autoFocus
          name="firstName"
          type="text"
          value={signUpData?.firstName}
          onChange={(e) => handleChangeValue(e)}
          isInvalid={errorData?.firstName}
        />
        {/* <Form.Control.Feedback type="invalid">
          {errorData?.firstName ? <>{errorDataValue?.firstName}</> : null}
        </Form.Control.Feedback> */}
      </Form.Group>
      <Form.Group className="signup-md-left" size="lg" controlId="email">
        <Form.Label>
          Last Name <span className="mand-sign-field">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="lastName"
          value={signUpData?.lastName}
          onChange={(e) => handleChangeValue(e)}
          isInvalid={errorData?.lastName}
        />
        {/* <Form.Control.Feedback type="invalid">
          {errorData?.lastName ? <>{errorDataValue?.lastName}</> : null}
        </Form.Control.Feedback> */}
      </Form.Group>
      <Form.Group className="signup-md" size="lg" controlId="email">
        <Form.Label>
          Phone Number <span className="mand-sign-field">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          maxLength={10}
          name="mobileNo"
          value={signUpData?.mobileNo}
          isInvalid={errorData?.mobileNo}
          onChange={(e) => handleChangeValue(e)}
        />
        {/* <Form.Control.Feedback type="invalid">
          {errorData?.mobileNo ? <>{errorDataValue?.mobileNo}</> : null}
        </Form.Control.Feedback> */}
      </Form.Group>
      <Form.Group className="signup-md-left" size="lg" controlId="email">
        <Form.Label>
          Email <span className="mand-sign-field">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="username"
          value={signUpData?.username}
          isInvalid={errorData?.username}
          onChange={(e) => handleChangeValue(e)}
        />
        {/* <Form.Control.Feedback type="invalid">
          {errorData?.username ? <>{errorDataValue?.username}</> : null}
        </Form.Control.Feedback> */}
      </Form.Group>

      <Form.Group className="signup-md" size="lg" controlId="email">
        <Form.Label>Adhar No</Form.Label>
        <Form.Control
          type="text"
          maxLength={10}
          name="adharCardNo"
          value={signUpData?.adharCardNo}
          onChange={(e) => handleChangeValue(e)}
        />
        <Form.Control.Feedback type="invalid">
          {errorData?.adharCardNo ? <>{errorDataValue?.adharCardNo}</> : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="signup-md-left" size="lg" controlId="role">
        <Form.Label>Select Role </Form.Label>

        {selectedData?.userType == "STORE_OWNER" ||
        selectedData?.userType == "Owner" ? (
          <Form.Control
            type="text"
            maxLength={10}
            disabled={true}
            value={signUpData?.userType}
          />
        ) : (
          <Form.Control
            name="userType"
            // isInvalid={x.cityError}
            value={signUpData?.userType}
            onChange={(e) => handleChangeValue(e)}
            isInvalid={errorData?.userType}
            as="select"
          >
            <option name="" value="">
              Select
            </option>
            {(userRoles || []).map((item, key) => {
              return (
                <>
                  <option
                    selected={
                      item?.value === signUpData?.userType ? true : false
                    }
                    value={item?.value}
                  >
                    {item?.label}
                  </option>
                </>
              );
            })}
          </Form.Control>
        )}
        <Form.Control.Feedback type="invalid">
          {errorData?.userType ? <>{errorDataValue?.userType}</> : null}
        </Form.Control.Feedback>
      </Form.Group>
      {!selectedData?.id && (
        <div className="password-fields-sec">
          <Row>
            <Form.Group className="signup-md" size="lg" controlId="password">
              <Form.Label>
                Password<span className="mand-sign-field">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                name="password"
                isInvalid={errorData?.password}
                value={signUpData?.password}
                onChange={(e) => handleChangeValue(e)}
              />
              {/* <Form.Control.Feedback type="invalid">
                {errorData?.password ? <>{errorDataValue?.password}</> : null}
              </Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group
              className="signup-md-left"
              size="lg"
              controlId="password"
            >
              <Form.Label>
                Confirm Password<span className="mand-sign-field">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                name="conformPassword"
                isInvalid={errorData?.conformPassword}
                value={signUpData?.conformPassword}
                onChange={(e) => handleChangeValue(e)}
              />
            </Form.Group>
          </Row>
        </div>
      )}

      {passError && <p className="errorMessage">{passError}</p>}
      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
      {error && !passError && <p className="errorMessage">{error}</p>}

      <div className="create-btn-full-width">
        <div className="create-account-action-con">
          <button
            // disabled={validationCheck()}
            onClick={() => {
              createAccountData();
            }}
            block
            size="lg"
            type="submit"
            className="create-account-btn-sec"
          >
            {loading
              ? "Loading"
              : selectedData?.id
              ? "Update User"
              : "Add User"}
          </button>
        </div>
      </div>
      <CustomModal
        show={showSuccessUserPopup}
        titleText="You have Successfully Created User."
        deleteText="ok"
        cancelText=""
        onCancel={() => hideModel()}
        onDelete={() => hideModel()}
      />
    </div>
  );
};

export default AddNewUser;
