import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createInstituteAPI,
  getAllInstitutes,
  getInstituteDetailsAPI,
} from "./InstituteManagementService";

export interface leadManagementState {
  loading: boolean;
  error: any;
  instituteDetails: any;
  allInstitutes: any;
}

const initialState: leadManagementState = {
  loading: false,
  error: null,
  instituteDetails: [],
  allInstitutes:[],
};

export const InstituteManagement = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    // updateUserDetails: (
    //   state: leadManagementState,
    //   action: PayloadAction<boolean>
    // ) => {
    //   state.user = action.payload;
    // },
    updateErrorMessage: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(createInstituteAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      createInstituteAPI.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.instituteDetails = action.payload?.data;
      }
    );
    builder.addCase(
      createInstituteAPI.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.instituteDetails = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    builder.addCase(getInstituteDetailsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getInstituteDetailsAPI.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.instituteDetails = action.payload?.data;
      }
    );
    builder.addCase(
      getInstituteDetailsAPI.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.instituteDetails = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    

    //getAllInstitutes
    builder.addCase(getAllInstitutes.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllInstitutes.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allInstitutes = action.payload?.data;
      }
    );
    builder.addCase(
      getAllInstitutes.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.allInstitutes = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
  },
});

export const { setLoading, updateErrorMessage } = InstituteManagement.actions;

export default InstituteManagement.reducer;
