export const assignmentTypes = [
  { label: "Bank Audit", value: "Bank Audit" },
  { label: "Book Keeping", value: "Book Keeping" },
  { label: "ESI", value: "ESI" },
  { label: "GST", value: "GST" },
  { label: "TDS", value: "TDS" },
  { label: "Internal Audit", value: "Internal Audit" },
  { label: "PF", value: "PF" },
  { label: "Statutory Audit", value: "Statutory Audit" },
  { label: "Stock Audit", value: "Stock Audit" },
  { label: "PT", value: "PT" },
];

export const statusTypes = [
  { label: "Done", value: "Done" },
  { label: "In-Progress", value: "In-Progress" },
  { label: "Need Clarification", value: "Need Clarification" },
  { label: "Partly Cleared", value: "Partly Cleared" },
  { label: "Yet to Start", value: "Yet to Start" },
];

export const priorityList = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];
