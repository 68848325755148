import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Dropdown } from "react-bootstrap";
import Select from "react-select";
import {
  getAllLocationsByIId,
  getAllBoards,
  getAllAcademicYears,
  getAllApplications,
  getAllApplicationsbyuser,
  createApplicationAPI,
  updateApplicationAPI,
  getApplicationDetailById,
} from "../../../redux/slices/PlansandPayments/PlansandPaymentsService";

import {
  getInstituteDetailsAPI,
  getAllInstitutes,
} from "../../../redux/slices/InstituteManagement/InstituteManagementService";
import { DatePicker, Space } from "antd";

import {
  getStudentDetailsByRollNumberAPI,
  getPancardDetailsById,
  getAadhaarDetailsById,
} from "../../../redux/slices/StudentManagement/StudentManagementService";
import { acadamicYearsList, classesList } from "../../../config";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import salaried_img from "../../../img/delta/salaried_img.png";
import info_img from "../../../img/delta/info.png";
import edit_img from "../../../img/delta/edit.png";
import briefcase_img from "../../../img/delta/briefcase.png";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "../../../Scenes/common";
import { id } from "date-fns/locale";
import Loader from "../../../components/Common/Loader";
const initValStore = {
  monthlyIncome: undefined,
  companyName: undefined,
};
const initValError = {
  monthlyIncome: undefined,
  companyName: undefined,
};
const errorDataValue = {
  monthlyIncome: "Please Enter Monthly Income",
  companyName: "Please Enter Company Name",
};
const EMIPlans = (props: any) => {
  const { currentStep, setCurrentStep, applicationId } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [instituteerrorMessage, setInstituteErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [allInstitutesList, setAllInstitutesList] = useState([] as any);
  const [allLocationsList, setAllLocationsList] = useState([] as any);
  // const [instituteValue, setInstituteValue] = useState({} as any);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [locationValue, setLocationValue] = useState({} as any);
  const [boardValue, setBoardValue] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [academicClass, setAcademicClass] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [pancardDetails, setPancardDetails] = useState({} as any);
  const [aadhaarDetails, setAadhaarDetails] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null as any);
  const [agreeCheckBox, setAgreeCheckBox] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [empType, setEmpType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [errorData, setErrorData] = useState(initValError as any);
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((authR: any) => authR?.auth);
  const { allInstitutes, instituteDetails } = useSelector(
    (authR: any) => authR?.instituteManagement
  );
  const { applicationDetails, userAllApplications } = useSelector(
    (authR: any) => authR?.plansandPayments
  );
  console.log("instituteDetails", instituteDetails);
  console.log("applicationDetails", applicationDetails);
  console.log("userAllApplications", userAllApplications);
  useEffect(() => {
    if (applicationId) {
      dispatch(getApplicationDetailById(applicationId));
    }
  }, []);
  useEffect(() => {
    console.log("allInstitutes", allInstitutes);
    const allINi = [] as any;
    allInstitutes.map((iniItem: any) => {
      allINi.push({
        label: iniItem?.instituteName,
        value: iniItem?._id,
      });
    });
    setAllInstitutesList(allINi);
  }, [allInstitutes]);
  useEffect(() => {
    // setCurrentStep("2");
    if (applicationDetails?._id) {
      const addr = { ...signUpData };

      setSignUpData({
        ...addr,
        empType: applicationDetails?.empType,
        monthlyIncome: applicationDetails?.monthlyIncome,
        companyName: applicationDetails?.companyName,
      });
      setEmpType(applicationDetails?.empType);
    }
  }, [applicationDetails]);

  useEffect(() => {
    const allINi = [] as any;
    const finalObj = {
      label: instituteDetails?.instituteLocation,
      value: instituteDetails?.instituteLocation,
    };
    allINi.push(finalObj);

    setAllLocationsList(allINi);
    handleChangeLocation(finalObj);
  }, [instituteDetails]);

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleChange = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setInstituteValue(selectedOption);
    // dispatch(getAllLocationsByIId({ instituteId: 233 }));
    setLocationValue({});
    setAcademicYear({});
    dispatch(getInstituteDetailsAPI(selectedOption?.value));
  };
  const handleChangeLocation = (selectedOption: any) => {
    console.log("selectedOption", selectedOption);
    setInstituteErrorMessage("");
    setLocationValue(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
    setAcademicYear({});
  };
  const handleChangeBoard = (selectedOption: any) => {
    setBoardValue(selectedOption);
  };
  const handleChangeYear = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setAcademicYear(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
  };
  const handleChangeClass = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setAcademicClass(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
  };

  const handleChangeValue = async (e: any) => {
    setInstituteErrorMessage("");
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    if (name === "feeAmount") {
      const value1 = e.target.value;
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        setSignUpData({
          ...addr,
          [name]: value1,
        });
        setErrorData({
          ...addr_error,
          [name]: false,
        });
      }
    } else {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      setErrorData({
        ...addr_error,
        [name]: false,
      });
    }
  };
  console.log("signUpData", signUpData);
  console.log("academicClass?.value", academicClass?.value);

  const handleValidate = () => {
    setShowErrorMessage(false);
    setErrorMessage("");
    var isValid = true;
    var errornew = { ...initValError } as any;
    for (var key in errornew) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    setErrorData({ ...errornew });
    return isValid;
  };
  const handleProceed = async () => {
    // if (empType) {
    //   if (handleValidate()) {
    //     const body = {
    //       ...signUpData,
    //       empType: empType,
    //       _id: applicationId,
    //     };
    //     console.log("body", body);
    //     setIsLoading(true);
    //     const resp = (await dispatch(updateApplicationAPI(body))) as any;
    //     setIsLoading(false);
    //     if (resp?.payload?.success) {
    //       // getAppDetailsIdCall();
    //       props?.nextStep();
    //     } else {
    //       setErrorMessage("Please enter valid details!");
    //     }
    //   } else {
    //     setErrorMessage("Please Enter Valid Address Details.");
    //   }
    // } else {
    //   setErrorMessage("Please select Employment Type");
    // }
    props?.nextStep();
  };
  const onChangeDate = (datev: any) => {
    // setErrorMessage("");
    const date2 = new Date(datev);
    console.log("date2", date2);
    console.log("onChange", datev);
    const datav = moment(datev || new Date()).format("YYYY-MM-DD");
    console.log("datav", datav);
    setSelectedDate(datav);
    console.log("allInstitutesList", allInstitutesList);
  };
  const aggreCheckBox = (e: any) => {
    const value1 = e.target.checked;
    setAgreeCheckBox(value1);
  };
  const selectEmptype = (val: any) => {
    setEmpType(val);
    console.log("valval", val);
  };
  console.log("empType", empType);
  return (
    <>
      <div className="create-new-application-sec user-preview-sec">
        {isLoading && <Loader />}
        <>
          <div>
            <div className="user-emi-plan-top">
              <h3>Confirm All Details</h3>
              <p>Confirm All Details Step by Step</p>
            </div>
            <div>
              <div className="d-flex1">
                <div>
                  <img src={info_img} />
                </div>
                <p>
                  Please review your details carefully before submitting. Once
                  you click “Confirm and Submit” you won’t be able to make any
                  changes.
                </p>
              </div>
            </div>
            <div className="app-preview-row">
              <div className="app-preview-top">
                <div>
                  <img src={briefcase_img} /> Student Details
                </div>
                <div>
                  <img
                    onClick={() => props?.prevStep(1)}
                    className="edit-img"
                    src={edit_img}
                  />
                </div>
              </div>
              <div className="app-preview-text">
                <div className="all-fields-rows">
                  <div className="all-fields-row">
                    <p className="key">School / College / University Name</p>
                    <p className="value">{applicationDetails?.instituteName}</p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Location</p>
                    <p className="value">{applicationDetails?.locationId}</p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Academic Year</p>
                    <p className="value">{applicationDetails?.academicYear}</p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Board/Class/Stream</p>
                    <p className="value">{applicationDetails?.classId}</p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Student First Name</p>
                    <p className="value">
                      {applicationDetails?.studentFirstName}
                    </p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Student Last Name</p>
                    <p className="value">
                      {applicationDetails?.studentLastName}
                    </p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Admission Type</p>
                    <p className="value">{applicationDetails?.admissionType}</p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Student ID </p>
                    <p className="value">{applicationDetails?.studentId}</p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Annual Fee</p>
                    <p className="value">Rs. {applicationDetails?.feeAmount}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="app-preview-row">
              <div className="app-preview-top">
                <div>
                  <img src={briefcase_img} /> EMI Plan Details
                </div>
                <div>
                  <img
                    onClick={() => props?.prevStep(2)}
                    className="edit-img"
                    src={edit_img}
                  />
                </div>
              </div>
              <div className="app-preview-text">
                <div className="all-fields-rows">
                  <div className="all-fields-row">
                    <p className="key">EMI Amount</p>
                    <p className="value">₹ {applicationDetails?.emiAmount}</p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Interest Amount per Month</p>
                    <p className="value"> Variable interest</p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Tenure (in months)</p>
                    <p className="value">
                      {applicationDetails?.emiSelectedPlan}
                    </p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Payable Today</p>
                    <p className="value">₹ {applicationDetails?.emiAmount}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="app-preview-row">
              <div className="app-preview-top">
                <div>
                  <img src={briefcase_img} /> Parent Information
                </div>
                <div>
                  <img
                    onClick={() => props?.prevStep(3)}
                    className="edit-img"
                    src={edit_img}
                  />
                </div>
              </div>
              <div className="app-preview-text">
                <div className="all-fields-rows">
                  <div className="all-fields-row">
                    <p className="key">PAN Number</p>
                    <p className="value">{applicationDetails?.panNo}</p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Date of Birth</p>
                    <p className="value">{applicationDetails?.dateOfBirth}</p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Parents First Name</p>
                    <p className="value">
                      {applicationDetails?.pancardFirstName}
                    </p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Parents Last Name</p>
                    <p className="value">
                      {applicationDetails?.pancardLastName}
                    </p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Gender</p>
                    <p className="value">{applicationDetails?.parentGender}</p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Marital Status</p>
                    <p className="value">{applicationDetails?.maritalStatus}</p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Email Address</p>
                    <p className="value">{applicationDetails?.email}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="app-preview-row">
              <div className="app-preview-top">
                <div>
                  <img src={briefcase_img} /> Residential Details
                </div>
                <div>
                  <img
                    onClick={() => props?.prevStep(4)}
                    className="edit-img"
                    src={edit_img}
                  />
                </div>
              </div>
              <div className="app-preview-text">
                <div className="all-fields-rows">
                  <div className="all-fields-row">
                    <p className="key">Current Address</p>
                    <p className="value">
                      {" "}
                      {applicationDetails?.address1?.line1},
                      {applicationDetails?.address1?.line2},
                      {applicationDetails?.address1?.street},
                      {applicationDetails?.address1?.city},
                      {applicationDetails?.address1?.state},
                      {applicationDetails?.address1?.pincode}
                    </p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Resident Type</p>
                    <p className="value">{applicationDetails?.residentType}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="app-preview-row">
              <div className="app-preview-top">
                <div>
                  <img src={briefcase_img} /> Employment Details
                </div>
                <div>
                  <img
                    onClick={() => props?.prevStep(5)}
                    className="edit-img"
                    src={edit_img}
                  />
                </div>
              </div>
              <div className="app-preview-text">
                <div className="all-fields-rows">
                  <div className="all-fields-row">
                    <p className="key">Employment Type</p>
                    <p className="value"> {applicationDetails?.empType}</p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Full Name of current Company</p>
                    <p className="value">{applicationDetails?.companyName}</p>
                  </div>
                  <div className="all-fields-row">
                    <p className="key">Salary per Month</p>
                    <p className="value">{applicationDetails?.monthlyIncome}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {instituteerrorMessage && (
            <p className="error_message text_left">{instituteerrorMessage}</p>
          )}
        </>
        {/* {empType && ( */}
        <div className="actions-btn-sec">
          <div className="deltape-action-btns">
            <button onClick={props?.prevStep} className="cancel-btn">
              Back
            </button>
            <button onClick={handleProceed} className="confirm-btn">
              Proceed
            </button>
          </div>
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default EMIPlans;
