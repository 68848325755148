import homeicon from "./../../img/house-black-silhouette-without-door.png";

export const userMenuLinks = [
  {
    title: "Profile",
    id: "Profile",
    icon: homeicon,
    sublinks: [],
    link: "/dashboard/Profile",
  },
  {
    title: "Dashboard",
    id: "Dashboard",
    icon: homeicon,
    sublinks: [],
    link: "/customerDashboard",
  },

  {
    title: "Applications",
    id: "Applications",
    icon: homeicon,
    sublinks: [],
    link: "/dashboard/Applications",
  },
  {
    title: "Transactions",
    id: "PlansandPayments",
    icon: homeicon,
    sublinks: [],
    link: "/dashboard/transactions",
  },
];
export const adminMenuLinks = [
  // {
  //   title: "Profile",
  //   id: "Profile",
  //   icon: homeicon,
  //   sublinks: [],
  //   link: "/dashboard/Profile",
  // },
  {
    title: "Dashboard",
    id: "Dashboard",
    icon: homeicon,
    sublinks: [],
    link: "/adminDashboard",
  },
  {
    title: "Institutes",
    id: "Institutes",
    icon: homeicon,
    sublinks: [],
    link: "/Institutes",
  },
  {
    title: "Students",
    id: "Students",
    icon: homeicon,
    sublinks: [],
    link: "/Students",
  },

  {
    title: "Transactions",
    id: "PlansandPayments",
    icon: homeicon,
    sublinks: [],
    link: "/dashboard/transactions",
  },
  // {
  //   title: "Delta Zero",
  //   id: "DeltaZero",
  //   icon: homeicon,
  //   sublinks: [],
  //   link: "/dashboard/DeltaZero",
  // },
  // {
  //   title: "Delta Auto",
  //   id: "DeltaAuto",
  //   icon: homeicon,
  //   link: "/dashboard/DeltaAuto",
  // },
  // {
  //   title: "Delta Pay",
  //   id: "DeltaPay",
  //   icon: homeicon,
  //   link: "/dashboard/DeltaPay",
  // },
  // {
  //   title: "Reports",
  //   id: "Reports",
  //   icon: homeicon,
  //   sublinks: [],
  //   link: "/Reports",
  // },
  {
    title: "Manage Users",
    id: "manageUsers",
    icon: homeicon,
    sublinks: [],
    link: "/manageUsers",
  },
];
export const instituteMenuLinks = [
  {
    title: "Dashboard",
    id: "Dashboard",
    icon: homeicon,
    sublinks: [],
    link: "/myDashboard",
  },
  {
    title: "Clients",
    id: "Clients",
    icon: homeicon,
    sublinks: [],
    link: "/Clients",
  },
  {
    title: "Assignments",
    id: "Assignments",
    icon: homeicon,
    sublinks: [],
    link: "/Assignments",
  },
  {
    title: "Billing",
    id: "Billings",
    icon: homeicon,
    sublinks: [],
    link: "/billings",
  },
  {
    title: "Reports",
    id: "Reports",
    icon: homeicon,
    sublinks: [],
    link: "/Reports",
  },
  {
    title: "Manage Users",
    id: "manageUsers",
    icon: homeicon,
    sublinks: [],
    link: "/manageUsers",
  },
];
