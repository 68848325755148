import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  loginUser,
  signUpUser,
  validateOTP,
  getAllUsersList,
  addNewUser,
  getStoreDetails,
  getCustomerSearch,
  getAllCustomersList,
  getCustomerDetails,
  getPrintSettingByIdApi,
  updatePrintSettingApi,
} from "./authService";

export interface AuthState {
  loading: boolean;
  user: any;
  error: any;
  signUpResponse: any;
  addNewUserResponse: any;
  allUsersList: any;
  loginStoreDetails: any;
  searchUsersList: any;
  allCustomersList: any;
  selectedCustomerDetails: any;
  getPrintSettingsResp: any;
  isParentSite: any;
  isAdminSite: any;
  alertModelBox: any;
}
const userData: string | null = localStorage.getItem("user");
const initialState: AuthState = {
  loading: false,
  user: userData !== null ? JSON.parse(userData) : {},
  error: null,
  signUpResponse: {},
  addNewUserResponse: {},
  allUsersList: [],
  loginStoreDetails: {},
  searchUsersList: [],
  allCustomersList: [],
  selectedCustomerDetails: {},
  getPrintSettingsResp: {},
  isParentSite: false,
  isAdminSite: false,
  alertModelBox: {},
};

export const authSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state: AuthState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateUserDetails: (state: AuthState, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    updateErrorMessage: (state: AuthState, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    updateIsParentSiteOnly: (state: any, action: any) => {
      state.isParentSite = action.payload;
    },
    updateIsAdminSiteOnly: (state: any, action: any) => {
      state.isAdminSite = action.payload;
    },
    updateAlertModelBox: (state: any, action: PayloadAction<any>) => {
      state.alertModelBox = action.payload;
    },

    updateAddNewUserResponse: (
      state: AuthState,
      action: PayloadAction<any>
    ) => {
      state.addNewUserResponse = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(loginUser.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      loginUser.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        // console.log("action", action);
        state.user = action.payload;
        var userInfo = action.payload;

        localStorage.setItem("user", JSON.stringify(userInfo));
      }
    );
    builder.addCase(loginUser.rejected, (state: AuthState, action: any) => {
      state.loading = false;
      console.log("action?.error?.message", action?.error?.message);
      state.user = {};
      state.error = action?.error?.message
        ? action?.error?.message
        : "something went wrong, Please try with valid user name and password";
      var userInfo = { _id: "54235345345r34", email: "vinod@gamil.com" };
      state.user = userInfo;
      localStorage.setItem("user", JSON.stringify(userInfo));
    });

    builder.addCase(signUpUser.pending, (state: any) => {
      state.loading = true;
      state.error = null;
      state.signUpResponse = null;
    });
    builder.addCase(
      signUpUser.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        // state.signUpResponse = action.payload;
        // state.user = action.payload;
        // var userInfo = action.payload;

        // localStorage.setItem("user", JSON.stringify(userInfo));
      }
    );
    builder.addCase(signUpUser.rejected, (state: AuthState, action: any) => {
      console.log({ action });
      state.loading = false;
      state.signUpResponse = null;
      state.error = action.error?.message ?? "Something went wrong";
    });
    //addNewUser
    builder.addCase(addNewUser.pending, (state: any) => {
      state.loading = true;
      state.error = null;
      state.addNewUserResponse = null;
    });
    builder.addCase(
      addNewUser.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.addNewUserResponse = action.payload;
      }
    );
    builder.addCase(addNewUser.rejected, (state: AuthState, action: any) => {
      console.log({ action });
      state.loading = false;
      state.addNewUserResponse = null;
      state.error = action.error?.message ?? "Something went wrong";
    });
    //getAllUsersList

    builder.addCase(getAllUsersList.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllUsersList.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allUsersList = action.payload;
      }
    );
    builder.addCase(
      getAllUsersList.rejected,
      (state: AuthState, action: any) => {
        console.log({ action });
        state.loading = false;
      }
    );

    //getStoreDetails
    builder.addCase(getStoreDetails.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getStoreDetails.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        var userInfo = action.payload?.data;
        state.loginStoreDetails = userInfo;
        state.user = userInfo;
        localStorage.setItem("user", JSON.stringify(userInfo));
      }
    );
    builder.addCase(
      getStoreDetails.rejected,
      (state: AuthState, action: any) => {
        state.loading = false;
      }
    );
    //getCustomerSearch
    builder.addCase(getCustomerSearch.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getCustomerSearch.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.searchUsersList = action.payload;
      }
    );
    builder.addCase(
      getCustomerSearch.rejected,
      (state: AuthState, action: any) => {
        state.loading = false;
      }
    );
    //getAllCustomersList
    builder.addCase(getAllCustomersList.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllCustomersList.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allCustomersList = action.payload?.data;
      }
    );
    builder.addCase(
      getAllCustomersList.rejected,
      (state: AuthState, action: any) => {
        state.loading = false;
      }
    );
    //getCustomerDetails
    builder.addCase(getCustomerDetails.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getCustomerDetails.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.selectedCustomerDetails = action.payload;
      }
    );
    builder.addCase(
      getCustomerDetails.rejected,
      (state: AuthState, action: any) => {
        state.loading = false;
      }
    );
    //getPrintSettingByIdApi
    builder.addCase(getPrintSettingByIdApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getPrintSettingByIdApi.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.getPrintSettingsResp = action.payload;
      }
    );
    builder.addCase(
      getPrintSettingByIdApi.rejected,
      (state: AuthState, action: any) => {
        state.loading = false;
      }
    );

    //updatePrintSettingApi
    builder.addCase(updatePrintSettingApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      updatePrintSettingApi.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
      }
    );
    builder.addCase(
      updatePrintSettingApi.rejected,
      (state: AuthState, action: any) => {
        state.loading = false;
      }
    );
    //validateOTP
    builder.addCase(validateOTP.pending, (state: any) => {
      state.loading = true;
      state.error = null;
      // const userO = {
      //   _id: "r423r34r34r4",
      //   mobileNumber: 9862341234,
      // }
      // state.user = action.payload?.user;
      // var userInfo = action.payload?.user;
      // localStorage.setItem("user", JSON.stringify(userInfo));
    });
    builder.addCase(
      validateOTP.fulfilled,
      (state: AuthState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.user = action.payload?.user;
        var userInfo = action.payload?.user;
        localStorage.setItem("user", JSON.stringify(userInfo));
      }
    );
    builder.addCase(validateOTP.rejected, (state: AuthState, action: any) => {
      state.loading = false;
    });
  },
});

export const {
  setLoading,
  updateUserDetails,
  updateErrorMessage,
  updateAddNewUserResponse,
  updateIsParentSiteOnly,
  updateIsAdminSiteOnly,
  updateAlertModelBox,
} = authSlice.actions;

export default authSlice.reducer;
