import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

export const createStudentAPI = createAsyncThunk(
  "reports/createStudentAPI",
  async (body: any) => await post(`${SERVER_IP}/student/addstudent`, body)
);
export const getStudentDetailsAPI = createAsyncThunk(
  "reports/getStudentDetailsAPI",
  async (iID: any) => await get(`${SERVER_IP}/student/${iID}`)
);
export const getStudentDetailsByRollNumberAPI = createAsyncThunk(
  "reports/getStudentDetailsByRollNumberAPI",
  async (iID: any) =>
    await get(`${SERVER_IP}/student/studentDetailsByRollNumber/${iID}`)
);

export const updateStudentDetailsAPI = createAsyncThunk(
  "reports/updateStudentDetailsAPI",
  async (body: any) => await put(`${SERVER_IP}/student/update`, body)
);

export const getAllStudents = createAsyncThunk(
  "reports/getAllStudents",
  async (body: any) =>
    await post(`${SERVER_IP}/student/all?page=${body?.page || 10}`, body)
);
export const createBulkStudentsAPI = createAsyncThunk(
  "reports/createBulkStudentsAPI",
  async (body: any) => await post(`${SERVER_IP}/student/bulk`, body)
);
export const addDirectPaymentAPI = createAsyncThunk(
  "reports/addDirectPaymentAPI",
  async (body: any) => await post(`${SERVER_IP}/payment/transaction`, body)
);
// export const getAllDirectPaymentsAPI = createAsyncThunk(
//   "reports/getAllDirectPaymentsAPI",
//   async (body: any) => await post(`${SERVER_IP}/payment/allDirectPayments`, body)
// );
export const getAllDirectPaymentsAPI = createAsyncThunk(
  "reports/getAllDirectPaymentsAPI",
  async (body: any) => await post(`${SERVER_IP}/payment/allTransactions`, body)
);
export const createFeeComponentsAPI = createAsyncThunk(
  "reports/createFeeComponentsAPI",
  async (body: any) => await post(`${SERVER_IP}/feecomponent/add`, body)
);
export const getAllFeeComponentsAPI = createAsyncThunk(
  "reports/getAllFeeComponentsAPI",
  async (body: any) => await post(`${SERVER_IP}/feecomponent/all`, body)
);
export const getPancardDetailsById = createAsyncThunk(
  "reports/getPancardDetailsById",
  async (body: any) => await post(`${SERVER_IP}/verify/pan`, body)
);
export const getAadhaarDetailsById = createAsyncThunk(
  "reports/getAadhaarDetailsById",
  async (body: any) => await post(`${SERVER_IP}/verify/aadhaar`, body)
);

// export const getAllFeeComponentsAPI = createAsyncThunk(
//   "reports/getAllFeeComponentsAPI",
//   async (id: any) => await get(`${SERVER_IP}/feecomponent/getAllFeeComponents/${id}`)
// );
// getAllFeeComponentsAPI
// getAllStudentDirectPaymentsAPI
