import styled from "styled-components";

export const IndexStyled = styled.div`
  .create-new-web-application {
    .add_new_child_step1 {
      width: 600px;
      margin: auto;
    }
    .add_new_child_step2 {
      width: 900px;
      margin: auto;
    }
  }
  .create-new-web-application {
    > div {
      max-height: fit-content !important;
    }
  }
`;
