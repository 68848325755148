import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./../pages/Home";
// import Signin from "../pages/Signin";
import UserLogin from "./../pages/UserLogin/UserLogin";
import UserSignUp from "./../pages/UserSignUp/UserSignUp";

import PrivacyPolicy from "./../pages/PrivacyPolicy";
import ContactUS from "./../pages/ContactUS";
import Applications from "./../pages/Applications";
import Transactions from "./../pages/Transactions";
import CustomerDashboard from "./../pages/CustomerDashboard";
import InstituteDashboard from "./../pages/InstituteDashboard";
import PrintJobCard from "../pages/BillingDetails/PrintJobCard";
import TermsOfService from "./../pages/TermsOfService";
// import SignUp from "./../pages/Signin/SignUp";
import Dashboard from "../pages/Dashboard/Dashboard";
import MyProfile from "../pages/MyProfile";
import { useLocation } from "react-router-dom";
import UsersList from "./../pages/UsersList/UsersList";
import StoresList from "./../pages/StoresList/StoresList";
import StoresReport from "./../pages/StoresReport/StoresReport";
import MyAccount from "./../pages/userManagement/index";
import CustomersList from "./../pages/CustomersList/CustomersList";
import ChangePassword from "./../pages/ChangePassword/ChangePassword";
import PrintPageSetting from "./../pages/PrintPageSetting/PrintPageSetting";
import AboutUs from "./../pages/AboutUs/AboutUs";
import EmailsReport from "./../pages/EmailsReport/EmailsReport";
import Testing from "../pages/Testing/Testing";
import CreateOrder from "../pages/CreateOrder/CreateOrder";
import InstituteSetUp from "../pages/InstituteSetUp/InstituteSetUp";
import Institutes from "../pages/Institutes/Institutes";
import Students from "../pages/Students/Students";
import CreateStudent from "./../pages/Students/CreateStudent";
import StudentDetails from "./../pages/Students/StudentDetails/StudentDetails";
import Reports from "./../pages/Reports/Reports";
import ManageUsers from "./../pages/ManageUsers/ManageUsers";
import StudentsBulkUpload from "./../pages/StudentsBulkUpload/StudentsBulkUpload";
import DeltaAuto from "../pages/DeltaAuto/DeltaAuto";
import DeltaPay from "../pages/DeltaPay/DeltaPay";
import Clients from "../pages/Clients/Clients";
import CreateNewApplication from "./../pages/CreateNewApplication/CreateNewApplication";
import Assignments from "./../pages/Assignments/Assignments";
import Billings from "./../pages/Billings/Billings";

const MainRoutes: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      {/* <Route exact path="/" component={Home} /> */}
      <Route exact path="/" component={Home} />

      <Route exact path="/dashboard/Profile" component={MyProfile} />
      <Route exact path="/dashboard/Applications" component={Applications} />
      <Route exact path="/dashboard/transactions" component={Transactions} />
      <Route exact path="/customerDashboard" component={CustomerDashboard} />
      <Route exact path="/myDashboard" component={InstituteDashboard} />
      <Route exact path="/dashboard/DeltaAuto" component={DeltaAuto} />
      <Route exact path="/dashboard/DeltaPay" component={DeltaPay} />
      <Route exact path="/createApplication" component={CreateNewApplication} />

      <Route exact path="/billingDetails/:billingId" component={PrintJobCard} />
      <Route exact path="/Billings" component={Billings} />
      <Route exact path="/Assignments" component={Assignments} />
      <Route exact path="/Clients" component={Clients} />
      <Route exact path="/contactUS" component={ContactUS} />
      <Route exact path="/signup" component={UserSignUp} />
      <Route exact path="/Signin" component={UserLogin} />
      <Route exact path="/Testingapp" component={Testing} />
      <Route exact path="/TermsOfService" component={TermsOfService} />
      <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />

      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/changepassword" component={ChangePassword} />
      <Route exact path="/usersList" component={UsersList} />
      <Route exact path="/customersList" component={CustomersList} />
      <Route exact path="/storesList" component={StoresList} />
      <Route exact path="/storesReport" component={StoresReport} />

      <Route exact path="/storesList1" component={MyAccount} />
      <Route exact path="/printpagesetting" component={PrintPageSetting} />
      <Route exact path="/AboutUs" component={AboutUs} />
      <Route exact path="/EmailsReport" component={EmailsReport} />
      <Route exact path="/createOrder" component={CreateOrder} />

      <Route exact path="/instituteSetUp" component={InstituteSetUp} />
      <Route
        exact
        path="/instituteSetUp/:instituteId"
        component={InstituteSetUp}
      />
      <Route exact path="/Institutes" component={Institutes} />
      <Route exact path="/Students" component={Students} />

      <Route exact path="/students/upload" component={StudentsBulkUpload} />
      <Route exact path="/Students/:studentId" component={StudentDetails} />
      <Route exact path="/createStudent" component={CreateStudent} />
      <Route exact path="/Reports" component={Reports} />
      <Route exact path="/ManageUsers" component={ManageUsers} />
    </Switch>
  );
};

export default MainRoutes;
