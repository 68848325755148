import { SendRegistrationLinkStyled } from "./SendRegistrationLink.styled";
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import clsx from "clsx";
import CloseButton from "react-bootstrap/CloseButton";
const initValStore = {
  admissionFeeAmount: undefined,
  tuitionFeeAmount: undefined,
  transactionDate: undefined,
  transactionMode: undefined,
  transactionId: undefined,
};
const initValError = {
  admissionFeeAmount: false,
  tuitionFeeAmount: false,
  transactionDate: false,
  transactionMode: false,
  transactionId: false,
};
const errorDataValue = {
  admissionFeeAmount: "Please Select Value",
  tuitionFeeAmount: "Please Enter Value",
  transactionDate: "Please Enter Value",
  transactionMode: "Please Enter Value",
  transactionId: "Please Enter Value",
};

const SendRegistrationLink = (props: any) => {
  const history = useHistory();
  const [passError, setPassError] = useState("");
  const dispatch = useDispatch();
  const [color, setColor] = useState("rgb(255, 255, 255)");
  const [feeComponent, setFeeComponent] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [errorData, setErrorData] = useState(initValError as any);

  useEffect(() => {}, [props?.show]);
  const handleSubmitFn = () => {
    const new344 = color.replace("rgb(", "").replace(")", "");
  };
  const handleChangeValue = (e: any) => {
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    setSignUpData({
      ...addr,
      [name]: value1,
    });
    setErrorData({
      ...addr_error,
      [name]: false,
    });
  };

  const admissionList = [
    {
      label: "Zero",
      value: "Zero",
    },
    {
      label: "Auto",
      value: "Auto",
    },
    {
      label: "Pay",
      value: "Pay",
    },
  ] as any;
  const handleChangeFeeComponent = (selectedOption: any) => {
    setFeeComponent(selectedOption);
  };
  return (
    <>
      <Modal {...props} size="sm" className="payment-mode-model-box" centered>
        <div className="conformation-model1">
          <SendRegistrationLinkStyled>
            <Modal.Body>
              <span
                className="color-picker-close-icon"
                onClick={() => props.handleClose(false)}
              >
                <CloseButton />
              </span>
              <div className="content-sec-color-picker">
                <h3>Send auto pay link</h3>
              </div>
              <p>
                The link will be sent on provided WhatsApp number. Using this
                link, the customer will be able to view or setup the Jodo
                payment plan for Hardeep Test.
              </p>

              <div className="model-content-body-sec">
                <div>
                  <div className="deltape-form free-payment-models-component">
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          {" "}
                          Full Name <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          // autoFocus
                          isInvalid={errorData?.admissionFeeAmount}
                          name="admissionFeeAmount"
                          type="text"
                          value={signUpData?.admissionFeeAmount}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.admissionFeeAmount ? (
                            <>{errorDataValue?.admissionFeeAmount}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          {" "}
                          Whats App Number{" "}
                          <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          // autoFocus
                          isInvalid={errorData?.admissionFeeAmount}
                          name="admissionFeeAmount"
                          type="text"
                          value={signUpData?.admissionFeeAmount}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.admissionFeeAmount ? (
                            <>{errorDataValue?.admissionFeeAmount}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          {" "}
                          Email Address{" "}
                          <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          // autoFocus
                          isInvalid={errorData?.admissionFeeAmount}
                          name="admissionFeeAmount"
                          type="text"
                          value={signUpData?.admissionFeeAmount}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.admissionFeeAmount ? (
                            <>{errorDataValue?.admissionFeeAmount}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="delta-select-column">
                      <label>Product </label>
                      <Select
                        value={feeComponent}
                        placeholder="Select Class"
                        onChange={handleChangeFeeComponent}
                        options={admissionList}
                        className="delta-select"
                        classNamePrefix="delta-select"
                      />
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="new-option-body-actions">
                <button
                  className="deltape-cancel-button"
                  onClick={props?.handleClose}
                >
                  Cancel
                </button>

                <Button className="deltape-button" onClick={handleSubmitFn}>
                  Send Link
                </Button>
              </div>
            </Modal.Footer>
          </SendRegistrationLinkStyled>
        </div>
      </Modal>
    </>
  );
};
export default SendRegistrationLink;
