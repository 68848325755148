import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { TestingStyled } from "./AlertModel.styled";

import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Axios from "axios";
import Swal from "sweetalert2";
import { updateAlertModelBox } from "../../redux/slices/auth/authSlice";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import clsx from "clsx";
import CloseButton from "react-bootstrap/CloseButton";
const Testing = (props: any) => {
  // console.log("propsprops", props);

  const history = useHistory();
  const [isError, setIsError] = useState("");
  const dispatch = useDispatch();
  const [color, setColor] = useState("rgb(255, 255, 255)");
  const [miles, setMiles] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [isLoding, setIsLoding] = useState(false);
  const { alertModelBox } = useSelector((auth1: any) => auth1?.auth);

  useEffect(() => {
    if (alertModelBox?.title) {
      showAlertModel();
    }
  }, [alertModelBox]);

  const showAlertModel = () => {
    Swal.fire({
      title: alertModelBox?.title,
      text: alertModelBox?.subTitle,
      icon: alertModelBox?.icon || "Success",
      showCancelButton: alertModelBox?.cancelBT ? true : false,
      confirmButtonColor: "#14a2b8",
      // cancelButtonColor: "#fff",
      // cancelTextColor: "#000",
      confirmButtonText: alertModelBox?.confirmBT,
      cancelButtonText: alertModelBox?.cancelBT,
    }).then((result) => {
      dispatch(updateAlertModelBox({}));
      if (result.isConfirmed && alertModelBox?.confirmBT) {
        alertModelBox?.confirmBTCall();
      }
    });
  };
  // console.log("signUpData final", JSON.stringify(signUpData));
  return <></>;
};
export default Testing;
