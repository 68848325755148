import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import back_btn from "../../../img/delta/back_btn_right.png";
import close_icon_img from "../../../img/delta/close_icon_img.png";
// import { LoginStyled } from "./Login.Styled";
// import ProfileDetails from "./ProfileDetails";
// import MedicalRegistration from "./MedicalRegistration";
// import EducationQualification from "./EducationQualification";
// import ConnectPractice from "./ConnectPractice";
// import EstablishmentDetails from "./EstablishmentDetails";
// import ProgressSummary from "./ProgressSummary";
// import IdentityProof from "./IdentityProof";
// import MedRegisterProof from "./MedRegisterProof";
// import EstablishmentProof from "./EstablishmentProof";
// import MapLocation from "./MapLocation";
// import ProgressSummarytwo from "./ProgressSummarytwo";
// import ConsultantDoctor from "./ConsultantDoctor";
// import BankAccount from "./BankAccount";
// import VerificationProfile from "./VerificationProfile";
import { CreateApplicationStyled } from "./CreateApplication.styled";
import SchoolSelection from "./SchoolSelection";
import UserInformation from "./UserInformation";
import AddChildMenuSteps from "./AddChildMenuSteps";
import ResidentialDetails from "./ResidentialDetails";
import EmploymentDetails from "./EmploymentDetails";
import IncomeVerification from "./IncomeVerification ";
import EMIPlans from "./EMIPlans";
import Preview from "./Preview";
import {
  getAllLocationsByIId,
  getAllBoards,
  getAllAcademicYears,
  getAllApplications,
  getAllApplicationsbyuser,
  createApplicationAPI,
  updateApplicationAPI,
  getApplicationDetailById,
} from "../../../redux/slices/PlansandPayments/PlansandPaymentsService";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const NewChildComponent = (props) => {
  console.log("props index js", props);
  const { fromPage = false } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [currectStep, setCurrectStep] = useState(1);
  const [pancardDetails, setPancardDetails] = useState({});
  const [applicationId, setApplicationId] = useState("");
  const { applicationDetails, userAllApplications } = useSelector(
    (authR) => authR?.plansandPayments
  );
  console.log("applicationDetails", applicationDetails);
  console.log("applicationId index js", applicationId);

  useEffect(() => {
    setCurrectStep(1);
    getApplicationDetailsCall();
  }, []);
  useEffect(() => {
    if (props?.show) {
      setCurrectStep(1);
      if (props?.applicationId) {
        setApplicationId(props?.applicationId);
      } else {
        setApplicationId("");
      }
    }
  }, [props?.show]);
  useEffect(() => {
    getApplicationDetailsCall();
  }, [applicationId]);
  console.log("currectStep", currectStep);
  const nextStep = (updN) => {
    const numv = updN && Number.isInteger(updN) ? updN : currectStep + 1;
    console.log("currectStep", currectStep, updN);
    setCurrectStep(numv);
    getApplicationDetailsCall();
  };
  const prevStep = (updN) => {
    const numv = updN && Number.isInteger(updN) ? updN : currectStep - 1;
    setCurrectStep(numv);
    getApplicationDetailsCall();
  };
  const createApplicationSuccess = (updN) => {
    setApplicationId(updN);
    nextStep();
  };
  const storePancardDetails = (updN) => {
    setPancardDetails(updN);
  };
  const getApplicationDetailsCall = () => {
    if (applicationId) {
      dispatch(getApplicationDetailById(applicationId));
    }
  };

  console.log("applicationId", applicationId);
  const renderContent = () => {
    switch (currectStep) {
      case 1:
        return (
          <SchoolSelection
            prevStep={prevStep}
            nextStep={nextStep}
            applicationId={applicationId}
            createApplicationSuccess={createApplicationSuccess}
          />
        );
      case 2:
        return (
          <EMIPlans
            prevStep={prevStep}
            nextStep={nextStep}
            applicationId={applicationId}
          />
        );
      case 3:
        return (
          <UserInformation
            applicationId={applicationId}
            prevStep={prevStep}
            nextStep={nextStep}
            storePancardDetails={storePancardDetails}
          />
        );
      case 4:
        return (
          <ResidentialDetails
            applicationId={applicationId}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 5:
        return (
          <EmploymentDetails
            applicationId={applicationId}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 6:
        return (
          <Preview
            applicationId={applicationId}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );

      case 7:
        return (
          <IncomeVerification
            applicationId={applicationId}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );

      //   case "identity-proof":
      //     return <IdentityProof />;
      //   case "med-register-proof":
      //     return <MedRegisterProof />;
      //   case "establishment-proof":
      //     return <EstablishmentProof />;
      //   case "progress-summary-two":
      //     return <ProgressSummarytwo />;
      //   case "map-location":
      //     return <MapLocation />;
      //   case "consultant-doctor":
      //     return <ConsultantDoctor />;
      //   case "bank-account":
      //     return <BankAccount />;
      //   case "verification-profile":
      //     return <VerificationProfile />;
      default:
        return <h1>Coming Soon</h1>;
    }
  };
  return (
    <>
      {fromPage ? (
        <>
          <CreateApplicationStyled>
            <div
              className={`add_new_child_step add_new_child_step${
                currectStep === 1 || currectStep === 2 || currectStep === 6
                  ? 1
                  : 2
              }`}
            >
              <div className="add-child-header-sec-close">
                <span onClick={prevStep}>
                  {currectStep > 1 && (
                    <>
                      <img src={back_btn} /> Back
                    </>
                  )}
                </span>
                {/* <span>
                <img onClick={props?.handleClose} src={close_icon_img} />
              </span> */}
              </div>
              {currectStep === 1 || currectStep === 2 || currectStep === 6 ? (
                <div className="add-child-content-sec">{renderContent()}</div>
              ) : (
                <>
                  <div className="add-child-full-content-sec">
                    <div className="add-child-left-content-sec">
                      <AddChildMenuSteps />
                    </div>
                    <div className="add-child-right-content-sec">
                      {renderContent()}
                    </div>
                  </div>
                </>
              )}
            </div>
          </CreateApplicationStyled>
        </>
      ) : (
        <Modal
          {...props}
          // size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={`add_new_child_step add_new_child_step${
            currectStep === 1 || currectStep === 2 || currectStep === 6 ? 1 : 2
          }`}
        >
          <Modal.Body className={`modalBodyDefault`}>
            <CreateApplicationStyled>
              <div className="add-child-header-sec-close">
                <span onClick={prevStep}>
                  {currectStep > 1 && (
                    <>
                      <img src={back_btn} /> Back
                    </>
                  )}
                </span>
                <span>
                  <img onClick={props?.handleClose} src={close_icon_img} />
                </span>
              </div>
              {currectStep === 1 || currectStep === 2 || currectStep === 6 ? (
                <div className="add-child-content-sec">{renderContent()}</div>
              ) : (
                <>
                  <div className="add-child-full-content-sec">
                    <div className="add-child-left-content-sec">
                      <AddChildMenuSteps />
                    </div>
                    <div className="add-child-right-content-sec">
                      {renderContent()}
                    </div>
                  </div>
                </>
              )}
            </CreateApplicationStyled>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default NewChildComponent;
