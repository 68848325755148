import React, { useEffect, useState } from "react";
import { StudentModulesStyled } from "./StudentModules.styled";
import { Row, Col, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { getStudentDetailsAPI } from "../../../redux/slices/StudentManagement/StudentManagementService";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./../../../components/Common/Loader";
import CustomModal from "../../../components/CustomModel";
import EditStudentDetails from "../StudentDetails/EditStudentDetails";
// import ProductCard from "./../../components/ProductCard";
import { Tabs } from "antd";
import type { TabsProps } from "antd";

import moment from "moment";
import { triggerMixpanelEvent } from "./../../../Scenes/common";
import DirectPaymentModel from "./../PaymentModels/DirectPaymentModel";
import { Table, Badge, Dropdown, Space, Pagination } from "antd";

const Payments = (props: any) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [instituteerrorMessage, setInstituteErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [studentGrade, setStudentGrade] = useState({} as any);
  const [showDirectPaymentModel, setShowDirectPaymentModel] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [editStudentModel, setEditStudentModel] = useState(false);
  const { studentDetails, allFeeComponents, allDirectPayments } = useSelector(
    (state: any) => state?.studentManagement
  );
  // const query = useQuery();
  // const selectedTab = query.get("selectedTab");
  console.log("allDirectPayments", allDirectPayments);
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((state: any) => state?.auth);

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };

  const handleCancel = () => {
    history.push("/students");
  };
  const handleEdit = (id: any) => {
    setCurrentStep(`createStudent/${id}`);
  };
  const showDerectPaymentModel = () => {
    setShowDirectPaymentModel(true);
  };
  const handleEditClose = () => {
    setShowDirectPaymentModel(false);
  };
  const onTabChange = (key: string) => {
    console.log(key);
  };
  const StudentAction = (id: any, actionN: any) => {
    console.log("idid", id);
    // if (actionN === "edit") {
    //   setSelectedStudentId(id);
    //   setShowEditStudentModel(true);
    //   // history.push(`/createStudent/${id}`);
    // } else if (actionN === "DropOff") {
    //   handleOpenDropOff();
    // } else if (actionN === "PaymentLink") {
    //   setShowCreatePaymentLink(true);
    // } else if (actionN === "RegistrationLink") {
    //   setShowSendRegistrationLink(true);
    // }
  };

  const items = [
    {
      key: "edit",
      label: <span className="drop-down-single-item-sec">Edit</span>,
    },
    // {
    //   key: "PaymentLink",
    //   label: (
    //     <span className="drop-down-single-item-sec">Create Payment Link</span>
    //   ),
    // },
    {
      key: "delete",
      label: <span className="drop-down-single-item-sec">Delete</span>,
    },
  ];

  const columns = [
    // {
    //   title: "Ref No",
    //   width: 100,
    //   dataIndex: "RefNo",
    //   key: "1",
    // },

    {
      title: "Transaction Mode",
      dataIndex: "transactionMode",
      key: "2",
      width: 170,
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "2",
      width: 170,
    },

    {
      title: "Fees Amount",
      // dataIndex: "tuitionFees",
      key: "3",
      width: 170,
      render: (item: any) => {
        return (
          <>
            {item?.tuitionFees ||
              item?.addmissionFees ||
              item?.transpotaionFees}
          </>
        );
      },
    },
    {
      title: "Fee Purpose",
      // dataIndex: "addmissionFees",
      key: "3",
      width: 170,
      render: (item: any) => {
        return (
          <>
            {item?.tuitionFees
              ? "Tuition Fee"
              : item?.addmissionFees
              ? "Addmission Fee"
              : item?.transpotaionFees
              ? "Transpotaion Fee"
              : null}
          </>
        );
      },
    },

    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "42",
      width: 170,
      render: (item: any) => {
        return <>{moment(item).format("Do, MMM YYYY")}</>;
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "4",
      width: 200,
    },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      dataIndex: "_id",
      render: (itemId: any) => {
        console.log("item", itemId);

        return (
          <Space size="middle">
            <Dropdown
              placement="topLeft"
              className="menu-items-sec-all"
              menu={{
                items,
                onClick: ({ key }) => {
                  StudentAction(itemId, key);
                },
              }}
            >
              <span className="hover-menu-item-sec">...</span>
            </Dropdown>
          </Space>
        );
      },
    },
  ] as any;

  return (
    <>
      {/* <StudentModulesStyled> */}
      <div>
        <div className="delata-table-pagination deltape-table-view">
          <Table
            columns={columns}
            pagination={false}
            dataSource={allDirectPayments}
            scroll={{ x: 1200, y: 500 }}
            rowClassName={(record, index) =>
              `row_selected_priority_${record?.priority}`
            }

            // total={50}
          />
        </div>
        {props?.pageFrom !== "transactions" && (
          <div className="add-paymet-btn">
            <button onClick={showDerectPaymentModel} className="deltape-button">
              + Add Direct Payment
            </button>
          </div>
        )}

        <DirectPaymentModel
          show={showDirectPaymentModel}
          handleClose={handleEditClose}
          studentId={props?.studentId}
        />
      </div>
      {/* </StudentModulesStyled> */}
    </>
  );
};

export default Payments;
