import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

export const createInstituteAPI = createAsyncThunk(
  "reports/createInstituteAPI",
  async (body: any) => await post(`${SERVER_IP}/institute/create`, body)
);
export const getInstituteDetailsAPI = createAsyncThunk(
  "reports/getInstituteDetailsAPI",
  async (iID: any) => await get(`${SERVER_IP}/institute/${iID}`)
);

export const updateInstituteDetailsAPI = createAsyncThunk(
  "reports/updateInstituteDetailsAPI",
  async (body: any) => await put(`${SERVER_IP}/institute/update`, body)
);

export const getAllInstitutes = createAsyncThunk(
  "reports/getAllInstitutes",
  async () => await get(`${SERVER_IP}/institute/all`)
);


