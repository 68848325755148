import React, { useEffect, useState } from "react";
import { ManageUsersStyled } from "./ManageUsers.styled";
import { Row, Col, Button, Form, Dropdown } from "react-bootstrap";
import Select from "react-select";
import {
  createUserAPI,
  getUserDetailsAPI,
  updateUserDetailsAPI,
  getAllUsers,
} from "../..//redux/slices/UserManagement/UserManagementService";

import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./../../components/Common/Loader";

// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "./../../Scenes/common";
const initValStore = {
  fullName: undefined,
  email: undefined,
  phoneNumber: undefined,
  role: undefined,
};
const initValError = {
  fullName: false,
  phoneNumber: false,
  email: false,
};
const errorDataValue = {
  fullName: "Please Enter First name",
  email: "Please Enter Phone number",
  phoneNumber: "Please Enter Last name",
  role: "Please Enter Last name",
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const CreateUser = (props: any) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [instituteerrorMessage, setInstituteErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [studentRole, setStudentRole] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [errorData, setErrorData] = useState(initValError as any);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // const query = useQuery();
  // const selectedTab = query.get("selectedTab");
  const isEdit = props?.isEdit ? false : false;
  const userId = null;
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((state: any) => state?.auth);
  const { studentDetails, manageUserDetails } = useSelector(
    (state: any) => state?.userManagement
  );
  // useEffect(() => {
  //   triggerMixpanelEvent("Contact_Us");
  //   if (userId) {
  //     dispatch(getUserDetailsAPI(userId));
  //   }
  // }, [userId]);
  useEffect(() => {
    if (props?.selectedUser) {
      dispatch(getUserDetailsAPI(props?.selectedUser));
    }
  }, [props?.selectedUser]);
  console.log("manageUserDetails", manageUserDetails);
  useEffect(() => {
    if (manageUserDetails || isEdit) {
      const ssDetails = manageUserDetails?.fullName
        ? manageUserDetails
        : props?.sDetails;
      const prevDetail = {
        fullName: ssDetails?.fullName,
        establishedYear: ssDetails?.establishedYear,
        email: ssDetails?.email,
        primaryContactEmail: ssDetails?.primaryContactEmail,
        primaryContactNumber: ssDetails?.primaryContactNumber,
        primaryContactName: ssDetails?.primaryContactName,
        phoneNumber: ssDetails?.phoneNumber,
        role: ssDetails?.role,
      };
      setStudentRole({ label: ssDetails?.role, value: ssDetails?.role });
      setSignUpData({ ...prevDetail });
    }
  }, [manageUserDetails, isEdit]);

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };

  const handleChangeValue = (e: any) => {
    setErrorMessage("");
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    setSignUpData({
      ...addr,
      [name]: value1,
    });
    setErrorData({
      ...addr_error,
      [name]: false,
    });
  };
  const handleSaveClick = async () => {
    setShowErrorMessage(true);
    setErrorMessage("");
    var isValid = true;
    var errornew = { ...initValError } as any;
    for (var key in errornew) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    if (!signUpData.role) {
      isValid = false;
    }
    setErrorData({ ...errornew });

    if (isValid) {
      triggerMixpanelEvent("save_my_profile");
      //   , _id: user?._id
      const jBody = { ...signUpData, instituteId: user?._id };
      setIsLoading(true);
      if (props?.selectedUser) {
        const body = { ...jBody, _id: manageUserDetails?._id };
        const resp = (await dispatch(updateUserDetailsAPI(body))) as any;
        props?.getAllUsersCall();
        setIsLoading(false);
        if (resp?.payload?.success) {
          props?.addUserSuccess();
        } else if (resp?.payload?.error) {
          setErrorMessage(resp?.payload?.error);
        }
      } else {
        const body = { ...jBody };
        const resp = (await dispatch(createUserAPI(body))) as any;
        props?.getAllUsersCall();
        setIsLoading(false);
        if (resp?.payload?.success) {
          props?.addUserSuccess();
        } else if (resp?.payload?.error) {
          setErrorMessage(resp?.payload?.error);
        }
      }
    }
  };
  const handleCancel = () => {
    props?.handleCancel();
  };
  const handleEdit = () => {
    setCurrentStep("1");
  };
  const handleChangeYear = (selectedOption: any) => {
    // setInstituteErrorMessage("");
    setAcademicYear(selectedOption);
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["academicYear"]: selectedOption?.label,
    });
    // dispatch(getAllAcademicYears({ studentId: 233, locationId: 1233 }));
  };
  const handleChangeGrade = (selectedOption: any) => {
    setErrorMessage("");
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["role"]: selectedOption?.label,
    });
    // setInstituteErrorMessage("");
    setStudentRole(selectedOption);
    // dispatch(getAllAcademicYears({ studentId: 233, locationId: 1233 }));
  };

  console.log("errorDataerrorData", errorData);
  console.log("errorDataerrorData", errorDataValue);

  const locationsList3 = [
    {
      label: "Super Admin",
      value: "Super Admin",
    },
    {
      label: "Admin",
      value: "Admin",
    },
    {
      label: "Staff",
      value: "Staff",
    },
    {
      label: "Accoutant",
      value: "Accoutant",
    },
    {
      label: "Operations",
      value: "Operations",
    },
    {
      label: "Developer",
      value: "Developer",
    },
  ] as any;
  console.log("signUpData", signUpData);
  const isEditText = isEdit ? "edit" : "";
  return (
    <>
      <ManageUsersStyled>
        <div
          className={`create-new-institute-sec create-new-institute-sec-${isEditText}`}
        >
          {isLoading && <Loader />}
          <div className="create-new-institute-sec-content">
            <div className="student-info-values">
              <div className="create-new-institute-header">
                <h3>{props?.selectedUser ? "Edit User" : "Add New User"} </h3>
              </div>
              <div>
                {/* <h5>Institute Name: Institute Name</h5> */}

                <br />
                <div className="student-fields-sec-content deltape-form">
                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        {" "}
                        Full Name <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        // autoFocus
                        isInvalid={errorData?.fullName}
                        name="fullName"
                        type="text"
                        value={signUpData?.fullName}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.fullName ? (
                          <>{errorDataValue?.fullName}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        Phone Number <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        // autoFocus
                        isInvalid={errorData?.phoneNumber}
                        name="phoneNumber"
                        type="text"
                        maxLength={10}
                        value={signUpData?.phoneNumber}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.phoneNumber ? (
                          <>{errorDataValue?.phoneNumber}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        Email Address <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        // autoFocus
                        isInvalid={errorData?.email}
                        name="email"
                        type="text"
                        value={signUpData?.email}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.email ? <>{errorDataValue?.email}</> : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div
                    className={
                      showErrorMessage && !signUpData?.role
                        ? "delta-select-column delta-select-column-error"
                        : "delta-select-column"
                    }
                  >
                    <label>Role </label>
                    <Select
                      value={studentRole?.value ? studentRole : ""}
                      placeholder="Select Role"
                      onChange={handleChangeGrade}
                      options={locationsList3}
                      className="delta-select"
                      classNamePrefix="delta-select"
                    />
                    {showErrorMessage && !signUpData?.role && (
                      <p className="error_message"> Please select Role </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {errorMessage && <p className="error_message"> {errorMessage} </p>}
          <div className="actions-btn-sec align-center">
            <button className="deltape-cancel-button" onClick={handleCancel}>
              Cancel
            </button>
            <button className="deltape-button" onClick={handleSaveClick}>
              {userId || isEdit ? "Update User" : "Create User"}
            </button>
          </div>
        </div>
      </ManageUsersStyled>
    </>
  );
};

export default CreateUser;
