import styled from "styled-components";

export const InstituteDashboardStyled = styled.div`
  .no-results {
    color: rgb(89, 85, 85);
    margin-top: 100px;
    text-align: center;
  }
  .all-applocations-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 20px;
  }
  .prev-values-section {
    border: 1px solid #e2e2e2;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
  .dashboard-header-top-sec {
    h4 {
      font-size: 24px;
      line-height: 40px;
      color: #4c4c4c;
    }
    p {
      font-size: 18px;
      line-height: 18px;
      color: #343434;
    }
  }
  .dashboard-statistics-sec {
    margin-top: 30px;
    h4 {
      font-size: 20px;
      line-height: 22px;
      color: #4c4c4c;
    }

    .dashboard-statistics-row-all {
      margin-top: 24px;
      margin-bottom: 24px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      > div {
        box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16);
        border-radius: 16px;
        background-color: #fff;
        padding: 16px;
        text-align: center;
        p {
          font-size: 16px;
          letter-spacing: -0.02em;
          line-height: 26px;
          font-weight: 600;
          color: #8c8c8c;
          margin-bottom: 10px;
        }
        h4 {
          font-size: 18px;
          letter-spacing: -0.02em;
          line-height: 32px;
          color: #23272e;
        }
      }
    }
  }
  .chart-sec-head-sec {
    border-bottom: 1px solid #dadada;
    padding-bottom: 14px;
    margin-bottom: 14px;
    text-align: right;
    display: flex;
    justify-content: end;
    > div {
      background-color: rgba(218, 218, 218, 0.24);
      border-radius: 6.97px;
      height: 40px;
      padding-top: 6px;
      padding-right: 10px;
      padding-left: 10px;
    }
    span {
      color: #4c4c4c;
      font-size: 14px;
      padding: 3.5px 10.5px;
      width: 75px;
      display: inline-block;
      text-align: center;
      cursor: pointer;
    }
    .active {
      box-shadow: 0px 0px 10.45px rgba(0, 0, 0, 0.15);
      border-radius: 6.97px;
    }
  }
  .chart-sec-head-sec1 {
    justify-content: start;
    > span {
      width: auto;
      top: 5px;
      position: relative;
      font-size: 18px;
      letter-spacing: -0.02em;
      line-height: 17.52px;
      font-weight: 600;
      color: #050505;
    }
  }
  .students-list-sec-all {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 16px;
  }
  .chart-sec-full-view {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    > div {
      width: 100%;
      box-shadow: 0px 0px 10.45px rgba(0, 0, 0, 0.15);
      border-radius: 6.97px;
      padding: 25px 35px;
    }
  }
  .edit-sec-prev-values {
    button {
      margin-top: 20px;
      width: 250px;
    }
  }
  .prev-valu-row {
    span {
      font-size: 15px;
      color: rgb(107 105 105);
    }
    span:nth-child(1) {
      //   font-size: 20px;
      //   font-weight: bold;
      width: 120px;
      display: inline-block;
    }
    span:nth-child(2) {
      width: 10px;
      display: inline-block;
    }
  }
  .chart-view-section-fee {
    display: flex;
  }
`;
