import styled from "styled-components";

export const DeltaPayStyled = styled.div`
  .no-results {
    color: rgb(89, 85, 85);
    margin-top: 100px;
    text-align: center;
  }
  .delata-tabs-sec {
    .ant-tabs-tab-btn {
      font-size: 20px;
    }
  }
`;
