import React, { useEffect, useState } from "react";
import { ManageUsersStyled } from "./ManageUsers.styled";
import { Table, Badge, Dropdown, Space, Pagination } from "antd";

import { Row, Col, Button, Form } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddNewUserModel from "./AddNewUserModel";
import moment from "moment";
import doctor_img from "./../../img/young-hispanic-woman-working-scientist-laboratory-holding-safety-glasses-smiling-happy-pointing-with-hand-finger-side 1(1).png";
import Select from "react-select";
import add_icon from "../../img/delta/add.png";

import { getAllUsers } from "./../../redux/slices/UserManagement/UserManagementService";
import { triggerMixpanelEvent } from "../../Scenes/common";
import CreateApplication from "../../components/CreateApplication/CreateApplication";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import CustomModal from "../../components/CustomModel";

const ManageUsers = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [showAddNewUserModel, setShowAddNewUserModel] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [signUpData, setSignUpData] = useState({} as any);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user, isAdminSite } = useSelector(
    (state: any) => state?.auth
  );
  const { allUsers } = useSelector((state: any) => state?.userManagement);
  console.log("allUsers", allUsers);

  useEffect(() => {
    triggerMixpanelEvent("Contact_Us");
    getAllUsersCall();
  }, []);
  useEffect(() => {
    getAllUsersCall();
  }, [signUpData]);

  const getAllUsersCall = () => {
    let newJson = {} as any;
    for (const [key, value] of Object.entries(signUpData)) {
      if (value && value !== "All") {
        newJson[key] = value;
      }
      console.log(`${key}: ${value}`);
    }
    let body = { ...newJson };
    if (!isAdminSite) {
      body.instituteId = user?._id;
    }
    // body.role= "Admin"

    dispatch(getAllUsers(body));
  };
  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleCancel = () => {
    setShowAddNewUserModel(false);
  };
  const addUserSuccess = () => {
    setShowAddNewUserModel(false);
    setShowSuccessMessage(true);
  };
  const handleCreatenewApp = () => {
    setSelectedUser("");
    setShowAddNewUserModel(true);
  };
  const handleChangeValue = (e: any) => {
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };

    setSignUpData({
      ...addr,
      [name]: value1,
    });
  };
  const instituteAction = (id: any, actionN: any) => {
    console.log("idid", id);
    if (actionN === "edit") {
      setShowAddNewUserModel(true);
      setSelectedUser(id);
      // history.push(`/institutesetup/${id}`);
    }
  };
  const pageonChange = (page: any) => {
    console.log("page", page);
    setCurrentPage(page);
  };
  const handleChangeRole = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["role"]: selectedOption?.label,
    });
  };
  const items = [
    {
      key: "edit",
      label: <span className="drop-down-single-item-sec">Edit</span>,
    },
    {
      key: "delete",
      label: <span className="drop-down-single-item-sec">Delete</span>,
    },
  ];
  const columns = [
    {
      title: "Name",
      width: 200,
      dataIndex: "fullName",
      key: "0",
      fixed: "left",
    },
    // {
    //   title: "Ref No",
    //   width: 100,
    //   dataIndex: "RefNo",
    //   key: "1",
    // },

    {
      title: "Mobile Number",
      dataIndex: "phoneNumber",
      key: "2",
      width: 200,
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "3",
      width: 230,
      render: (item: any) => {
        return <>{item}</>;
      },
    },

    {
      title: "Institute",
      dataIndex: "phoneNumber",
      key: "42",
      width: 200,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "4",
      width: 200,
    },

    {
      title: "Created",
      dataIndex: "createdAt",
      key: "7",
      width: 150,
      render: (item: any) => {
        return <>{moment(item).format("DD/MM/YYYY")}</>;
      },
    },
    { title: "Age", dataIndex: "age", key: "8", width: 70 },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 90,
      dataIndex: "_id",
      render: (itemId: any) => {
        console.log("item", itemId);

        return (
          <Space size="middle">
            <Dropdown
              placement="topLeft"
              className="menu-items-sec-all"
              menu={{
                items,
                onClick: ({ key }) => {
                  instituteAction(itemId, key);
                },
              }}
            >
              <span className="hover-menu-item-sec">...</span>
            </Dropdown>
          </Space>
        );
      },
    },
  ] as any;
  const rolesList = [
    {
      label: "Super Admin",
      value: "Super Admin",
    },
    {
      label: "Admin",
      value: "Admin",
    },
    {
      label: "Staff",
      value: "Staff",
    },
    {
      label: "Accoutant",
      value: "Accoutant",
    },
    {
      label: "Operations",
      value: "Operations",
    },
    {
      label: "Developer",
      value: "Developer",
    },
  ] as any;

  return (
    <>
      <ManageUsersStyled>
        <div className="freshbag-wrapper">
          <div className="content getinTouchPage">
            <div>
              <BreadCrumb list={[{ title: "Users" }]} />
              <div className="profileinfoHeader">
                <div>
                  <span className="edit-p-text">Manage Users</span>
                  <div>
                    <span className="profileEdit" onClick={handleCreatenewApp}>
                      Add New User &nbsp; <img src={add_icon} />
                    </span>
                  </div>
                </div>
                <p>View All Users</p>
              </div>
            </div>
            <div className="create-new-institute-sec-content-all">
              <div className="student-fields-sec-content-all deltape-form">
                <div className="student-info-row">
                  <Form.Group className="delta-signup-md" controlId="email">
                    <Form.Label> Search</Form.Label>
                    <Form.Control
                      placeholder="Search: Name, Email, Phone"
                      // autoFocus
                      // isInvalid={errorData?.studentName}
                      name="studentName"
                      type="text"
                      value={signUpData?.studentName}
                      onChange={(e) => handleChangeValue(e)}
                    />
                  </Form.Group>
                </div>

                <div className="delta-select-column">
                  <label>Select Role </label>
                  <Select
                    value={
                      signUpData?.role
                        ? { label: signUpData?.role, value: signUpData?.role }
                        : ""
                    }
                    placeholder="Select Role"
                    onChange={handleChangeRole}
                    options={rolesList}
                    className="delta-select"
                    classNamePrefix="delta-select"
                  />
                </div>
                <div></div>
                <div></div>
                {/* <div className="delta-select-column submit-filter-btn-sec">
                  <button className="deltape-button">SUBMIT</button>
                </div> */}
              </div>
            </div>
            <div>
              <div className="total-count-row">
                <span>
                  Showing {allUsers?.data?.length} of {allUsers?.totalRecords}{" "}
                  Users
                </span>
              </div>
              <div className="institutes-filters"></div>
              <div className="all-institutes-data deltape-table-view">
                <Table
                  columns={columns}
                  pagination={false}
                  dataSource={allUsers?.data}
                  scroll={{ x: 1500, y: 500 }}
                  rowClassName={(record, index) =>
                    `row_selected_priority_${record?.priority}`
                  }
                />

                <div className="delata-table-pagination">
                  <Pagination
                    defaultCurrent={1}
                    current={currentPage}
                    total={allUsers?.length}
                    onChange={(val) => {
                      pageonChange(val);
                    }}
                    pageSize={10}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            </div>
          </div>

          {allUsers?.length === 0 && (
            <div className="no-results">
              <p>No Users available</p>
            </div>
          )}
          <AddNewUserModel
            handleCancel={handleCancel}
            selectedUser={selectedUser}
            addUserSuccess={addUserSuccess}
            show={showAddNewUserModel}
            getAllUsersCall={getAllUsersCall}
          />
          <CustomModal
            show={showSuccessMessage}
            titleText={
              selectedUser
                ? "User Successfully Updated."
                : "User Successfully Created."
            }
            deleteText="Ok"
            cancelText=""
            onDelete={() => {
              setShowSuccessMessage(false);
              handleCancel();
            }}
          />
        </div>
      </ManageUsersStyled>
    </>
  );
};

export default ManageUsers;
