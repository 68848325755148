import React, { useEffect, useState } from "react";
import { StudentsStyled } from "./Students.styled";
import { Table, Badge, Dropdown, Space, Pagination } from "antd";

import { Row, Col, Button, Form } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { getAllClients } from "../../redux/slices/Clients/ClientsService";
import print_img from "../../img/delta/print-6568980_640.png";
import edit_img from "../../img/delta/edit-6931553_640.png";

import { getAllAssignments } from "../../redux/slices/Assignments/AssignmentsService";
import { triggerMixpanelEvent } from "../../Scenes/common";
import { updatesClientDetails } from "../../redux/slices/Assignments/AssignmentsSlice";
import { acadamicYearsList, classesList, admissionList } from "../../config";
import Loader from "../../components/Common/Loader";
import CustomModal from "../../components/CustomModel";
import { getAllUsers } from "./../../redux/slices/UserManagement/UserManagementService";
import { assignmentTypes, statusTypes } from "../../library/constants";

import EditStudentDetails from "./StudentDetails/EditStudentDetails";
import CreateApplication from "../../components/CreateApplication/CreateApplication";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import upload_icon from "../../img/delta/backup.png";
import add_icon from "../../img/delta/add.png";
import { checkIsMobile } from "../../Scenes/common";
import CreateStudentModel from "./CreateStudentModel/CreateStudentModel";
const Assignments = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [createStudentModelShow, setCreateStudentModelShow] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [showDropOffStudent, setShowDropOffStudent] = useState(false);
  const [showSendRegistrationLink, setShowSendRegistrationLink] =
    useState(false);
  const [showCreatePaymentLink, setShowCreatePaymentLink] = useState(false);
  const [showEditStudentModel, setShowEditStudentModel] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [newAdmission, setNewAdmission] = useState({} as any);
  const [studentGrade, setStudentGrade] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [signUpData, setSignUpData] = useState({} as any);

  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user, isAdminSite } = useSelector(
    (state: any) => state?.auth
  );

  const { allAssignments } = useSelector((state: any) => state?.assignments);
  const { allClients } = useSelector((state: any) => state?.clients);
  console.log("allAssignments", allAssignments);
  console.log("allClients", allClients);

  useEffect(() => {
    triggerMixpanelEvent("Students_page");
    const body = { vendorId: user?._id };
    getAllStudentsCall();
    dispatch(getAllClients({}));

    dispatch(getAllUsers(body));
  }, []);
  useEffect(() => {
    getAllStudentsCall();
  }, [signUpData, currentPage]);
  const getAllStudentsCall = async () => {
    console.log("signUpData", signUpData);
    console.log("studentGrade", studentGrade);
    console.log("academicYear", academicYear);
    let newJson = {} as any;
    // signUpData.map((itt: any) => {
    //   console.log("itt", itt);
    // });
    for (const [key, value] of Object.entries(signUpData)) {
      if (value && value !== "All") {
        newJson[key] = value;
      }
      console.log(`${key}: ${value}`);
    }
    let body = { instituteId: user?._id, ...newJson };
    if (isAdminSite) {
      body = { ...newJson };
    }
    body.page = currentPage;
    setIsLoading(true);
    await dispatch(getAllAssignments(body));

    setIsLoading(false);
  };
  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleCreatenewApp = () => {
    setSelectedStudentId("");
    dispatch(updatesClientDetails({}));
    // history.push("/createStudent");
    setCreateStudentModelShow(true);
  };
  const handleBulkUpload = () => {
    history.push("/students/upload");
  };

  const StudentAction = (id: any, actionN: any) => {
    console.log("idid", id);
    if (actionN === "edit") {
      setSelectedStudentId(id);
      setCreateStudentModelShow(true);
      // history.push(`/createStudent/${id}`);
    } else if (actionN === "DropOff") {
      handleOpenDropOff();
    } else if (actionN === "PaymentLink") {
      setShowCreatePaymentLink(true);
    } else if (actionN === "RegistrationLink") {
      setShowSendRegistrationLink(true);
    }
  };

  const handleEditClose = () => {
    setCreateStudentModelShow(false);
    setShowDropOffStudent(false);
    setShowCreatePaymentLink(false);
    setShowSendRegistrationLink(false);
  };
  const handleOpenDropOff = () => {
    setShowDropOffStudent(true);
  };
  const handleChangeValue = (e: any) => {
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };

    setSignUpData({
      ...addr,
      [name]: value1,
    });
  };
  const handleChangeYear = (selectedOption: any) => {
    // setInstituteErrorMessage("");
    setAcademicYear(selectedOption);
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["academicYear"]: selectedOption?.label,
    });
    // dispatch(getAllAcademicYears({ studentId: 233, locationId: 1233 }));
  };
  const handleChangeGrade = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["studentGrade"]: selectedOption?.label,
    });
    // setInstituteErrorMessage("");
    setStudentGrade(selectedOption);
    // dispatch(getAllAcademicYears({ studentId: 233, locationId: 1233 }));
  };
  const handleChangeAdmission = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["newAdmission"]: selectedOption?.label,
    });
    // setInstituteErrorMessage("");
    setNewAdmission(selectedOption);
    // dispatch(getAllAcademicYears({ studentId: 233, locationId: 1233 }));
  };
  const studentAddSuccess = () => {
    setCreateStudentModelShow(false);
    setShowSuccessMessage(true);
    dispatch(getAllAssignments({ vendorId: user?._id }));
  };

  const pageonChange = (page: any) => {
    console.log("page", page);
    setCurrentPage(page);
  };
  const items = [
    {
      key: "edit",
      label: <span className="drop-down-single-item-sec">Edit</span>,
    },

    {
      key: "delete",
      label: <span className="drop-down-single-item-sec">Delete</span>,
    },
  ];
  const columns = [
    {
      title: "Client Name",
      width: 200,
      key: "0",
      fixed: `${checkIsMobile() ? "none" : "fixed"}`,
      render: (item: any) => {
        return (
          <>
            <span className="table-student-name">
              {item?.clientName || "Client 1"}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Ref No",
    //   width: 100,
    //   dataIndex: "RefNo",
    //   key: "1",
    // },

    {
      title: "Assignment Name",
      dataIndex: "assignmentName",
      key: "2",
      width: 200,
    },
    {
      title: "Assignment Type",
      dataIndex: "assignmentType",
      key: "2",
      width: 180,
    },
    {
      title: "Assign To",
      dataIndex: "assignTo",
      key: "2",
      width: 200,
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "3",
      width: 200,
      render: (item: any) => {
        return <>{item || "24-11-2024"}</>;
      },
    },

    {
      title: "End Date",
      dataIndex: "endDate",
      key: "3",
      width: 200,
      render: (item: any) => {
        return <>{item || "24-11-2024"}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "3",
      width: 200,
      render: (item: any) => {
        return <>{item || "In-progress"}</>;
      },
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "3",
      width: 500,
      render: (item: any) => {
        return <>{item || "In-progress"}</>;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "7",
      width: 150,
      render: (item: any) => {
        return <>{moment(item).format("DD/MM/YYYY")}</>;
      },
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 90,
      dataIndex: "_id",
      render: (itemId: any) => {
        console.log("item", itemId);

        return (
          <div className="action-icons-sec-new">
            <div>
              <img
                src={edit_img}
                onClick={() => StudentAction(itemId, "edit")}
              />
            </div>
            <div>
              <img
                src={print_img}
                onClick={() => StudentAction(itemId, "delete")}
              />
            </div>
          </div>
          // <Space size="middle">
          //   <Dropdown
          //     placement="topLeft"
          //     className="menu-items-sec-all"
          //     menu={{
          //       items,
          //       onClick: ({ key }) => {
          //         StudentAction(itemId, key);
          //       },
          //     }}
          //   >
          //     <span className="hover-menu-item-sec">...</span>
          //   </Dropdown>
          // </Space>
        );
      },
    },
  ] as any;

  return (
    <>
      <StudentsStyled>
        <div className="freshbag-wrapper">
          {isLoading && <Loader />}
          {/* <CreateStudentModel
            show={showEditStudentModel}
            selectedStudentId={selectedStudentId}
            handleEditClose={handleEditClose}
          /> */}
          <CreateStudentModel
            show={createStudentModelShow}
            selectedStudentId={selectedStudentId}
            handleCloseSuccess={studentAddSuccess}
            handleClose={() => {
              setCreateStudentModelShow(false);
            }}
          />
          <CustomModal
            show={showSuccessMessage}
            titleText={
              selectedStudentId
                ? "Assignment Successfully Updated."
                : "Assignment Successfully Created."
            }
            deleteText="Ok"
            cancelText=""
            onDelete={() => {
              setShowSuccessMessage(false);
            }}
          />
          <div className="content getinTouchPage">
            <div>
              <BreadCrumb list={[{ title: "Assignments" }]} />
              <div className="profileinfoHeader">
                <div>
                  <span className="edit-p-text">Manage Assignments</span>

                  <div>
                    {/* <span className="profileEdit" onClick={handleBulkUpload}>
                      Bulk Upload &nbsp;
                      <img src={upload_icon} />
                    </span> */}
                    <span className="profileEdit" onClick={handleCreatenewApp}>
                      Add New Assignment &nbsp; <img src={add_icon} />
                    </span>
                  </div>
                </div>
                <p>View All Assignments</p>
              </div>
            </div>

            <div className="create-new-institute-sec-content-all">
              <div className="student-fields-sec-content-all deltape-form">
                <div className="student-info-row">
                  <Form.Group className="delta-signup-md" controlId="email">
                    <Form.Label> Assignment Name</Form.Label>
                    <Form.Control
                      placeholder="Search: Name, Email, Phone"
                      // autoFocus
                      // isInvalid={errorData?.studentName}
                      name="studentName"
                      type="text"
                      value={signUpData?.studentName}
                      onChange={(e) => handleChangeValue(e)}
                    />
                  </Form.Group>
                </div>
                <div className="delta-select-column">
                  <label>
                    Client <span className="mand-sign-field">*</span>
                  </label>
                  <Select
                    // value={studentGrade ?? "All"}
                    value={{
                      label: studentGrade?.label ?? "All",
                      value: studentGrade?.label ?? "All",
                    }}
                    placeholder="Select Location"
                    onChange={handleChangeGrade}
                    options={[
                      {
                        label: "All",
                        value: "",
                      },
                    ]}
                    className="delta-select"
                    classNamePrefix="delta-select"
                  />
                </div>
                {/* <div className="student-info-row">
                  <Form.Group className="delta-signup-md" controlId="email">
                    <Form.Label>
                      Assignment Type
                      <span className="mand-sign-field">*</span>
                    </Form.Label>
                    <Select
                      // value={studentGrade ?? "All"}
                      value={{
                        label: assignmentType?.label ?? "All",
                        value: assignmentType?.label ?? "All",
                      }}
                      placeholder="Select Assign TO"
                      onChange={handleChangeType}
                      options={assignmentTypes}
                      className="delta-select"
                      classNamePrefix="delta-select"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorData?.assignmentType ? (
                        <>{errorDataValue?.assignmentType}</>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div> */}
              </div>
            </div>
            <br />
            <div>
              <div className="total-count-row">
                <span>
                  Showing {allAssignments?.data?.length} of{" "}
                  {allAssignments?.totalRecords} Clients
                </span>
              </div>
              <div className="Students-filters"></div>
              <div className="all-Students-data deltape-table-view">
                <Table
                  columns={columns}
                  pagination={false}
                  dataSource={allAssignments?.data}
                  scroll={{ x: 1500, y: 500 }}
                  rowClassName={(record, index) =>
                    `row_selected_priority_${record?.priority}`
                  }

                  // total={50}
                />
                {/* <Pagination
                  defaultCurrent={1}
                  total={allStudents?.totalRecords}
                /> */}
                {/* noOfPages */}
                <br />
                <div className="delata-table-pagination">
                  <Pagination
                    defaultCurrent={1}
                    current={currentPage}
                    total={allAssignments?.totalRecords}
                    onChange={(val) => {
                      pageonChange(val);
                    }}
                    pageSize={10}
                    showSizeChanger={false}
                    // size="small"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* {allStudents?.length === 0 && (
            <div className="no-results">
              <p>No Students available</p>
            </div>
          )} */}
        </div>
      </StudentsStyled>
    </>
  );
};

export default Assignments;
