import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  signUpUser,
  addNewUserData,
  updatePasswordApi,
} from "../../redux/slices/auth/authService";
import { useHistory, useLocation } from "react-router-dom";
import { GOOGLE_MAP_URL } from "../../config";
import CustomModal from "../../components/CustomModel";
import { triggerMixpanelEvent } from "../../Scenes/common";
import { ChangePasswordStyled } from "./ChangePassword.styled";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import clsx from "clsx";
import CloseButton from "react-bootstrap/CloseButton";

const ChangePassword = (props: any) => {
  const { selectedData, addUserSuccess, onHide, addPasswordSuccess } = props;
  const { error, loading, user } = useSelector((stateD: any) => stateD?.auth);
  const history = useHistory();
  const [passError, setPassError] = useState(false);
  const [passCError, setCPassError] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [showSuccessUserPopup, setShowSuccessUserPopup] = useState(false);
  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, errorErrorMessage] = useState("");

  const dispatch = useDispatch();
  console.log("user", user);
  const handleChangeValue = (e: any) => {
    errorErrorMessage("");
    let { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    } else if (name === "currentPassword") {
      setCurrentPassword(value);
    } else {
      setConfirmPassword(value);
    }
  };

  const createAccountData = async () => {
    if (!password && !currentPassword) {
      errorErrorMessage("Please enter Password and Current Password");
    } else if (!confirmPassword) {
      errorErrorMessage("Please enter Confirm Password");
    } else if (!password) {
      errorErrorMessage("Please enter Password");
    } else if (!currentPassword) {
      errorErrorMessage("Please enter Current Password");
    } else if (password && confirmPassword && password !== confirmPassword) {
      errorErrorMessage(
        "Password and Confirm Password fields value must be matched."
      );
    } else if (password && password?.length < 6) {
      errorErrorMessage("Password requires 6 characters minimum");
    } else if (password && confirmPassword) {
      const jsonB = {
        userId: user?.id,
        currentPassword: currentPassword,
        changePassword: password,
      };
      const resp = (await dispatch(updatePasswordApi(jsonB))) as any;
      console.log("resp", resp);
      if (resp?.payload?.id) {
        setShowSuccessUserPopup(true);
      } else if (resp?.error?.message) {
        errorErrorMessage(resp?.error?.message);
      }
    }
  };

  const hideModel = () => {
    setShowSuccessUserPopup(false);
  };
  const hideModelOk = () => {
    history.push("/jobcardsummary");
    setShowSuccessUserPopup(false);
  };

  return (
    <ChangePasswordStyled>
      <div className="new-option-header">
        <h4>{`Update Password`}</h4>
      </div>
      <div className="new-option-body">
        <div className="SignupContainer-signup">
          <div>
            <div>
              <Form.Group className="signup-md22" controlId="email">
                <Form.Label>
                  Current Password <span className="mand-sign-field">*</span>{" "}
                </Form.Label>
                <Form.Control
                  autoFocus
                  name="currentPassword"
                  type="password"
                  value={currentPassword}
                  onChange={(e: any) => handleChangeValue(e)}
                  isInvalid={currentPasswordError}
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group className="signup-md22" controlId="email">
                <Form.Label>
                  Password <span className="mand-sign-field">*</span>{" "}
                </Form.Label>
                <Form.Control
                  autoFocus
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e: any) => handleChangeValue(e)}
                  isInvalid={passError}
                />
              </Form.Group>
            </div>
            <Form.Group className="signup-md22" controlId="email">
              <Form.Label>
                Confirm Password <span className="mand-sign-field">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                name="lastName"
                value={confirmPassword}
                onChange={(e: any) => handleChangeValue(e)}
                isInvalid={passCError}
              />
            </Form.Group>
          </div>
          {errorMessage && <p className="errorMessage">{errorMessage}</p>}
          {/* {passError && <p className="errorMessage">{passError}</p>}
    //   {errorMessage && <p className="errorMessage">{errorMessage}</p>}
      {error && !passError && <p className="errorMessage">{error}</p>} */}
          <div className="create-btn-full-width">
            <div className="create-account-action-con">
              <button
                // disabled={validationCheck()}
                onClick={() => {
                  createAccountData();
                }}
                type="submit"
                className="create-account-btn-sec"
              >
                {loading ? "Loading" : "Update Password"}
              </button>
            </div>
          </div>
          <CustomModal
            show={showSuccessUserPopup}
            titleText="You have Successfully Changed Password."
            deleteText="ok"
            cancelText=""
            onCancel={() => hideModel()}
            onDelete={() => hideModelOk()}
          />
        </div>
      </div>

      {/* <Modal.Footer>
          <div className="new-option-body-actions">
            <Button onClick={handleSubmitFn}>Add New User</Button>
          </div>
        </Modal.Footer> */}
    </ChangePasswordStyled>
  );
};

export default ChangePassword;
