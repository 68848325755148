import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllBrands,
  getDashboards,
 
} from "./ordersService";

export interface serviceManagementState {
  loading: boolean;
  error: any;
  brandsList: any;
  dashboardData: any;
}
const userData: string | null = localStorage.getItem("user");
const initialState: serviceManagementState = {
  loading: false,
  error: null,
  brandsList: [],
  dashboardData: {},
};

export const ordersSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (
      state: serviceManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    // updateUserDetails: (
    //   state: serviceManagementState,
    //   action: PayloadAction<boolean>
    // ) => {
    //   state.user = action.payload;
    // },
    updateErrorMessage: (
      state: serviceManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getAllBrands.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllBrands.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.brandsList = action.payload;
      }
    );
    builder.addCase(
      getAllBrands.rejected,
      (state: serviceManagementState, action: any) => {
        state.loading = false;
        state.brandsList = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //getDashboards
    builder.addCase(getDashboards.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getDashboards.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.dashboardData = action.payload;
      }
    );
    builder.addCase(
      getDashboards.rejected,
      (state: serviceManagementState, action: any) => {
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //getDisplayJobCardFormApi
  
  },
});

export const {
  setLoading,
  updateErrorMessage,
} = ordersSlice.actions;

export default ordersSlice.reducer;
