import React, { useEffect, useState, useRef } from "react";
import { StudentsBulkUploadStyled } from "./StudentsBulkUpload.styled";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Table, Badge, Dropdown, Space, Pagination } from "antd";
import add_icon from "../../img/delta/import.png";
import add_icon1 from "../../img/delta/Picture.png";

import Select from "react-select";
import {
  createStudentAPI,
  getAllStudents,
  getStudentDetailsAPI,
  createBulkStudentsAPI,
  updateStudentDetailsAPI,
} from "../..//redux/slices/StudentManagement/StudentManagementService";
import { CSVLink, CSVDownload } from "react-csv";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./../../components/Common/Loader";
import CustomModal from "../../components/CustomModel";
import { acadamicYearsList, classesList, admissionList } from "./../../config";

// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "./../../Scenes/common";
import { title } from "process";
const csvData = [
  [
    "rollNumber",
    "studentName",
    "newAdmission",
    "academicYear",
    "studentGrade",
    "primaryContactName",
    "primaryContactNumber",
    "primaryContactEmail",
  ],
  [
    "ABC1",
    "Test John",
    "Yes",
    "2022-2023",
    "Class-I",
    "vinod Tana",
    "9534634654",
    "vinod@gmail.com",
  ],
  [
    "ABC2",
    "Test John",
    "Yes",
    "2022-2023",
    "Class-I",
    "vinod Tana",
    "9534634654",
    "vinod@gmail.com",
  ],
  [
    "ABC3",
    "Test John",
    "Yes",
    "2022-2023",
    "Class-I",
    "vinod Tana",
    "9534634654",
    "vinod@gmail.com",
  ],
];

const StudentsBulkUpload = (props: any) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [instituteerrorMessage, setInstituteErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [studentGrade, setStudentGrade] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const hiddenFileInput = useRef<any>();

  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((state: any) => state?.auth);
  const { studentDetails } = useSelector(
    (state: any) => state?.studentManagement
  );
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);

  const fileReader = new FileReader();

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
    handleOnSubmit(e.target.files[0]);
  };
  const handleDownloadSampleSheet = () => {};
  const handleCancel = () => {
    history.push("/students");
  };
  const submitBulkUpload = async () => {
    if (array?.length > 0) {
      let newList = [] as any;
      array?.map((itt: any) => {
        newList.push({ ...itt, instituteId: user?._id });
      });
      const body = { students: newList, instituteId: user?._id };
      setIsLoading(true);
      const resp = (await dispatch(createBulkStudentsAPI(body))) as any;
      setIsLoading(false);
      setShowSuccessMessage(true);
    }
  };

  const csvFileToArray = (string: any) => {
    console.log("string", string);
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    console.log("csvHeader", csvHeader);
    console.log("csvRows", csvRows);
    const array = csvRows.map((i: any) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object: any, header: any, index: any) => {
        object[header?.replace("\r", "")?.replace(`"`, "")?.replace(`"`, "")] =
          values[index]
            ?.replace("\r", "")
            ?.replace(`\"`, "")
            ?.replace(`\"`, "");
        return object;
      }, {});
      return obj;
    });

    setArray(array);
  };

  const handleOnSubmit = (fileUpload: any) => {
    // e.preventDefault();

    if (fileUpload) {
      fileReader.onload = function (event: any) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(fileUpload);
    }
  };
  const handleClick = () => {
    if (hiddenFileInput) {
      hiddenFileInput?.current?.click();
    }
  };

  const columns = [
    {
      title: "Name",
      width: 200,
      key: "0",
      fixed: "left",

      dataIndex: "studentName",
      render: (item: any) => {
        return <>{item}</>;
      },
    },
    // {
    //   title: "Ref No",
    //   width: 100,
    //   dataIndex: "RefNo",
    //   key: "1",
    // },

    {
      title: "Roll Number",
      // dataIndex: "rollNumber",
      key: "3",
      width: 200,
      render: (item: any) => {
        console.log("rollNumber", item);
        return <>{item?.rollNumber}</>;
      },
    },
    {
      title: "Student Grade",
      dataIndex: "studentGrade",
      key: "2",
      width: 200,
    },

    {
      title: "Academic Year",
      dataIndex: "academicYear",
      key: "42",
      width: 200,
    },
    {
      title: "Admission Type",
      dataIndex: "newAdmission",
      key: "4",
      width: 200,
    },
    {
      title: "Primary Contact Name",
      dataIndex: "primaryContactName",
      key: "4",
      width: 250,
    },
    {
      title: "Primary Contact Number",
      dataIndex: "primaryContactNumber",
      key: "4",
      width: 250,
    },
    {
      title: "Primary Contact Email",
      dataIndex: "primaryContactEmail",
      key: "4",
      width: 250,
    },
  ] as any;

  const headerKeys = Object.keys(Object.assign({}, ...array));
  console.log("arrayarray", array);
  return (
    <>
      <StudentsBulkUploadStyled>
        <div className={`create-new-institute-sec `}>
          {isLoading && <Loader />}
          <div className="create-new-institute-sec-content">
            <div>
              <BreadCrumb
                list={[
                  { title: "Students", link: "/Students" },
                  { title: "Bulk Upload Students" },
                ]}
              />
              <div className="profileinfoHeader">
                <div>
                  <span className="edit-p-text">Bulk Upload Students</span>
                </div>
                <p>Upload multiple students at one go</p>
              </div>
            </div>
            <div className="bulk-upload-btns">
              <div>
                <div className="sample-sheet">
                  <CSVLink
                    filename="Students-Bulk-Upload-Sample-Sheet.csv"
                    data={csvData}
                  >
                    Download Sample Sheet &nbsp; <img src={add_icon} />
                  </CSVLink>
                </div>
              </div>
            </div>
            {/* <form> */}
            <div
              className={
                array?.length > 0
                  ? "btn-upload btn-upload-filled"
                  : "btn-upload"
              }
            >
              <input
                type={"file"}
                id={"csvFileInput"}
                accept={".csv"}
                ref={hiddenFileInput}
                onChange={handleOnChange}
              />

              <div className="btn-upload-section" onClick={handleClick}>
                <img src={add_icon1} />
                <br />
                <button className="button-upload">
                  {array?.length > 0
                    ? "Re upload here, or"
                    : "Drop your media here, or"}
                  <span>browse</span>
                </button>
                <p>Supports: CSV</p>
              </div>
            </div>
            {array?.length > 0 && (
              <>
                <div className="deltape-table-view">
                  <Table
                    columns={columns}
                    pagination={false}
                    dataSource={array}
                    scroll={{ x: 1500, y: 500 }}
                  />
                </div>
                <div className="submit-s-list-sec">
                  <button onClick={submitBulkUpload} className="deltape-button">
                    Submit Students
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <CustomModal
          show={showSuccessMessage}
          titleText={"Students Details Successfully Uploaded."}
          deleteText="Ok"
          cancelText=""
          onDelete={() => {
            setShowSuccessMessage(false);
            // setIsProfileEdit(false);
            handleCancel();
            // history.push("/students");
          }}
        />
      </StudentsBulkUploadStyled>
    </>
  );
};

export default StudentsBulkUpload;
