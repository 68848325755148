import styled from "styled-components";

export const SendRegistrationLinkStyled = styled.div`
  .free-payment-models-component {
  }
  .free-payment-models-component {
    max-height: 500px;
    overflow: scroll;
  }
  .model-content-body-sec {
    max-height: 350px;
    overflow: scroll;
  }
`;
