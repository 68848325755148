import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

export const getAllDashboardReportsApi = createAsyncThunk(
  "reports/getAllDashboardReportsApi",
  async (body: any) => await post(`${SERVER_IP}/dashboardReports`, body)
);

export const sendMeaageAPI = createAsyncThunk(
  "reports/sendMeaageAPI",
  async (body: any) => await post(`${SERVER_IP}/dashboardReports`, body)
);

export const getLineReportsByDate = createAsyncThunk(
  "reports/getLineReportsByDate",
  async (param: any) =>
    await post(`${SERVER_IP}/getJobcardCardCountbyDate`, param)
);
export const getStoreWiseJobCardReportAPi = createAsyncThunk(
  "reports/getStoreWiseJobCardReportAPi",
  async (param: any) =>
    await get(
      `${SERVER_IP}/getStoreWiseJobCardReport?startDate=${param?.startDate}&endDate=${param?.endDate}`
    )
);

export const getAllStoresReportAPi = createAsyncThunk(
  "reports/getAllStoresReportAPi",
  async () => await get(`${SERVER_IP}/getAllStoresReport`)
);
export const getAllStudentReportAPI = createAsyncThunk(
  "reports/getAllStudentReportAPI",
  async (body: any) => await post(`${SERVER_IP}/student/studentreport`, body)
);

export const getAllPaymentReportAPI = createAsyncThunk(
  "reports/getAllPaymentReportAPI",
  async (body: any) => await post(`${SERVER_IP}/payment/report`, body)
);

export const getAllCounts = createAsyncThunk(
  "reports/getAllCounts",
  async (id: any) => await get(`${SERVER_IP}/counts?instituteId=${id}`)
);
