import styled from "styled-components";

export const StudentsStyled = styled.div`
  .no-results {
    color: rgb(89, 85, 85);
    margin-top: 100px;
    text-align: center;
    border-top: 1px solid #e2e2e2;
    padding-top: 45px;
  }
  .create-new-sec {
    text-align: center;
    margin-top: 30px;
  }
  .profileEdit {
    font-size: 14px;
    padding: 12px 15px;
    border-radius: 8px;
    background: #1565c0;
    color: #fff;
  }
  .drops-off-marked-against {
    background-color: rgb(252, 238, 238);
    display: flex;
    padding: 16px 16px;
    margin-bottom: 20px;
    margin-top: 10px;
    border-radius: 9px;
    p {
      margin-bottom: 0px;
    }
  }
  .create-new-institute-sec-content {
    width: 95%;
    margin: auto;
    margin-top: 5px;
  }
  .submit-filter-btn-sec {
    text-align: right;
    margin-top: 24px;
  }
  .create-new-institute-sec-content-all {
  }
  .table-student-name {
    color: #007bff;
    cursor: pointer;
  }
  .table-student-name:hover {
    text-decoration: underline;
  }
  .student-fields-sec-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 10px;
  }
  .actions-btn-sec-c-s {
    display: flex;
    justify-content: end;
    margin-top: 16px;
  }
  .student-fields-sec-content-all {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    // margin-bottom: 30px;
    margin-top: 30px;
    // border: 1px solid #e2e2e2;
    padding: 10px;
    padding-left: 0px;
    padding-bottom: 0px;
    // border-radius: 4px;
  }
  .create-new-institute-header {
    margin-bottom: 20px;
  }
  .create-new-sec {
    text-align: center;
    margin-top: 30px;
  }
  .create-new-institute-header {
    span {
      color: #4682b4;
      cursor: pointer;
    }
  }
`;
