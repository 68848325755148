import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

export const getAllApplicationsbyuser = createAsyncThunk(
  "reports/getAllApplicationsbyuser",
  async (id: any) =>
    await get(`${SERVER_IP}/application/applicationsbyuser/${id}`)
);

export const getAllApplications = createAsyncThunk(
  "reports/getAllApplications",
  async () => await get(`${SERVER_IP}/application/all`)
);
export const getAllLocationsByIId = createAsyncThunk(
  "reports/getAllLocationsByIId",
  async (body?: any) =>
    await post(
      `${SERVER_IP}/institute/locationsbyinstitute/${body?.instituteId}?instituteId=${body?.instituteId}`,
      {}
    )
);

export const getAllBoards = createAsyncThunk(
  "reports/getAllBoards",
  async (body?: any) => await get(`${SERVER_IP}/getAllBoards`)
);

export const getAllAcademicYears = createAsyncThunk(
  "reports/getAllAcademicYears",
  async (body?: any) =>
    await get(
      `${SERVER_IP}/getAllAcademicYears?instituteId=${body?.instituteId}&locationId=${body?.locationId}`
    )
);

export const createApplicationAPI = createAsyncThunk(
  "reports/createApplicationAPI",
  async (body?: any) => await post(`${SERVER_IP}/application/create`, body)
);
export const updateApplicationAPI = createAsyncThunk(
  "reports/updateApplicationAPI",
  async (body?: any) => await put(`${SERVER_IP}/application/update`, body)
);
export const getApplicationDetailById = createAsyncThunk(
  "reports/getApplicationDetailById",
  async (id?: any) => await get(`${SERVER_IP}/application/details/${id}`)
);
