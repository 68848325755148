import React, { useEffect, useState } from "react";
import { StudentsStyled } from "./Students.styled";
import { Row, Col, Button, Form, Dropdown } from "react-bootstrap";
import Select from "react-select";
import {
  createStudentAPI,
  getAllStudents,
  getStudentDetailsAPI,
  updateStudentDetailsAPI,
  createFeeComponentsAPI,
  getAllFeeComponentsAPI,
} from "../..//redux/slices/StudentManagement/StudentManagementService";

import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./../../components/Common/Loader";
import CustomModal from "../../components/CustomModel";
import { acadamicYearsList, classesList, admissionList } from "./../../config";

// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "./../../Scenes/common";
const initValStore = {
  studentName: undefined,
  establishedYear: undefined,
  email: undefined,
  phoneNumber: undefined,
  primaryContactEmail: undefined,
  primaryContactNumber: undefined,
  primaryContactName: undefined,
  rollNumber: undefined,
};
const initValError = {
  studentName: false,
  primaryContactEmail: false,
  primaryContactNumber: false,
  primaryContactName: false,
  rollNumber: false,
};
const errorDataValue = {
  studentName: "Please Enter First name",
  establishedYear: "Please Enter Last name",
  email: "Please Enter Phone number",
  phoneNumber: "Please Enter Last name",
  primaryContactEmail: "Please Enter Last name",
  primaryContactNumber: "Please Enter Last name",
  primaryContactName: "Please Enter Last name",
  rollNumber: "Please Enter Last name",
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const CreateStudent = (props: any) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [instituteerrorMessage, setInstituteErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [studentGrade, setStudentGrade] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [errorData, setErrorData] = useState(initValError as any);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  // const query = useQuery();
  // const selectedTab = query.get("selectedTab");
  const studentId = props?.selectedStudentId
    ? props?.selectedStudentId
    : props?.match?.params?.studentId;
  const isEdit = props?.selectedStudentId ? true : false;
  const selectedStudentId = props?.selectedStudentId
    ? props?.selectedStudentId
    : "";
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((state: any) => state?.auth);
  const { studentDetails } = useSelector(
    (state: any) => state?.studentManagement
  );
  useEffect(() => {
    triggerMixpanelEvent("Contact_Us");
    if (studentId) {
      dispatch(getStudentDetailsAPI(studentId));
    }
  }, [selectedStudentId]);

  useEffect(() => {
    if (studentDetails || isEdit) {
      const ssDetails = studentDetails?.studentName
        ? studentDetails
        : props?.sDetails;
      const prevDetail = {
        studentName: ssDetails?.studentName,
        establishedYear: ssDetails?.establishedYear,
        email: ssDetails?.email,
        phoneNumber: ssDetails?.phoneNumber,
        newAdmission: ssDetails?.newAdmission,
        academicYear: ssDetails?.academicYear,
        studentGrade: ssDetails?.studentGrade,
        primaryContactEmail: ssDetails?.primaryContactEmail,
        primaryContactNumber: ssDetails?.primaryContactNumber,
        primaryContactName: ssDetails?.primaryContactName,
        rollNumber: ssDetails?.rollNumber,
      };
      console.log("prevDetail", prevDetail);
      setSignUpData({ ...prevDetail });
    }
  }, [studentDetails, isEdit]);

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };

  const handleChangeValue = (e: any) => {
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    setSignUpData({
      ...addr,
      [name]: value1,
    });
    setErrorData({
      ...addr_error,
      [name]: false,
    });
  };
  const handleSaveClick = async () => {
    setShowErrorMessage(true);
    var isValid = true;
    var errornew = { ...initValError } as any;
    for (var key in errornew) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    setErrorData({ ...errornew });

    if (isValid) {
      triggerMixpanelEvent("save_my_profile");
      //   , _id: user?._id
      const jBody = { ...signUpData, instituteId: user?._id };
      setIsLoading(true);
      if (studentId || isEdit) {
        const body = { ...jBody, _id: studentId || props?.sDetails?._id };
        const resp = (await dispatch(updateStudentDetailsAPI(body))) as any;
        setIsLoading(false);
        if (resp?.payload?.success) {
          props?.handleCloseSuccess();

          // if (studentId) {
          //   dispatch(getStudentDetailsAPI(studentId));
          // }

          // setShowSuccessMessage(true);
        }
      } else {
        const body = { ...jBody };
        const resp = (await dispatch(createStudentAPI(body))) as any;
        await createFeeComponents(jBody, resp);
        setIsLoading(false);
        if (resp?.payload?.success) {
          props?.handleCloseSuccess();
          // setShowSuccessMessage(true);
        }
      }
    }
  };
  const createFeeComponents = async (jBody: any, resp: any) => {
    console.log("jBody", jBody);
    console.log("resp", resp);
    const sId = resp?.payload?.data?._id;
    var componentsList = [];
    componentsList.push({
      studentId: sId,
      instituteId: user?._id,
      ComponentName: "Transportation Fees",
      FeeAmount: 40000,
      PaidAmount: 0,
      DiscountType: "",
      DiscountAmount: 0,
      PayableFee: 40000,
      note: "Note ",
    });
    componentsList.push({
      studentId: sId,
      instituteId: user?._id,
      ComponentName: "Tuition Fees",
      FeeAmount: 100000,
      PaidAmount: 0,
      DiscountType: "",
      DiscountAmount: 0,
      PayableFee: 100000,
      note: "",
    });
    if (true) {
      componentsList.push({
        studentId: sId,
        instituteId: user?._id,
        ComponentName: "Admission Fees",
        FeeAmount: 10000,
        PaidAmount: 0,
        DiscountType: "",
        DiscountAmount: 0,
        PayableFee: 10000,
        note: "",
      });
    }
    const resp2 = (await dispatch(
      createFeeComponentsAPI(componentsList)
    )) as any;
    dispatch(getAllFeeComponentsAPI({ studentId: sId }));
    console.log("resp2", resp2);
  };
  const handleCancel = () => {
    props?.handleClose();
  };
  const handleEdit = () => {
    setCurrentStep("1");
  };
  const handleChangeYear = (selectedOption: any) => {
    // setInstituteErrorMessage("");
    setAcademicYear(selectedOption);
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["academicYear"]: selectedOption?.label,
    });
    // dispatch(getAllAcademicYears({ studentId: 233, locationId: 1233 }));
  };
  const handleChangeGrade = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["studentGrade"]: selectedOption?.label,
    });
    // setInstituteErrorMessage("");
    setStudentGrade(selectedOption);
    // dispatch(getAllAcademicYears({ studentId: 233, locationId: 1233 }));
  };
  const handleChangeAdmission = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["newAdmission"]: selectedOption?.label,
    });
  };

  console.log("errorDataerrorData", errorData);
  console.log("errorDataerrorData", errorDataValue);

  console.log("signUpData", signUpData);
  const isEditText = isEdit ? "edit" : "";
  return (
    <>
      <StudentsStyled>
        <div
          className={`create-new-institute-sec create-new-institute-sec-${isEditText}`}
        >
          {isLoading && <Loader />}
          <div className="create-new-institute-sec-content">
            <div className="student-info-values">
              <div className="create-new-institute-header">
                <h3>{isEdit ? "Edit" : "Add"} Student</h3>
                {/* <span onClick={handleCancel}>{"< All Students"} </span> */}
              </div>

              <div>
                <div className=" deltape-form">
                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        {" "}
                        Student Name <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        // autoFocus
                        isInvalid={errorData?.studentName}
                        name="studentName"
                        type="text"
                        value={signUpData?.studentName}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.studentName ? (
                          <>{errorDataValue?.studentName}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="student-fields-sec-content">
                    <div
                      className={
                        showErrorMessage && !signUpData?.studentGrade
                          ? "delta-select-column delta-select-column-error"
                          : "delta-select-column"
                      }
                    >
                      <label>Grade </label>
                      <Select
                        value={
                          signUpData?.studentGrade
                            ? {
                                label: signUpData?.studentGrade,
                                value: signUpData?.studentGrade,
                              }
                            : ""
                        }
                        placeholder="Select Grade"
                        onChange={handleChangeGrade}
                        options={classesList}
                        className="delta-select"
                        classNamePrefix="delta-select"
                      />
                      {showErrorMessage && !signUpData?.studentGrade && (
                        <div className="invalid-feedback">
                          Please Select Grade
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        showErrorMessage && !signUpData?.academicYear
                          ? "delta-select-column delta-select-column-error"
                          : "delta-select-column"
                      }
                    >
                      <label>Academic Year </label>
                      <Select
                        value={
                          signUpData?.academicYear
                            ? {
                                label: signUpData?.academicYear,
                                value: signUpData?.academicYear,
                              }
                            : ""
                        }
                        placeholder="Select Year"
                        onChange={handleChangeYear}
                        options={acadamicYearsList}
                        className="delta-select"
                        classNamePrefix="delta-select"
                      />
                      {showErrorMessage && !signUpData?.academicYear && (
                        <div className="invalid-feedback">
                          Please Select Grade
                        </div>
                      )}
                    </div>
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          Roll Number <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          // autoFocus
                          isInvalid={errorData?.rollNumber}
                          name="rollNumber"
                          type="text"
                          value={signUpData?.rollNumber}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.rollNumber ? (
                            <>{errorDataValue?.rollNumber}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div
                      className={
                        showErrorMessage && !signUpData?.newAdmission
                          ? "delta-select-column delta-select-column-error"
                          : "delta-select-column"
                      }
                    >
                      <label>New Admission </label>
                      <Select
                        value={
                          signUpData?.newAdmission
                            ? {
                                label: signUpData?.newAdmission,
                                value: signUpData?.newAdmission,
                              }
                            : ""
                        }
                        placeholder="Select Admission"
                        onChange={handleChangeAdmission}
                        options={admissionList}
                        className="delta-select"
                        classNamePrefix="delta-select"
                      />
                      {showErrorMessage && !signUpData?.newAdmission && (
                        <div className="invalid-feedback">
                          Please Select Admission
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {/* <h4>Primary contact details</h4> */}
                <div className="deltape-form">
                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        Primary Contact Name{" "}
                        <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        // autoFocus
                        isInvalid={errorData?.primaryContactName}
                        name="primaryContactName"
                        type="text"
                        value={signUpData?.primaryContactName}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.primaryContactName ? (
                          <>{errorDataValue?.primaryContactName}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        Primary Contact Number{" "}
                        <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        // autoFocus
                        isInvalid={errorData?.primaryContactNumber}
                        name="primaryContactNumber"
                        type="text"
                        maxLength={10}
                        value={signUpData?.primaryContactNumber}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.primaryContactNumber ? (
                          <>{errorDataValue?.primaryContactNumber}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        Primary Contact Email{" "}
                        <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        // autoFocus
                        isInvalid={errorData?.primaryContactEmail}
                        name="primaryContactEmail"
                        type="text"
                        value={signUpData?.primaryContactEmail}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.primaryContactEmail ? (
                          <>{errorDataValue?.primaryContactEmail}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="actions-btn-sec actions-btn-sec-c-s align-center">
            <button className="deltape-cancel-button" onClick={handleCancel}>
              Cancel
            </button>
            <button className="deltape-button" onClick={handleSaveClick}>
              {studentId || isEdit ? "Update Student" : "Create Student"}
            </button>
          </div>
        </div>
      </StudentsStyled>
    </>
  );
};

export default CreateStudent;
