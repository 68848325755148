import React, { useEffect, useState } from "react";
import { StudentsStyled } from "./Students.styled";
import { Row, Col, Button, Form, Dropdown } from "react-bootstrap";
import Select from "react-select";
import {
  createClientAPI,
  getClientDetailsAPI,
  updateClientDetailsAPI,
} from "../../redux/slices/Assignments/AssignmentsService";
import {
  assignmentTypes,
  statusTypes,
  priorityList,
} from "../../library/constants";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Common/Loader";
import CustomModal from "../../components/CustomModel";
import { acadamicYearsList, classesList, admissionList } from "../../config";
import { DatePicker, Space } from "antd";

// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "../../Scenes/common";

const initValStore = {
  clientName: undefined,
  assignmentName: undefined,
  assignTo: undefined,
  startDate: undefined,
  endDate: undefined,
  status: undefined,
};
const initValError = {
  // clientName: false,
  assignmentName: false,
  assignTo: false,
  assignmentType: false,
  clientName: false,
  endDate: false,
  startDate: false,

  // clientId: false,
};
const errorDataValue = {
  clientName: "Please Enter Client Name",
  assignmentName: "Please Enter Client Name",
  assignTo: "Please Enter Client Name",
  startDate: "Please Enter Client Name",
  endDate: "Please Enter Client Name",
  status: "Please Enter Client Name",
  assignmentType: "Please select the Assignment Type",
  priorityVal: "Please select the priority",
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const CreateClient = (props: any) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusVal, setStatusVal] = useState({} as any);
  const [priorityVal, setPriorityVal] = useState({} as any);
  const [assignmentType, setAssignmentType] = useState({} as any);
  const [studentClient, setStudentClient] = useState({} as any);
  const [assignTo, setAssignTo] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [errorData, setErrorData] = useState(initValError as any);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [clientsList, setClientsList] = useState([] as any);
  const [usersList, setUsersList] = useState([] as any);
  const [sDate, setSDate] = useState(moment(new Date()).format("DD-MM-YYYY"));
  const [eDate, setEDate] = useState(moment(new Date()).format("DD-MM-YYYY"));

  // const query = useQuery();
  // const selectedTab = query.get("selectedTab");
  const studentId = props?.selectedStudentId
    ? props?.selectedStudentId
    : props?.match?.params?.studentId;
  const isEdit = props?.selectedStudentId ? true : false;
  const selectedStudentId = props?.selectedStudentId
    ? props?.selectedStudentId
    : "";
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((state: any) => state?.auth);
  const { allClients } = useSelector((state: any) => state?.clients);
  const { allUsers } = useSelector((state: any) => state?.userManagement);

  const { clientDetails } = useSelector((state: any) => state?.assignments);
  console.log("clientDetails", clientDetails);
  useEffect(() => {
    triggerMixpanelEvent("Contact_Us");
    if (studentId) {
      dispatch(getClientDetailsAPI(studentId));
    }
  }, [selectedStudentId]);
  useEffect(() => {
    const lewlist3 = [] as any;
    allClients?.data.map((items: any) => {
      lewlist3.push({ label: items?.clientName, value: items?._id });
    });
    setClientsList(lewlist3);
  }, [allClients?.data]);
  useEffect(() => {
    const lewlist3 = [] as any;
    allUsers?.data.map((items: any) => {
      lewlist3.push({ label: items?.fullName, value: items?._id });
    });
    setUsersList(lewlist3);
  }, [allUsers?.data]);

  useEffect(() => {
    if (props?.show && !props?.selectedStudentId) {
      setSignUpData(initValStore);
    }
  }, [props?.show]);

  useEffect(() => {
    if (clientDetails || isEdit) {
      const ssDetails = clientDetails?.assignmentName
        ? clientDetails
        : props?.sDetails;
      const prevDetail = {
        ...ssDetails,
      };
      console.log("prevDetail", prevDetail);
      setSignUpData({ ...prevDetail });
      setStatusVal({
        label: prevDetail?.status,
        value: prevDetail?.status,
      });
      setPriorityVal({
        label: prevDetail?.priority,
        value: prevDetail?.priority,
      });
      setAssignmentType({
        label: prevDetail?.assignmentType,
        value: prevDetail?.assignmentType,
      });
      setStudentClient({
        label: prevDetail?.clientName,
        value: prevDetail?.clientId,
      });
      setAssignTo({
        label: prevDetail?.assignTo,
        value: prevDetail?.assignToId,
      });
      setSDate(prevDetail?.startDate);
      setEDate(prevDetail?.endDate);
    }
  }, [clientDetails, isEdit]);

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };

  const handleChangeValue = (e: any) => {
    setErrorMessage("");
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    setSignUpData({
      ...addr,
      [name]: value1,
    });
    setErrorData({
      ...addr_error,
      [name]: false,
    });
  };
  const handleSaveClick = async () => {
    setErrorMessage("");
    setShowErrorMessage(true);
    var isValid = true;
    var errornew = { ...initValError } as any;
    for (var key in errornew) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    setErrorData({ ...errornew });

    if (isValid) {
      triggerMixpanelEvent("save_my_profile");
      //   , _id: user?._id
      const jBody = { ...signUpData, vendorId: user?._id };
      setIsLoading(true);
      if (studentId || isEdit) {
        const body = { ...jBody, _id: studentId || props?.sDetails?._id };
        const resp = (await dispatch(updateClientDetailsAPI(body))) as any;
        console.log("resp", resp);
        setIsLoading(false);
        if (resp?.payload?.success) {
          props?.handleCloseSuccess();

          // if (studentId) {
          //   dispatch(getStudentDetailsAPI(studentId));
          // }

          // setShowSuccessMessage(true);
        } else if (resp?.payload?.error) {
          setErrorMessage(resp?.payload?.error);
        }
      } else {
        const body = { ...jBody };
        const resp = (await dispatch(createClientAPI(body))) as any;
        console.log("resp", resp);
        // await createFeeComponents(jBody, resp);
        setIsLoading(false);
        if (resp?.payload?.success) {
          props?.handleCloseSuccess();
          // setShowSuccessMessage(true);
        } else if (resp?.payload?.error) {
          setErrorMessage(resp?.payload?.error);
        }
      }
    }
  };

  const handleCancel = () => {
    props?.handleClose();
  };
  const handleEdit = () => {
    setCurrentStep("1");
  };
  const handleChangeType = (selectedOption: any) => {
    setAssignmentType(selectedOption);
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["assignmentType"]: selectedOption?.label,
    });
    const addr_error = { ...errorData };
    setErrorData({
      ...addr_error,
      assignmentType: false,
    });
  };
  const handleChangClient = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["clientID"]: selectedOption?.value,
      ["clientName"]: selectedOption?.label,
    });
    setStudentClient(selectedOption);
    const addr_error = { ...errorData };
    setErrorData({
      ...addr_error,
      clientName: false,
    });
  };
  const handleChangAssignTo = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["assignToId"]: selectedOption?.value,
      ["assignTo"]: selectedOption?.label,
    });
    setAssignTo(selectedOption);
    const addr_error = { ...errorData };
    setErrorData({
      ...addr_error,
      assignTo: false,
    });
  };
  const handleChangStatus = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["status"]: selectedOption?.value,
    });
    setStatusVal(selectedOption);
    const addr_error = { ...errorData };
    setErrorData({
      ...addr_error,
      status: false,
    });
  };
  const handleChangPriority = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["priority"]: selectedOption?.value,
    });
    setPriorityVal(selectedOption);
    const addr_error = { ...errorData };
    setErrorData({
      ...addr_error,
      status: false,
    });
  };

  const handleDateChange = (seDate: any) => {
    console.log("seDate", seDate);
    const sDate = moment(seDate).format("DD-MM-YYYY");
    setSDate(sDate);
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["startDate"]: sDate,
    });
    const addr_error = { ...errorData };
    setErrorData({
      ...addr_error,
      startDate: false,
    });
  };
  const handleDateChange1 = (seDate: any) => {
    const eDate = moment(seDate).format("DD-MM-YYYY");
    setEDate(eDate);
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["endDate"]: eDate,
    });
    const addr_error = { ...errorData };
    setErrorData({
      ...addr_error,
      endDate: false,
    });
  };
  console.log("errorDataerrorData", errorData);
  console.log("errorDataerrorData", errorDataValue);

  console.log("signUpData", signUpData);
  const isEditText = isEdit ? "edit" : "";
  return (
    <>
      <StudentsStyled>
        <div
          className={`create-new-institute-sec create-new-institute-sec-${isEditText}`}
        >
          {isLoading && <Loader />}
          <div className="create-new-institute-sec-content">
            <div className="student-info-values">
              <div className="create-new-institute-header">
                <h3>{isEdit ? "Edit" : "Add"} Assignment</h3>
                {/* <span onClick={handleCancel}>{"< All Students"} </span> */}
              </div>

              <div>
                <div className=" deltape-form">
                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        {" "}
                        Select Client <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Select
                        // value={studentGrade ?? "All"}
                        value={{
                          label: studentClient?.label ?? "All",
                          value: studentClient?.label ?? "All",
                        }}
                        placeholder="Select Assign TO"
                        onChange={handleChangClient}
                        options={clientsList}
                        className="delta-select"
                        classNamePrefix="delta-select"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.clientName ? (
                          <>{errorDataValue?.clientName}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        Assignment Name{" "}
                        {/* <span className="mand-sign-field">*</span> */}
                      </Form.Label>
                      <Form.Control
                        // autoFocus
                        isInvalid={errorData?.assignmentName}
                        name="assignmentName"
                        type="text"
                        value={signUpData?.assignmentName}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.assignmentName ? (
                          <>{errorDataValue?.assignmentName}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="student-fields-sec-content">
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          Assignment Type
                          <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Select
                          // value={studentGrade ?? "All"}
                          value={{
                            label: assignmentType?.label ?? "All",
                            value: assignmentType?.label ?? "All",
                          }}
                          placeholder="Select Assign TO"
                          onChange={handleChangeType}
                          options={assignmentTypes}
                          className="delta-select"
                          classNamePrefix="delta-select"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.assignmentType ? (
                            <>{errorDataValue?.assignmentType}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          Assign TO <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Select
                          // value={studentGrade ?? "All"}
                          value={{
                            label: assignTo?.label ?? "All",
                            value: assignTo?.label ?? "All",
                          }}
                          placeholder="Select Assign TO"
                          onChange={handleChangAssignTo}
                          options={usersList}
                          className="delta-select"
                          classNamePrefix="delta-select"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.assignTo ? (
                            <>{errorDataValue?.assignTo}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          Start Date <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <DatePicker
                          onChange={handleDateChange}
                          value={moment(sDate, "DD-MM-YYYY")}
                          disabledDate={(d) => !d || d.isAfter(eDate)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.startDate ? (
                            <>{errorDataValue?.startDate}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          Target Date <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <DatePicker
                          onChange={handleDateChange1}
                          value={moment(eDate, "DD-MM-YYYY")}
                          disabledDate={(d) => !d || d.isAfter(eDate)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.endDate ? (
                            <>{errorDataValue?.endDate}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          Status <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Select
                          // value={studentGrade ?? "All"}
                          value={{
                            label: statusVal?.label ?? "All",
                            value: statusVal?.label ?? "All",
                          }}
                          placeholder="Select Assign TO"
                          onChange={handleChangStatus}
                          options={statusTypes}
                          className="delta-select"
                          classNamePrefix="delta-select"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.status ? (
                            <>{errorDataValue?.status}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          Priority <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Select
                          // value={studentGrade ?? "All"}
                          value={{
                            label: priorityVal?.label ?? "All",
                            value: priorityVal?.label ?? "All",
                          }}
                          placeholder="Select Assign TO"
                          onChange={handleChangPriority}
                          options={priorityList}
                          className="delta-select"
                          classNamePrefix="delta-select"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.priorityVal ? (
                            <>{errorDataValue?.priorityVal}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        Comments{" "}
                        {/* <span className="mand-sign-field">*</span> */}
                      </Form.Label>
                      <Form.Control
                        // autoFocus
                        isInvalid={errorData?.comments}
                        name="comments"
                        type="textarea"
                        value={signUpData?.comments}
                        onChange={(e) => handleChangeValue(e)}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {errorMessage && <p className="error_message">{errorMessage}</p>}
          <div className="actions-btn-sec actions-btn-sec-c-s align-center">
            <button className="deltape-cancel-button" onClick={handleCancel}>
              Cancel
            </button>
            <button className="deltape-button" onClick={handleSaveClick}>
              {studentId || isEdit ? "Update Assignment" : "Create Assignment"}
            </button>
          </div>
        </div>
      </StudentsStyled>
    </>
  );
};

export default CreateClient;
