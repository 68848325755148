import styled from "styled-components";

export const SideMenuStyled = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  .dashboard-container {
    color: red;
  }
  .side-bar-container {
    margin-bottom: 200px;
  }
  .side-bar-container .accordion-button:focus {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  }
  .left-menu-sec-name {
    span {
      text-transform: capitalize;
    }
  }
  .left-header-sec-cont-name {
    margin: 15px 1.25rem;
    display: flex;
    gap: 20px;
    padding-top: 0px;
    border-bottom: 1px solid #aca4a4;
    /* opacity: 1.5; */
    margin-top: 0px;
    padding-bottom: 15px;
    margin-left: 0px;
    svg {
      height: 44px;
      width: 44px;
    }
    span {
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      top: 10px;
      position: relative;
      letter-spacing: 1px;
    }
  }
  .side-bar-container,
  .side-bar-container .accordion-item,
  .side-bar-container .accordion-item h2 button {
    // background: #37474f;
    // color: #fff;
  }
  .job-card-sec-approval-count {
    color: #f64f5a;
    margin-left: 5px;
  }
  .MuiSvgIcon-fontSizeMedium {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
    margin-right: 20px;
  }
  .side-bar-container button.accordion-button {
    // box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
    font-size: 19px;
    padding: 12px;
    color: #fff;
    // background: #ec77a6;
    // background: #efefef;
    // padding-left: 0px;
  }
  .side-bar-container button.accordion-button::after {
    width: 18px;
    height: 15px;
    display: none;
  }
  .accordion-body-items ul {
    list-style: none;
    padding-left: 0rem;
    margin: 0;
    li {
      background: #78bebe;
    }
  }
  .accordion-body-items {
    // background: #e7f1ff;
    padding: 0;
    // margin-top: 8px;
  }
  .main-menu-sec {
    padding: 15px 1.25rem;
    color: #fff;
    font-size: 14px;
    opacity: 0.8;
    display: block;
    padding-top: 5px;
    padding-left: 0px;
  }
  .accordion-body-items ul li a {
    color: #000;
    font-size: 17px;
    padding: 8px 0px;
    display: block;
    padding-left: 4rem;
    // box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  }
  .side-bar-container .accordion-button[aria-expanded="true"] {
    background: #40a9ff;
    color: #fff;
    font-size: 22px;
  }
  // .accordion-body-items ul li a11:hover,
  .accordion-body-items ul li a.selectedItem {
    text-decoration: none;
    text-decoration: none;
    background: #4682b4;

    background: #fff;
    background: #e7f1ff;
    color: #000;
    border-radius: 4px;
    background-color: #fff;
    color: #000 !important;
  }
  .accordion-header-selected {
    background: #14a2b8;
  }
  .accordion-header-selected .accordion-button {
    background-color: #fff;
    color: #1565c0 !important;
    border-radius: 8px;
    background: #aee7f1;
    border-radius: 8px;
  }
  .side-bar-container button img {
    height: 20px;
    margin-right: 15px;
  }
  .side-bar-container .focusNextHome button.accordion-button::after,
  .side-bar-container .focusNextUsersList button.accordion-button::after,
  .side-bar-container .focusNextChangePassword button.accordion-button::after {
    display: none;
  }
  .accordion-item-disabled-view {
    opacity: 0.7;
    button.accordion-button {
      color: gray;
    }
  }
  @media (max-width: 700px) {
    .side-bar-container {
      margin-bottom: 50px;
    }
  }
`;
