import React, { useState, useEffect, Component } from "react";
import {
  Row,
  Col,
  Accordion,
  Card,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import TimePicker from "rc-time-picker";
import Select from "react-select";
import Logo from "../../img/focusNext.png";
import {
  getStoreDetails,
  getPrintSettingByIdApi,
  getCustomerDetails,
} from "./../../redux/slices/auth/authService";
import { MdOutlineCurrencyRupee } from "react-icons/md";

import "rc-time-picker/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getBillingDetailsAPI,
  // getActionTakenoptList,
} from "./../../redux/slices/Billings/BillingsService";
import moment from "moment";
import { isRaskoAccount } from "../../Scenes/common";
import { PrintJobCardStyled } from "./PrintJobCard.styled";

const PrintJobCard = (props: any) => {
  const billingId = props?.match?.params?.billingId;
  const dispatch = useDispatch();
  const { billingDetails } = useSelector((state: any) => state.billings);

  const [printData, setPrintData] = useState({} as any);
  const {
    user,
    loginStoreDetails,
    selectedCustomerDetails,
    getPrintSettingsResp,
  } = useSelector((state: any) => state.auth);
  useEffect(() => {
    if (getPrintSettingsResp?.printSetting) {
      setPrintData({ ...getPrintSettingsResp?.printSetting });
      // setValue(getPrintSettingsResp?.printSetting?.termsAndConditions);
    }
  }, [getPrintSettingsResp?.printSetting]);

  // const [selectedCustomerDetails, setSelectedCustomerDetails] = useState(
  //   {} as any
  // );
  console.log("user", user);
  console.log("printData", printData);
  useEffect(() => {
    dispatch(getPrintSettingByIdApi(1));

    if (billingId) {
      dispatch(getBillingDetailsAPI(billingId));
    }
    if (user?.storeId) {
      dispatch(getStoreDetails(user?.storeId));
    }
  }, []);
  useEffect(() => {
    console.log("billingDetails", billingDetails);
    if (billingDetails?.jobCard?.selectedCustomerId) {
      customerDetails(billingDetails?.jobCard?.selectedCustomerId);
    }
  }, [billingDetails]);
  const customerDetails = async (id: any) => {
    const resp = dispatch(getCustomerDetails(id)) as any;
    console.log("resp", resp);
    // setSelectedCustomerDetails(resp?.payload);
  };
  console.log("loginStoreDetails", loginStoreDetails);
  console.log("selectedCustomerDetails", selectedCustomerDetails);
  const { jobCard } = billingDetails;
  const itemRecivedList = jobCard?.itemRecived?.split(",");
  const defaultIssuesList = jobCard?.defaultIssues?.split(",");
  const customerAddRequirementsList =
    jobCard?.customerAddRequirements?.split(",");
  const cDetails = selectedCustomerDetails?.customerDetails;
  console.log("jobCard", jobCard);
  const imgUrls = jobCard?.imageUrls ? jobCard?.imageUrls.split(",") : [];
  console.log("imgUrls", imgUrls);
  return (
    <>
      <PrintJobCardStyled>
        <div className="print-page-box-container">
          <div className="print-page-box-sec">
            <div className="print-head">
              <div>
                {printData?.imageUrl && printData?.imageUrl !== "noImage" ? (
                  <img src={printData?.imageUrl} />
                ) : (
                  <>
                    <span className="logo-header-left-text">
                      {printData?.storeName ||
                        loginStoreDetails?.storeName ||
                        "KRMM"}
                    </span>
                  </>
                )}
              </div>
              <div className="print-sub-head">
                <p>Invoice No: KRMM112409</p>
              </div>
              <div className="bar-code-example-con">25-07-2024</div>
              {/* <img className="mainLogoImage-print" src={Logo}></img> */}
            </div>
            <div className="user-details-sec">
              <div className="user-customer-sec user-customer-sec-left">
                <div className="user-customer-sec-header">Invoice From</div>
                <div className="left-items">
                  <div>
                    <h5>KRMM and Associates</h5>
                    <div>
                      <p>
                        30/31, Erikarai Road, Mahatma Gandhi Nagar, <br />{" "}
                        Bhavani Nagar, Kolathur, Chennai - 600099 <br />
                        E-Mail: kotiaca455@gmail.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="user-customer-sec">
                <div className="user-customer-sec-header">Invoice To</div>
                <div className="left-items">
                  <div>
                    <h5>LCube Healthcare Private Limited</h5>
                    <div>
                      <p>
                        Flat No. A, No. 28, Parvathy Apartments, <br />
                        Damodaran Street, T Nagar,
                        <br />
                        Chennai - 600017, India
                        <br />
                        State Name: Tamil Nadu, Code: 33{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="items-content-table">
                <table>
                  <tr>
                    <th className="table_s_no">S. No.</th>
                    <th className="table_s_desc">Description of Services</th>
                    <th className="table_s_amount">Amount</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>
                      For Statutory Audit, MCA Returns and IT Filing for FY
                      2023-24
                    </td>
                    <td>2,00,000</td>
                  </tr>

                  <tr>
                    <td>2</td>
                    <td>MCA Govt Fees Paid – Reimbursement</td>
                    <td>10,0000</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Filing of Income Tax Returns for Directors</td>
                    <td>10,0000</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      <strong>Total</strong>{" "}
                    </td>
                    <td>50,0000</td>
                  </tr>
                </table>
                <div className="table-content-sec-bott">
                  <p>Amount Chargeable (in words)</p>
                  <p> Total SAC INR Sixty Thousand Six Hundred Only</p>
                  <br />
                  <p>
                    <strong>Remarks:</strong>{" "}
                  </p>
                  <p>Being Professional Charges towards Above services</p>
                  <br />
                </div>
                <div>
                  <div className="form-bank-details-sec">
                    <h5>Company's Bank Details:</h5>
                    <div className="form-bank-details-sec-con">
                      <div>
                        <div>Bank Name</div>
                        <div>:</div>
                        <div>South Indian Bank</div>
                      </div>
                      <div>
                        <div>A/c No</div>
                        <div>:</div>
                        <div>0110073000002852</div>
                      </div>
                      <div>
                        <div>IFS Code</div>
                        <div>:</div>
                        <div>SIBL0000110</div>
                      </div>
                      <div>
                        <div>PAN</div>
                        <div>:</div>
                        <div>AAVFK1526C</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-content-sec-bott">
                  <p>
                    <strong>Declaration:</strong>{" "}
                  </p>
                  <p>
                    We declare that this invoice shows the actual price of the
                    Services described and that all particulars are true and
                    correct.
                  </p>
                </div>
              </div>
            </div>

            <div className="sub-sub-head">
              {`Terms & Conditions`} <span className="manditary">*</span>
            </div>
            <div
              className={
                imgUrls?.length > 0
                  ? "terms-list terms-list-small-font"
                  : "terms-list"
              }
            >
              {printData?.termsAndConditions &&
              printData?.termsAndConditions !== "<p><br></p>" ? (
                <div
                  className="comment-text-terms-con-sec"
                  dangerouslySetInnerHTML={{
                    __html: `${printData?.termsAndConditions.replace(
                      /\n/g,
                      "<br>"
                    )}`,
                  }}
                ></div>
              ) : (
                <>
                  <ol>
                    <li>
                      Cost Of The Part Required During The Scope Of Repair Will
                      Be Borne By The Customer.
                    </li>
                    <li>
                      There is no guarantee / warranty for any parts replaced /
                      sold or services rendered.
                    </li>
                    <li>
                      In display related concerns like dull display or graphic
                      chip related, there is a probability of product going
                      completely dead for which we will not be liable.
                    </li>
                    <li>
                      During repair process, if the product goes dead due to
                      technical issues, we are not responsible for it.
                    </li>
                    <li>
                      We will not be responsible for any loss / damage due to
                      theft / fire / riots /acts of state / acts of Gods etc.
                    </li>
                  </ol>
                  <p className="note-list-sec">
                    <span className="bold-text">
                      {" "}
                      Note:- For any service related enquiries please call to
                      below mentioned Phone Numbers Only.
                    </span>
                  </p>
                </>
              )}
            </div>
            <div className="print-sign">Authorised Signatory:</div>
            <div className="print-compu-text">
              **This is computer generated print signature not required.
            </div>
            <div className="print-sign-bottom">
              {/* <div className="sign-head">FOCUS CORPORATION</div> */}
              <div className="sign-sub-head">
                {/* D.NO:42/108 | APSARA CIRCLE | SVR COMPLEX | RTC BUS STAND ROAD |
                KADAPA-516002 */}
                {printData?.address || loginStoreDetails?.address}
              </div>
              <div className="sign-sub-sub-head">
                {" "}
                {printData?.phoneNumbersInfo ? (
                  <>{printData?.phoneNumbersInfo}</>
                ) : (
                  <>
                    +91 {loginStoreDetails?.storeMobileNo} | +91{" "}
                    {loginStoreDetails?.contactNumber}
                  </>
                )}
                {isRaskoAccount(loginStoreDetails?.id) && (
                  <>
                    {" "}
                    | For Book To Repair please visit www.focusnext.in web site
                  </>
                )}
              </div>
            </div>
          </div>
          <p className="footer-bottom-text">
            This software provided by{" "}
            <a target="_blank" href="https://www.raneasy.in/">
              raneasy.in{" "}
            </a>
          </p>
        </div>
      </PrintJobCardStyled>
    </>
  );
};
export default PrintJobCard;
