import styled from "styled-components";

export const PaymentModelsStyled = styled.div`
  .free-payment-models-component {
  }
  .free-payment-models-component {
    max-height: 500px;
    overflow: scroll;
  }
  .free-payment-models-component {
    .delta-select,
    .form-group {
      margin-bottom: 10px;
    }
  }
  .delta-select__menu {
    border: 1px solid #e2e2e2;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .error_message {
    font-size: 1rem;
    text-align: left;
    margin-bottom: 10px;
    margin-top: -10px;
    color: #dc3545;
  }
`;
