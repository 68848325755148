import styled from "styled-components";

export const CreatePaymentLinkStyled = styled.div`
  .free-payment-models-component {
  }
  .free-payment-models-component {
    max-height: 500px;
    overflow: scroll;
  }
`;
