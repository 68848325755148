import styled from "styled-components";

export const InstitutesStyled = styled.div`
  .no-results {
    color: rgb(89, 85, 85);
    margin-top: 100px;
    text-align: center;
    border-top: 1px solid #e2e2e2;
    padding-top: 45px;
  }
  .create-new-sec {
    text-align: center;
    margin-top: 30px;
  }
`;
